import { ReactElement } from "react"
import { BookingAddress } from "../../../types/bookings"

type Props = {
  id: string
  title?: string
  value: BookingAddress
  disabled?: boolean
  className?: string
  onChange: (value: BookingAddress) => void
}

export const BookingAddressField: React.FC<Props> = ({
  id,
  title,
  value,
  disabled,
  className,
  onChange,
}): ReactElement => {
  let inputClassName =
    "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

  if (disabled) {
    inputClassName += " bg-gray-100"
  }

  return (
    <div className={className ?? "col-span-2 md:col-span-1"}>
      {title && (
        <label className="mb-1 block text-sm font-medium text-gray-700">
          {title}
        </label>
      )}
      <div className="flex flex-col gap-2">
        <input
          type="text"
          name={`${id}.line1`}
          className={inputClassName}
          value={value.line1}
          disabled={disabled}
          onChange={(e) => {
            onChange({ ...value, line1: e.target.value })
          }}
        />
        <input
          type="text"
          name={`${id}.line2`}
          className={inputClassName}
          value={value.line2 ?? ""}
          disabled={disabled}
          onChange={(e) => {
            onChange({
              ...value,
              line2: e.target.value?.length > 0 ? e.target.value : undefined,
            })
          }}
        />
        <input
          type="text"
          name={`${id}.city`}
          className={inputClassName}
          value={value.city}
          disabled={disabled}
          onChange={(e) => {
            onChange({ ...value, city: e.target.value })
          }}
        />
        <input
          type="text"
          name={`${id}.postcode`}
          className={inputClassName}
          value={value.postcode}
          disabled={disabled}
          onChange={(e) => {
            onChange({ ...value, postcode: e.target.value })
          }}
        />
      </div>
    </div>
  )
}
