import React from "react"
import { ViewingRequestListItem } from "../../ostrich/rpc/users/viewing_requests/v2_pb"

interface OfferComputedStatusLabelProps {
  offer: ViewingRequestListItem
  style: "large" | "small"
}

const STATUS_MAPPING = {
  "legacy-offer": {
    label: (style: "large" | "small") =>
      style === "large" ? "Legacy Offer" : "Legacy",
    className: "bg-gray-100 text-gray-800",
  },
  "deleted-by-ostrich": {
    label: (style: "large" | "small") =>
      style === "large" ? "Deleted by Ostrich" : "Deleted",
    className: "bg-gray-100 text-gray-800",
  },
  completed: {
    label: () => "Completed",
    className: "bg-green-100 text-green-800",
  },
  "under-offer": {
    label: () => "Under Offer",
    className: "bg-green-100 text-green-800",
  },
  "timed-out": {
    label: () => "Timed Out",
    className: "bg-gray-100 text-gray-800",
  },
  "review-required-looking-for-higher-price": {
    label: () => "Review Required - Looking for higher price",
    className: "bg-yellow-100 text-yellow-800",
  },
  "review-required-not-the-right-time": {
    label: () => "Review Required - Not the right time",
    className: "bg-yellow-100 text-yellow-800",
  },
  "review-required-interested": {
    label: () => "Review Required - Interested",
    className: "bg-yellow-100 text-yellow-800",
  },
  "review-required-message": {
    label: () => "Review Required - Message",
    className: "bg-yellow-100 text-yellow-800",
  },
  "awaiting-homeowner-response": {
    label: (style: "large" | "small") =>
      style === "large" ? "Awaiting Homeowner Response" : "Awaiting Response",
    className: "bg-blue-100 text-blue-800",
  },
  "in-discussion": {
    label: () => "In Discussion",
    className: "bg-green-100 text-green-800",
  },
  "viewing-offered": {
    label: () => "Viewing offered",
    className: "bg-blue-100 text-blue-800",
  },
  "viewing-arranged": {
    label: () => "Viewing arranged",
    className: "bg-green-100 text-green-800",
  },
  "availability-requested": {
    label: () => "Availability requested",
    className: "bg-blue-100 text-blue-800",
  },
  "alternative-slots-requested": {
    label: () => "Alternative slots requested",
    className: "bg-blue-100 text-blue-800",
  },
  "rejected-looking-for-higher-price": {
    label: () => "Rejected - Looking for higher price",
    className: "bg-red-100 text-red-800",
  },
  "rejected-not-the-right-time": {
    label: () => "Rejected - Not the right time",
    className: "bg-red-100 text-red-800",
  },
  "closed-by-ostrich": {
    label: (style: "large" | "small") =>
      style === "large" ? "Closed by Ostrich" : "Closed",
    className: "bg-gray-100 text-gray-800",
  },
  "rejected-by-ostrich": {
    label: (style: "large" | "small") =>
      style === "large" ? "Rejected by Ostrich" : "Rejected",
    className: "bg-red-100 text-red-800",
  },
  "review-required-new-offer": {
    label: (style: "large" | "small") =>
      style === "large" ? "Review Required - New Offer" : "Review Required",
    className: "bg-yellow-100 text-yellow-800",
  },
} as const

const getLabel = (item: ViewingRequestListItem, style: "large" | "small") => {
  let status = item.status as keyof typeof STATUS_MAPPING

  const mapping = STATUS_MAPPING[status]
  return {
    label: mapping.label(style),
    className: mapping.className,
  }
}

export const OfferComputedStatusLabel: React.FC<
  OfferComputedStatusLabelProps
> = ({ offer, style }) => {
  return (
    <>
      <span
        className={`inline-flex items-center rounded-full text-xs font-medium ${
          style === "large" ? "px-2.5 py-1" : "px-2.5 py-0.5"
        } ${getLabel(offer, style).className}`}
      >
        {getLabel(offer, style).label}
      </span>
    </>
  )
}
