import { ReactElement } from "react"
import { Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import { Link } from "react-router-dom"
import { formatDateTime } from "../../../util/dates"
import { waitlistedBuyerFundingPositions } from "../../../util/misc"

type Props = {
  property: Property
}

export const WaitlistedBuyersTab: React.FC<Props> = ({
  property,
}): ReactElement => {
  return (
    <>
      <FormPanel title="Waitlisted Buyers">
        {property.waitlistedBuyers && property.waitlistedBuyers.length > 0 ? (
          <table className="col-span-2 min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  User
                </th>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Home to Sell
                </th>
                <th
                  scope="col"
                  className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Funding Position
                </th>
                <th
                  scope="col"
                  className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date Created
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"
                >
                  <span className="sr-only">View</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {property.waitlistedBuyers?.map((waitlistedBuyer) => (
                <tr key={waitlistedBuyer.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                    {waitlistedBuyer.user.firstName}{" "}
                    {waitlistedBuyer.user.lastName}
                    {waitlistedBuyer.user.verified.stripe !== "VE" && (
                      <span className="ml-1 font-normal text-gray-500">
                        &ndash; Unverified
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {waitlistedBuyer.homeSeller ? "Yes" : "No"}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {
                      waitlistedBuyerFundingPositions[
                        waitlistedBuyer.fundingPosition
                      ]
                    }
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {formatDateTime(waitlistedBuyer.dateCreated)}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6 md:pr-0">
                    <Link
                      to={`/waitlisted-buyers/${waitlistedBuyer.id}/`}
                      className="font-medium text-indigo-600 hover:text-indigo-900"
                      target="_blank"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="col-span-2 text-sm text-gray-500">
            No waitlisted buyers.
          </p>
        )}
      </FormPanel>
    </>
  )
}
