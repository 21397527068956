import { ReactElement, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  fetchAvailabilityDate,
  fetchListingAppointment,
  saveListingAppointment,
  setListingAppointmentStatus,
  updateField,
} from "../../../redux/slices/listing-appointment-details"
import { formatDateTime } from "../../../util/dates"
import { Container } from "../../global/container"
import { FormPanel } from "../../global/forms/form-panel"
import { SelectField } from "../../global/forms/select-field"
import { TextField } from "../../global/forms/text-field"
import { Header, HeaderButton } from "../../global/header"
import { BookingAddressField } from "./booking-address-field"
import { ConfirmModal } from "../../global/modals/confirm-modal"
import { DateField } from "../../global/forms/date-field"
import { getSiteUrl } from "../../../util/api"
import { photographers } from "../../../util/misc"

export const BookingDetails: React.FC = (): ReactElement | null => {
  let { id } = useParams()

  const dispatch = useAppDispatch()

  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [postponeModalOpen, setPostponeModalOpen] = useState(false)

  useEffect(() => {
    if (!id) return

    dispatch(fetchListingAppointment(id))
  }, [dispatch, id])

  let { listingAppointment, status, availabilityDate } = useAppSelector(
    (state) => state.listingAppointmentDetails
  )

  useEffect(() => {
    if (!listingAppointment) return

    dispatch(fetchAvailabilityDate(listingAppointment.bookingSlot))
  }, [dispatch, listingAppointment])

  if (!listingAppointment) return null

  const handleSave = () => {
    dispatch(saveListingAppointment())
  }

  const handleCancel = (sendNotification: boolean) => {
    dispatch(setListingAppointmentStatus({ status: "CA", sendNotification }))
  }

  const handlePostpone = () => {
    dispatch(setListingAppointmentStatus({ status: "PO" }))
  }

  const handleConfirm = (sendNotification: boolean) => {
    dispatch(setListingAppointmentStatus({ status: "CO", sendNotification }))
  }

  const isCancelled = listingAppointment.status === "CA"
  const isPostponed = listingAppointment.status === "PO"

  let secondaryButtons: HeaderButton[] = []

  if (!isCancelled) {
    secondaryButtons.push({
      label: "Reschedule Link",
      onClick: () => {
        navigator.clipboard.writeText(
          `${getSiteUrl()}/seller/appointments/${
            listingAppointment?.id
          }/reschedule/`
        )
      },
    })

    if (isPostponed) {
      secondaryButtons.push({
        label: "Confirm Booking",
        onClick: () => setConfirmModalOpen(true),
      })
    } else {
      secondaryButtons.push({
        label: "Postpone Booking",
        onClick: () => setPostponeModalOpen(true),
      })
    }

    secondaryButtons.push({
      label: "Cancel Booking",
      onClick: () => setCancelModalOpen(true),
    })
  }

  let headerSuccessMessage: string | undefined

  switch (status) {
    case "saved":
      headerSuccessMessage = "Booking saved"
      break

    case "cancelled":
      headerSuccessMessage = "Booking cancelled"
      break

    case "postponed":
      headerSuccessMessage = "Booking postponed"
      break

    case "confirmed":
      headerSuccessMessage = "Booking confirmed"
      break
  }

  const name =
    listingAppointment?.contact?.name ||
    `${listingAppointment.account.firstName} ${listingAppointment.account.lastName}`
  const email =
    listingAppointment?.contact?.email || listingAppointment.account.email
  const phoneNumber =
    listingAppointment?.contact?.phoneNumber ||
    listingAppointment.account.phoneNumber

  return (
    <>
      <Header
        title={`${name} - ${formatDateTime(listingAppointment.bookingSlot)}`}
        titleTag={
          isCancelled ? "Cancelled" : isPostponed ? "Postponed" : undefined
        }
        breadcrumbs={[
          { label: "Bookings", url: "/bookings/" },
          { label: "View Booking" },
        ]}
        primaryButton={{ label: "Save", onClick: handleSave }}
        secondaryButton={secondaryButtons.length ? secondaryButtons : undefined}
        successMessage={headerSuccessMessage}
        errorMessage={status === "error" ? "Save error" : undefined}
        dateCreated={listingAppointment.dateCreated}
        dateUpdated={listingAppointment.dateUpdated}
      />
      <Container>
        <div className="flex flex-col gap-y-5 lg:grid lg:grid-cols-2 lg:gap-x-5 2xl:grid-cols-3">
          <FormPanel
            title="Seller Details"
            className="grid grid-cols-3 gap-5"
            expandHeight
          >
            <TextField
              id="name"
              title="Name"
              value={name}
              disabled={isCancelled}
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "contact",
                    value: {
                      email,
                      name: value,
                      phoneNumber,
                    },
                  })
                )
              }
            />
            <TextField
              id="email"
              title="Email"
              value={email}
              disabled={isCancelled}
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "contact",
                    value: {
                      email: value,
                      name,
                      phoneNumber,
                    },
                  })
                )
              }
            />
            <TextField
              id="phoneNumber"
              title="Phone Number"
              value={phoneNumber}
              disabled={isCancelled}
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "contact",
                    value: {
                      email,
                      name,
                      phoneNumber: value,
                    },
                  })
                )
              }
            />
            <TextField
              id="userId"
              title="User ID"
              value={listingAppointment.userId ?? ""}
              disabled={true}
              className="col-span-3"
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "userId",
                    value: value?.length ? value : undefined,
                  })
                )
              }
            />
            <TextField
              id="referrer"
              title="Referrer / Referral Code"
              value={listingAppointment.referralCode ?? ""}
              disabled={isCancelled}
              onChange={(value) =>
                dispatch(updateField({ field: "referralCode", value }))
              }
            />
          </FormPanel>
          <FormPanel
            title="Booking Details"
            className="grid gap-x-5 gap-y-5 sm:grid-cols-2 sm:gap-y-0"
            expandHeight
          >
            <div className="">
              <BookingAddressField
                id="address"
                title="Address"
                value={listingAppointment.address}
                disabled={isCancelled}
                onChange={(value) => {
                  dispatch(updateField({ field: "address", value }))
                }}
              />
            </div>
            <div className="flex flex-col">
              <DateField
                title="Date & Time"
                value={listingAppointment.bookingSlot}
                className="mb-4"
                onChange={(value) =>
                  dispatch(updateField({ field: "bookingSlot", value }))
                }
              />
              <SelectField
                id="bedrooms"
                title="Bedrooms"
                options={[
                  { label: "Studio", value: "ST" },
                  { label: "1 bedroom", value: "1" },
                  { label: "2 bedroom", value: "2" },
                  { label: "3 bedroom", value: "3" },
                  { label: "4+ bedrooms", value: "4+" },
                ]}
                value={listingAppointment.bedrooms}
                disabled={isCancelled}
                onChange={(value) => {
                  dispatch(updateField({ field: "bedrooms", value }))
                }}
                className="mb-4"
              />
              <SelectField
                id="assigned"
                title="Assigned To"
                options={[
                  { label: "Duncan", value: "duncan" },
                  { label: "Max", value: "max" },
                  { label: "Nicola", value: "nic" },
                  { label: "Rich", value: "rich" },
                ]}
                value={listingAppointment.assignedTo}
                disabled={isCancelled}
                showClearButton
                onChange={(value) => {
                  dispatch(updateField({ field: "assignedTo", value }))
                }}
                className="mb-4"
              />
              <SelectField
                id="photographer"
                title="Photographer"
                options={Object.keys(photographers).map((key) => ({
                  label: photographers[key],
                  value: key,
                }))}
                value={listingAppointment.photographer}
                disabled={isCancelled}
                showClearButton
                onChange={(value) => {
                  dispatch(updateField({ field: "photographer", value }))
                }}
                extraActions={
                  availabilityDate
                    ? [
                        {
                          key: "copy-from-availability-date",
                          label: "Copy from availability",
                        },
                      ]
                    : undefined
                }
                onExtraAction={() =>
                  dispatch(
                    updateField({
                      field: "photographer",
                      value: availabilityDate?.photographer,
                    })
                  )
                }
              />
            </div>
          </FormPanel>
          <div className="col-span-2 2xl:col-span-1">
            <FormPanel
              title="Notes"
              subtitle="For internal use only"
              expandHeight
            >
              <TextField
                id="notes"
                rows={8}
                className="md:col-span-2"
                value={listingAppointment.notes ?? ""}
                onChange={(value) => {
                  dispatch(
                    updateField({
                      field: "notes",
                      value: value?.length ? value : undefined,
                    })
                  )
                }}
              />
            </FormPanel>
          </div>
        </div>
      </Container>
      <ConfirmModal
        title="Cancel Booking"
        message="Are you sure you want to cancel this booking?"
        notificationText={
          <>
            Send <strong className="font-medium">Booking Cancelled</strong>{" "}
            email to homeowner.
          </>
        }
        confirmLabel="Yes, Cancel Booking"
        isOpen={cancelModalOpen}
        onConfirm={(sendNotification) => {
          handleCancel(sendNotification)
          setCancelModalOpen(false)
        }}
        onClose={() => {
          setCancelModalOpen(false)
        }}
      />
      <ConfirmModal
        title="Postpone Booking"
        message="Are you sure you want to postpone this booking?"
        confirmLabel="Yes, Postpone Booking"
        reversible
        isOpen={postponeModalOpen}
        onConfirm={() => {
          handlePostpone()
          setPostponeModalOpen(false)
        }}
        onClose={() => {
          setPostponeModalOpen(false)
        }}
      />
      <ConfirmModal
        title="Confirm Booking"
        message={
          <div>
            Are you sure you want to confirm the booking for
            <br />
            <strong className="font-medium">
              {formatDateTime(listingAppointment.bookingSlot)}
            </strong>
            ?
          </div>
        }
        infoBanner={
          <>
            This will change the status from{" "}
            <strong className="font-medium">Postponed</strong> to{" "}
            <strong className="font-medium">Confirmed</strong>.
          </>
        }
        notificationText={
          <>
            Send <strong className="font-medium">Booking Updated</strong> email
            to homeowner.
          </>
        }
        confirmLabel="Yes, Confirm Booking"
        reversible
        isOpen={confirmModalOpen}
        onConfirm={(sendNotification) => {
          handleConfirm(sendNotification)
          setConfirmModalOpen(false)
        }}
        onClose={() => setConfirmModalOpen(false)}
      />
    </>
  )
}
