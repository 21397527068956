import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/en-gb"
import utc from "dayjs/plugin/utc"

import advancedFormat from "dayjs/plugin/advancedFormat"

dayjs.extend(utc)

export const formatDateTime = (dateString: string): string => {
  let date = dayjs(dateString)

  if (date.year() === dayjs().year()) {
    return date.format("MMM D, h:mma")
  }

  return date.format("MMM D YYYY, h:mma")
}

export const formatDate = (dateString: string): string => {
  dayjs.extend(advancedFormat)

  let date = dayjs(dateString)

  return date.format("Do MMMM")
}

export const formatWeekday = (dateString: string): string => {
  let date = dayjs(dateString)

  return date.format("dddd")
}

export const encodeDateTime = (date: Date | Dayjs): string => {
  return dayjs.utc(dayjs(date)).format("YYYY-MM-DDTHH:mm:ss[Z]")
}

export const formatDateWithPadding = (dateString: string): string => {
  let date = dayjs(dateString)

  return date.format("YYYY-MM-DD")
}
