import { useAppDispatch } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/viewing-details"
import { Viewing } from "../../../types/viewings"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"

type Props = {
  viewing: Viewing
}

export const ViewingNotesTab: React.FC<Props> = ({ viewing }) => {
  const dispatch = useAppDispatch()

  return (
    <FormPanel
      title="Notes"
      subtitle="Displayed in account area"
      className="flex flex-col gap-y-6"
    >
      <TextField
        title="For Buyer"
        rows={8}
        value={viewing.notesBuyer}
        onChange={(value) =>
          dispatch(updateField({ field: "notesBuyer", value }))
        }
      />
      <TextField
        title="For Seller"
        rows={8}
        value={viewing.notesSeller}
        onChange={(value) =>
          dispatch(updateField({ field: "notesSeller", value }))
        }
      />
    </FormPanel>
  )
}
