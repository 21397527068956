import React, { ReactNode, useState } from "react"
import { PropertyImage, PropertyTag } from "../../../types/property"
import { ImagesTableRow } from "./images-table-row"
import { CSS } from "@dnd-kit/utilities"
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"

interface ImagesTableProps {
  images?: PropertyImage[]
  type: "photos" | "floorplans"
  className?: string
  onChange: (image: PropertyImage) => void
  onDelete: (image: PropertyImage) => void
  onMove: (images: PropertyImage[]) => void
}

interface DraggableProps {
  id: string
  children: ReactNode
}

const Draggable: React.FC<DraggableProps> = ({ id, children }) => {
  const { setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} className="group/draggable">
      {children}
    </div>
  )
}

export const ImagesTable: React.FC<ImagesTableProps> = ({
  images,
  type,
  className,
  onChange,
  onDelete,
  onMove,
}) => {
  const sensors = useSensors(useSensor(PointerSensor))

  const [sortingImage, setSortingImage] = useState<PropertyImage | undefined>()

  const handleChange = (
    image: PropertyImage,
    field: string,
    value?: string | boolean | PropertyTag[] | undefined
  ) => {
    if (!onChange) return

    let updatedImage = { ...image }

    updatedImage[field] = value

    onChange(updatedImage)
  }

  const handleDragStart = (event: DragStartEvent) => {
    if (!images) return

    setSortingImage(images.find((image) => image.id === event.active.id))
  }

  const handleDragEnd = (event: DragEndEvent) => {
    if (!images) return

    setSortingImage(undefined)

    const { active, over } = event

    if (over?.id && active.id !== over?.id) {
      let oldIndex = images
        .map((image) => image.id)
        .indexOf(active.id as string)

      let newIndex = images.map((image) => image.id).indexOf(over.id as string)

      console.log("swap", oldIndex, newIndex)

      onMove(arrayMove(images, oldIndex, newIndex))
    }
  }

  if (!images || images.length === 0) {
    return null
  }

  return (
    <div className={`px-4 sm:px-6 lg:px-8 ${className}`}>
      <div className="flow-root">
        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={images?.map((image) => image.id ?? "") ?? []}
                strategy={verticalListSortingStrategy}
              >
                {images?.map((image, i) => (
                  <Draggable id={image.id ?? ""} key={`image-${image.id}`}>
                    <ImagesTableRow
                      previousTags={
                        i > 0 && images[i - 1].tags?.length
                          ? images[i - 1].tags
                          : []
                      }
                      image={image}
                      type={type}
                      key={`image-${image.id}`}
                      index={i}
                      onChange={(field, value) =>
                        handleChange(image, field, value)
                      }
                      onDelete={() => onDelete(image)}
                    />
                  </Draggable>
                ))}
                <DragOverlay>
                  {sortingImage && (
                    <ImagesTableRow
                      image={sortingImage}
                      type={type}
                      index={
                        images?.map((i) => i.id).indexOf(sortingImage.id) ?? 0
                      }
                    />
                  )}
                </DragOverlay>
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>
    </div>
  )
}
