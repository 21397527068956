import { ReactElement } from "react"
import { SelectOption } from "../../../types/forms"

type Props = {
  title: string
  options: SelectOption[]
  value?: string[]
  error?: boolean
  onChange?: (value?: string[]) => void
}

export const CheckboxField: React.FC<Props> = ({
  title,
  options,
  value,
  error,
  onChange,
}): ReactElement => {
  const handleChange = (e: any) => {
    if (!onChange) {
      return
    }

    if (value?.includes(e.target.value)) {
      onChange(
        value?.length > 1
          ? value.filter((v) => v !== e.target.value)
          : undefined
      )
    } else {
      onChange(value ? [...value, e.target.value] : [e.target.value])
    }
  }

  return (
    <div className="col-span-2 md:col-span-1">
      <label className="block text-sm font-medium text-gray-700">{title}</label>
      <div
        className={`mt-1 flex flex-wrap items-center gap-x-4 rounded-md border px-4 text-sm text-gray-700 ${
          error ? "border-red-300" : "border-gray-300"
        }`}
      >
        {options.map((option) => (
          <div
            key={`option-${option.value}`}
            className="flex h-[38px] cursor-pointer items-center gap-2"
          >
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              checked={value?.includes(option.value ?? "")}
              onChange={handleChange}
              value={option.value}
            />
            <span>{option.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
