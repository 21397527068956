import React from "react"
import { Neighbourhood } from "../../../types/property"

type Props = {
  neighbourhood: Neighbourhood
}

const getLabel = (neighbourhood: Neighbourhood) => {
  switch (neighbourhood.status) {
    case "FO":
      return {
        text: "Filters Only",
        className: "bg-yellow-100 text-yellow-800",
      }

    case "SO":
      return {
        text: "Search Preferences Only",
        className: "bg-orange-100 text-orange-800",
      }

    case "HI":
      return {
        text: "Hidden",
        className: "bg-gray-100 text-gray-800",
      }
  }
}

export const NeighbourhoodStatusLabel: React.FC<Props> = ({
  neighbourhood,
}) => {
  const label = getLabel(neighbourhood)

  if (!label) return null

  return (
    <>
      <span
        className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${label.className}`}
      >
        {label.text}
      </span>
    </>
  )
}
