import { ReactElement, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Container } from "../../global/container"
import { Header } from "../../global/header"
import {
  WaitlistedBuyerFilters,
  decodeFilters,
  encodeFilters,
  fetchWaitlistedBuyers,
} from "../../../redux/slices/waitlisted-buyer-list"
import { WaitlistedBuyerTable } from "./waitlisted-buyer-table"
import { WaitlistedBuyerFiltersPanel } from "./waitlisted-buyer-filters"
import { useSearchParams } from "react-router-dom"

export const WaitlistedBuyerList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<WaitlistedBuyerFilters>({})
  const { waitlistedBuyers } = useAppSelector(
    (state) => state.waitlistedBuyerList
  )

  useEffect(() => {
    const filters = decodeFilters(searchParams)

    dispatch(fetchWaitlistedBuyers(filters))

    setFilters(filters)
  }, [dispatch, searchParams])

  const handleChangeFilters = (filters: WaitlistedBuyerFilters) => {
    setSearchParams(encodeFilters(filters))
  }

  return (
    <>
      <Header title="Waitlisted Buyers" />
      <Container>
        <WaitlistedBuyerFiltersPanel
          filters={filters}
          onChange={handleChangeFilters}
        />
        {waitlistedBuyers && (
          <WaitlistedBuyerTable waitlistedBuyers={waitlistedBuyers} />
        )}
      </Container>
    </>
  )
}
