import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/outline"
import { ReactElement, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  decodeFilters,
  encodeFilters,
  fetchListingAppointments,
  ListingAppointmentsFilters,
} from "../../../redux/slices/listing-appointments-list"
import { Container } from "../../global/container"
import { Header } from "../../global/header"
import { BookingsTabs } from "../bookings-tabs"
import { useSearchParams } from "react-router-dom"
import { Table } from "../../global/tables/table"
import {
  ListingAppointmentListItem,
  ListingAppointmentListItem_Address,
} from "../../../ostrich/rpc/users/listing_appointments/v1_pb"
import { formatDateTime } from "../../../util/dates"
import { upperFirstLetter } from "../../../util/text"
import { photographers } from "../../../util/misc"
import { ListingAppointmentFiltersPanel } from "./filters-panel"

export const formatBookingAddress = (
  address?: ListingAppointmentListItem_Address,
): string[] => {
  if (!address) {
    return []
  }

  let lines: string[] = []

  lines.push(address.line1)

  if (address.line2) {
    lines.push(address.line2)
  }

  lines.push(address.city)
  lines.push(address.postcode)

  return lines
}

const ListingAppointmentRow: React.FC<{
  listingAppointment: ListingAppointmentListItem
}> = ({ listingAppointment }): ReactElement => {
  return (
    <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
      <div className="flex flex-col gap-1 text-sm">
        <p className="flex items-center gap-2 text-gray-500">
          <span>{formatDateTime(listingAppointment.bookingSlot)}</span>
          {listingAppointment.status === "PO" ? (
            <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
              Postponed
            </span>
          ) : listingAppointment.status === "CA" ? (
            <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
              Cancelled
            </span>
          ) : (
            <>
              <span
                className={`inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium ${
                  listingAppointment.assignedTo
                    ? "bg-blue-100 text-blue-800"
                    : "bg-yellow-100 text-yellow-800"
                }`}
              >
                {listingAppointment.assignedTo
                  ? upperFirstLetter(listingAppointment.assignedTo)
                  : "Unassigned"}
              </span>
              {listingAppointment.photographer && (
                <span
                  className={`inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium ${"bg-green-100 text-green-800"}`}
                >
                  {photographers[listingAppointment.photographer]}
                </span>
              )}
            </>
          )}
        </p>
        <p className="truncate font-medium text-blue-600">
          {listingAppointment.contact?.name ||
            `${listingAppointment.account?.firstName} ${listingAppointment.account?.lastName}`}
        </p>
        <p className="flex gap-1 text-gray-500">
          <MailIcon className="w-5" />
          {listingAppointment.contact?.email ||
            listingAppointment.account?.email}
        </p>
        <p className="flex gap-1 text-gray-500">
          <PhoneIcon className="w-5" />
          {listingAppointment.contact?.phoneNumber ||
            listingAppointment.account?.phoneNumber}
        </p>
      </div>
      <div className="flex items-start gap-2 text-sm text-gray-800">
        <LocationMarkerIcon className="w-5 text-gray-500" />
        <div className="flex flex-col gap-1">
          {formatBookingAddress(listingAppointment.address).map((line, i) => (
            <p className={i === 0 ? "font-medium" : ""} key={`line-${i}`}>
              {line}
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}

export const BookingsList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<ListingAppointmentsFilters>({})

  useEffect(() => {
    const filters = decodeFilters(searchParams)
    dispatch(fetchListingAppointments(filters))
    setFilters(filters)
  }, [dispatch, searchParams])

  const { appointments, datesWithAppointments, pagination } = useAppSelector(
    (state) => state.listingAppointmentsList,
  )

  const handleChangeFilters = (newFilters: ListingAppointmentsFilters) => {
    const hasOtherFilterChanges = Object.keys(newFilters).some(
      (key) =>
        key !== "page" &&
        key !== "searchQuery" &&
        newFilters[key as keyof ListingAppointmentsFilters] !==
          filters[key as keyof ListingAppointmentsFilters],
    )

    const finalFilters = hasOtherFilterChanges
      ? { ...newFilters, page: 1 }
      : newFilters

    setSearchParams(encodeFilters(finalFilters))
  }

  return (
    <>
      <Header title="Bookings" />
      <BookingsTabs currentTab="Bookings" />
      <Container>
        <ListingAppointmentFiltersPanel
          filters={filters}
          onChange={handleChangeFilters}
        />
        <Table
          searchQuery={filters.searchQuery}
          selectedDate={filters.date}
          paginationData={pagination}
          availableDates={datesWithAppointments}
          onSearch={(value: string) => {
            setSearchParams(
              encodeFilters({ ...filters, searchQuery: value, page: 1 }),
            )
          }}
          onDateChange={(value: string | null) => {
            setSearchParams(
              encodeFilters({
                ...filters,
                date: value || undefined,
                page: 1,
              }),
            )
          }}
          items={appointments}
          getItemUrl={(appointment) => `/bookings/${appointment.id}/`}
          baseUrl="/bookings"
        >
          {(appointment) => (
            <ListingAppointmentRow listingAppointment={appointment} />
          )}
        </Table>
      </Container>
    </>
  )
}
