import React, { ReactElement, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  fetchNeighbourhoods,
  fetchProperty,
  fetchPropertyInsights,
  lookupAddress,
  newProperty,
  saveProperty,
} from "../../redux/slices/property-details"
import { getSiteUrl } from "../../util/api"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { PropertyForm } from "./property-form"

export const PropertyDetails: React.FC = (): ReactElement | null => {
  let { id, tab } = useParams()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [copiedPreview, setCopiedPreview] = useState(false)

  const state = useAppSelector((state) => state.propertyDetails)

  useEffect(() => {
    if (id && id != state.property?.id) {
      if (id == "new") {
        dispatch(newProperty())
      } else {
        dispatch(fetchProperty(id)).then((action: any) => {
          if (action.payload.property) {
            dispatch(fetchPropertyInsights(action.payload.property.id))
          }
        })
      }
    }
  }, [dispatch, id])

  let postcode = state.property?.address?.postcode

  useEffect(() => {
    if (postcode) {
      dispatch(lookupAddress(postcode))
    }

    dispatch(fetchNeighbourhoods())
  }, [dispatch, postcode])

  if (!state.property) {
    return null
  }

  const handleSave = async () => {
    try {
      const property = await dispatch(saveProperty()).unwrap()

      if (id === "new" && property) {
        navigate(`/properties/${property.id}/`, { replace: true })
      }
    } catch (err) {}
  }

  const handlePreview = () => {
    if (!state.property?.slug || !state.property?.token) return

    setCopiedPreview(true)

    navigator.clipboard.writeText(
      `${getSiteUrl()}/properties/${state.property.slug}/?token=${
        state.property.token
      }`
    )
  }

  let title = "New Property"

  if (state.property.displayAddress) {
    title = state.property.displayAddress
  } else if (state.listingAppointment) {
    let name =
      state.listingAppointment.contact?.name ??
      `${state.listingAppointment.account.firstName} ${state.listingAppointment.account.lastName}`

    title = `Booking from ${name}`
  }

  return (
    <>
      <Header
        title={title}
        breadcrumbs={[
          { label: "Properties", url: "/properties/" },
          { label: "Edit Property" },
        ]}
        primaryButton={{ label: "Save", onClick: handleSave }}
        secondaryButton={
          state.property?.token
            ? {
                label: copiedPreview ? "Copied Preview URL" : "Get Preview URL",
                onClick: handlePreview,
              }
            : undefined
        }
        successMessage={
          state.status === "success" ? "Property saved" : undefined
        }
        errorMessage={
          state.status === "error"
            ? state.errorMessage ?? "Save error"
            : undefined
        }
        dateCreated={state.property?.dateCreated}
        dateUpdated={state.property?.dateUpdated}
      />
      <Container>
        <PropertyForm
          property={state.property}
          listingAppointment={state.listingAppointment}
          newProperty={state.newProperty}
          currentTab={tab ?? "details"}
          onSubmit={handleSave}
        />
      </Container>
    </>
  )
}
