export const formatVerifiedFlag = (verified: string): string => {
  if (verified === "VE") {
    return "Yes"
  } else if (verified === "PR") {
    return "In Progress"
  } else if (verified === "RI") {
    return "Error"
  }

  return "No"
}
