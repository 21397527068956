import React, { Fragment, ReactNode, useEffect, useState } from "react"
import { PropertyTag } from "../../../types/property"
import { v4 as uuidv4 } from "uuid"
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Combobox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { SortableHandle } from "../../global/forms/sortable-handle"
import { TagInput } from "./tag-input"

export const tagDefaultOptions = [
  "Bay window",
  "Bespoke cabinetry",
  "Bespoke shelving",
  "Bidet",
  "Bike storage",
  "Built-in appliances",
  "Built-in murphy bed",
  "Built-in wardrobes",
  "Built-in window seat",
  "Bulthaup kitchen",
  "Comfort cooling",
  "Concealed utility space",
  "Contemporary design",
  "Corian countertop",
  "Crittall windows",
  "Decorative fireplace",
  "Double-glazed windows",
  "Double-height ceiling",
  "Double oven",
  "Double sash windows",
  "Double vanity unit",
  "Dual-aspect ",
  "Electric blinds",
  "En-suite bathroom",
  "En-suite shower room",
  "En-suite W/C",
  "Excellent condition",
  "Extensive storage",
  "Fisher & Paykal fridge freezer",
  "Floor-to-ceiling windows",
  "Freestanding bath",
  "Georgian terrace",
  "Glass roof",
  "Grade II listed",
  "Grand proportions",
  "Green roof",
  "High ceilings",
  "Home office",
  "Immaculately presented",
  "Integrated cabinetry",
  "Integrated sound system",
  "Integrated speakers",
  "Integrated wine cooler",
  "Jacuzzi bath",
  "Juliet balcony",
  "Kitchen island",
  "Breakfast bar",
  "Landscaped lawn",
  "Loft space",
  "Loft-style living",
  "Marble countertop",
  "Marble fireplace",
  "Mature garden",
  "Media space",
  "Miele appliances",
  "Minimalist design",
  "Nest thermostats",
  "Oak flooring",
  "Open-plan living",
  "Opportunity to extend the rear",
  "Opportunity to extend upwards",
  "Original fire surround",
  "Original fireplace",
  "Ornate ceiling rose",
  "Ornate cornicing",
  "Outdoor seating",
  "Parquet flooring",
  "Post-war exterior",
  "Private balcony",
  "Private drive",
  "Private garden",
  "Private patio",
  "Private study/guest bedroom",
  "Raindance shower",
  "Rayburn stove",
  "Recently modernised",
  "Requires modernisation",
  "Sash windows",
  "Sauna",
  "Secure development",
  "Separate pantry space",
  "Separate store room",
  "Shared garden",
  "Shared patio",
  "Shared terrace",
  "Shed",
  "Smeg appliances",
  "Space for a double bed",
  "Stacked balcony",
  "Stone flooring",
  "Tall built-in cupboards",
  "Tanked front vault",
  "Terrazzo floor",
  "Triple sash windows",
  "Underfloor heating",
  "Victorian terrace",
  "Walk-in shower",
  "Wine cellar",
  "Wood panelling",
]

interface TagsFieldProps {
  tags?: PropertyTag[]
  onChange: (tags?: PropertyTag[]) => void
}

interface DraggableProps {
  id: string
  children: ReactNode
}

const Draggable: React.FC<DraggableProps> = ({ id, children }) => {
  const { isDragging, setNodeRef, transform, transition } = useSortable({ id })

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  // We want to hide the original element if the user is
  // dragging the overlay - otherwise they'll see two
  // (not sure this is the best way to do this)
  style.opacity = isDragging ? 0 : 1

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  )
}

export const TagsField: React.FC<TagsFieldProps> = ({ tags, onChange }) => {
  const [internalTags, setInternalTags] = useState(tags)
  const [newTag, setNewTag] = useState<string | undefined>()

  const [sortingTag, setSortingTag] = useState<PropertyTag | undefined>()

  const sensors = useSensors(useSensor(PointerSensor))

  const filteredOptions =
    !newTag || newTag === ""
      ? tagDefaultOptions
      : tagDefaultOptions.filter((option) =>
          option
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(newTag.toLowerCase().replace(/\s+/g, ""))
        )

  useEffect(() => {
    setInternalTags(tags)
  }, [tags])

  const handleEditTag = (tag: PropertyTag, content?: string) => {
    onChange(
      internalTags?.map((t) =>
        t.id === tag.id ? { ...t, content: content ?? "" } : t
      )
    )
  }

  const handleClearEmptyTags = () => {
    onChange(internalTags?.filter((t) => t.content.trim().length > 0))
  }

  const handleCreateTag = (value: string) => {
    let updatedTags = internalTags ? [...internalTags] : []

    updatedTags.push({
      id: uuidv4(),
      content: value.trim(),
    })

    onChange(updatedTags)
    setNewTag(undefined)
  }

  const handleDragStart = (event: DragStartEvent) => {
    if (!internalTags) return

    setSortingTag(internalTags.find((tag) => tag.id === event.active.id))
  }

  const handleDragEnd = (event: DragEndEvent) => {
    if (!internalTags) return

    setSortingTag(undefined)

    const { active, over } = event

    if (over?.id && active.id !== over?.id) {
      let oldIndex = internalTags
        .map((tag) => tag.id)
        .indexOf(active.id as string)

      let newIndex = internalTags
        .map((tag) => tag.id)
        .indexOf(over.id as string)

      onChange(arrayMove(internalTags, oldIndex, newIndex))
    }
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={internalTags?.map((tag) => tag.id) ?? []}
          strategy={verticalListSortingStrategy}
        >
          {internalTags?.map((tag) => (
            <Draggable id={tag.id} key={`tag-${tag.id}`}>
              <div className="relative flex items-center rounded-sm border border-gray-300">
                <TagInput
                  key={`tag-${tag.id}`}
                  value={tag.content}
                  onChange={(content) => handleEditTag(tag, content)}
                  onBlur={handleClearEmptyTags}
                />
                <SortableHandle
                  id={tag.id}
                  className="fill-gray-300 group-hover/handle:fill-white"
                  wrapperClassName="p-1"
                />
              </div>
            </Draggable>
          ))}
          <DragOverlay>
            {sortingTag && (
              <div className="relative flex items-center rounded-sm border border-gray-300">
                <TagInput value={sortingTag.content} />
                <SortableHandle
                  id={sortingTag.id}
                  className="fill-gray-300 group-hover/handle:fill-white"
                  wrapperClassName="p-1"
                />
              </div>
            )}
          </DragOverlay>
        </SortableContext>
      </DndContext>
      <div
        className={`relative flex items-center rounded-sm border border-gray-300`}
      >
        <Combobox
          value={null}
          onChange={(value) => {
            if (!value) return

            handleCreateTag(value)
          }}
        >
          <div className="w-full">
            <div className="cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-1 pl-3 pr-10 text-sm leading-5 text-gray-900 placeholder:text-gray-400 focus:ring-0"
                placeholder="Add a new tag"
                onChange={(event) => setNewTag(event.target.value)}
              />
              <Combobox.Button className="group absolute inset-y-0 right-0 flex items-center px-1 hover:bg-gray-300">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-300 group-hover:text-white"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setNewTag("")}
            >
              <Combobox.Options className="absolute z-[1000] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {newTag &&
                  newTag.length > 0 &&
                  !tagDefaultOptions.includes(newTag) && (
                    <Combobox.Option
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-teal-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={newTag}
                    >
                      {newTag}
                    </Combobox.Option>
                  )}
                {filteredOptions.length > 0 &&
                  filteredOptions.map((option, i) => (
                    <Combobox.Option
                      key={`option-${i}`}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-teal-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </>
  )
}
