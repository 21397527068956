import { CalendarIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import { ReactElement } from "react"
import { AvailabilityDate, BookingSlotDate } from "../../../types/bookings"
import { formatDate, formatWeekday } from "../../../util/dates"
import { Link } from "react-router-dom"
import { SelectField } from "../../global/forms/select-field"
import { photographers } from "../../../util/misc"

type Props = {
  slots: BookingSlotDate
  onSetAvailability: (slot: string, available: boolean) => void
  onSetAvailabilityDate: (availabilityDate: AvailabilityDate) => void
}

const defaultNumberOfSlots = 5

export const AvailabilityRow: React.FC<Props> = ({
  slots,
  onSetAvailability,
  onSetAvailabilityDate,
}): ReactElement => {
  return (
    <div
      className={`mb-4 flex items-center gap-8 ${
        formatWeekday(slots.date) === "Monday" ? "border-t pt-4" : ""
      }`}
    >
      <div className="flex w-48 flex-col text-sm font-medium">
        <p className="relative text-xs font-normal text-gray-600">
          {formatWeekday(slots.date)}
        </p>
        <p className="flex items-center gap-x-2">
          {formatDate(slots.date)}
          {slots.bookingCount > 0 && (
            <Link
              to={`/bookings/?date=${slots.date}`}
              className="flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium leading-none text-blue-700 ring-1 ring-inset ring-blue-700/10 hover:bg-blue-700 hover:text-white"
            >
              <CalendarIcon className="mr-1 w-3" />
              {slots.bookingCount}
            </Link>
          )}
        </p>
      </div>
      {slots.times.map((time) => (
        <div
          className="group flex cursor-pointer items-center rounded-md border border-gray-100 px-2 text-center text-sm hover:bg-gray-50"
          key={`time-${time.start}`}
          onClick={() => onSetAvailability(time.slot, !time.available)}
        >
          {time.available ? (
            <EyeIcon className={`w-4 text-green-400`} />
          ) : (
            <EyeOffIcon className={`w-4 text-red-400`} />
          )}
          <span className="ml-2 border-l border-gray-100 py-2 pl-2">
            {time.start}
          </span>
        </div>
      ))}
      {slots.times.length < defaultNumberOfSlots && (
        <>
          {new Array(defaultNumberOfSlots - slots.times.length)
            .fill(null)
            .map((_, index) => (
              <div key={`empty-${index}`} className="mr-2 w-24" />
            ))}
        </>
      )}
      <SelectField
        value={slots.availabilityDate?.photographer}
        options={[
          { value: undefined, label: "-" },
          ...Object.keys(photographers).map((value) => ({
            value,
            label: photographers[value],
          })),
        ]}
        onChange={(value) => {
          onSetAvailabilityDate({
            date: new Date(slots.date),
            photographer: value,
          })
        }}
        className="w-24"
      />
    </div>
  )
}
