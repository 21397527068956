import { User } from "../types/user"
import { getEndpoint } from "../util/api"

export const getUser = async (userId: string): Promise<User> => {
  const response = await fetch(`${getEndpoint()}/users/${userId}/`, {
    cache: "no-cache",
    credentials: "include",
  })

  const json = (await response.json()) as {
    user?: User
  }

  if (!json.user) {
    return Promise.reject()
  }

  return Promise.resolve(json.user)
}
