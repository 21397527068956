import { ReactElement, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Container } from "../../global/container"
import { Header } from "../../global/header"
import { fetchNeighbourhoods } from "../../../redux/slices/neighbourhoods-list"
import { NeighbourhoodsTable } from "./neighbourhoods-table"
import { useNavigate } from "react-router-dom"

export const NeighbourhoodsList: React.FC = (): ReactElement => {
  const navigate = useNavigate()
  const { neighbourhoods } = useAppSelector((state) => state.neighbourhoodsList)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchNeighbourhoods())
  }, [dispatch])

  return (
    <>
      <Header
        title="Neighbourhoods"
        primaryButton={{
          label: "Create Neighbourhood",
          onClick: () => navigate("/neighbourhoods/new/"),
        }}
      />
      <Container>
        {neighbourhoods && (
          <NeighbourhoodsTable neighbourhoods={neighbourhoods} />
        )}
      </Container>
    </>
  )
}
