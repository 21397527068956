import { ExternalLinkIcon } from "@heroicons/react/solid"

export type FieldTitleExtraAction = {
  key: string
  label: string
  href?: string
  external?: boolean
}

type Props = {
  title: string
  subtitle?: string
  extraActions?: FieldTitleExtraAction[]
  onExtraAction?: (key: string) => void
}

export const FieldTitle: React.FC<Props> = ({
  title,
  subtitle,
  extraActions,
  onExtraAction,
}) => {
  return (
    <label
      htmlFor="description"
      className="mb-1 flex items-center text-sm font-medium text-gray-700"
    >
      {title}
      {subtitle && (
        <span className="ml-2 border-l border-l-gray-500 pl-2 text-xs font-normal text-gray-500">
          {subtitle}
        </span>
      )}
      {extraActions &&
        extraActions.map((action) => (
          <a
            href={action.href}
            onClick={() => {
              if (onExtraAction && !action.href) onExtraAction(action.key)
            }}
            target={action.external ? "_blank" : ""}
            className="ml-2 flex cursor-pointer border-l border-l-gray-500 pl-2 text-xs font-normal text-gray-500 hover:text-blue-500"
            key={`action-${action.key}`}
          >
            {action.label}
            {action.external && <ExternalLinkIcon className="ml-1 w-3" />}
          </a>
        ))}
    </label>
  )
}
