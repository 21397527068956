import React from "react"
import { WaitlistedBuyer } from "../../../types/waitlisted-buyers"
import { ActivityFeedItem } from "../../global/feeds/activity-feed-item"

type Props = {
  waitlistedBuyer: WaitlistedBuyer
  onEdit: () => void
  onReview: (approved: boolean) => void
}

export const ActivityFeed: React.FC<Props> = ({
  waitlistedBuyer,
  onEdit,
  onReview,
}) => {
  const buyingAgent = Boolean(waitlistedBuyer.companyName)

  return (
    <div className="flow-root">
      <ul>
        <ActivityFeedItem
          title={`*${waitlistedBuyer.user.firstName} ${waitlistedBuyer.user.lastName}* added to waitlist`}
          date={waitlistedBuyer.dateCreated}
          content={
            <div>
              {waitlistedBuyer.note && (
                <>
                  <h3 className="mb-1 text-xs font-medium text-gray-500">
                    What appeals about the property
                  </h3>
                  <p className="mb-1.5">{waitlistedBuyer.note}</p>
                </>
              )}
              <h3 className="mb-1 text-xs font-medium text-gray-500">
                Tell us about{" "}
                {buyingAgent ? "your client's search" : "yourself"}
              </h3>
              <p>{waitlistedBuyer.bioBuyer}</p>
            </div>
          }
          icon="PL"
          approved={waitlistedBuyer.approved}
          showEdit={
            waitlistedBuyer.status !== "DE" && waitlistedBuyer.approved === null
          }
          showReview={waitlistedBuyer.status !== "DE"}
          onEdit={() => onEdit()}
          onReview={(approved) => onReview(approved)}
        />
        {waitlistedBuyer.dateReviewed && (
          <ActivityFeedItem
            title={`*${
              waitlistedBuyer.approved ? "Approved" : "Rejected"
            }* by *${waitlistedBuyer.reviewedBy ?? "Ostrich"}*`}
            date={waitlistedBuyer.dateReviewed}
            icon={waitlistedBuyer.approved ? "AP" : "RE"}
            isChild
          />
        )}
      </ul>
    </div>
  )
}
