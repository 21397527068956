import {
  InformationCircleIcon,
  PhotographIcon,
  LocationMarkerIcon,
  CurrencyPoundIcon,
  HomeIcon,
  DocumentTextIcon,
  UserGroupIcon,
  ClipboardCheckIcon,
  CameraIcon,
  PencilAltIcon,
  BookOpenIcon,
  ChartBarIcon,
} from "@heroicons/react/outline"
import { ExclamationCircleIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { useAppSelector } from "../../redux/hooks"
import { Property } from "../../types/property"
import { classNames } from "../../util/misc"
import { DescriptionTab } from "./tabs/description-tab"
import { ImagesTab } from "./tabs/images-tab"
import { PropertyDetailsTab } from "./tabs/property-details-tab"
import { RenovationTab } from "./tabs/renovation-tab"
import { SalesTab } from "./tabs/sales-tab"
import { TravelTab } from "./tabs/travel-tab"
import { UsersTab } from "./tabs/users-tab"
import { OffersTab } from "./tabs/offers-tab"
import { AMLTab } from "./tabs/aml-tab"
import { ViewingsTab } from "./tabs/viewings-tab"
import { NotesTab } from "./tabs/notes-tab"
import { ListingAppointment } from "../../types/listingAppointment"
import { WaitlistedBuyersTab } from "./tabs/waitlisted-buyers-tab"
import { InsightsTab } from "./tabs/insights-tab"

const navigation = [
  {
    id: "details",
    name: "Property Details",
    icon: InformationCircleIcon,
  },
  { id: "description", name: "Description", icon: DocumentTextIcon },
  {
    id: "images",
    name: "Photos & Floorplans",
    icon: PhotographIcon,
  },
  {
    id: "renovation",
    name: "Renovation",
    icon: HomeIcon,
  },
  {
    id: "travel",
    name: "Travel",
    icon: LocationMarkerIcon,
  },
  {
    id: "aml",
    name: "AML",
    icon: ClipboardCheckIcon,
  },
  {
    id: "sales",
    name: "Sales",
    icon: CurrencyPoundIcon,
  },
  {
    id: "notes",
    name: "Notes",
    icon: PencilAltIcon,
  },
  {
    id: "users",
    name: "Users",
    icon: UserGroupIcon,
  },
  {
    id: "offers",
    name: "Offers",
    icon: CurrencyPoundIcon,
  },
  {
    id: "waitlisted-buyers",
    name: "Waitlisted Buyers",
    icon: BookOpenIcon,
  },
  {
    id: "viewings",
    name: "Viewings",
    icon: CameraIcon,
  },
  {
    id: "insights",
    name: "Insights",
    icon: ChartBarIcon,
  },
]

type Props = {
  property: Property
  listingAppointment?: ListingAppointment
  newProperty?: boolean
  currentTab?: string
  onSubmit: () => void
}

export const PropertyForm: React.FC<Props> = ({
  property,
  listingAppointment,
  newProperty,
  currentTab,
  onSubmit,
}): ReactElement => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit()
  }

  let { sectionErrors } = useAppSelector((state) => state.propertyDetails)

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="px-2 py-6 sm:px-6 lg:col-span-3 lg:px-0 lg:py-0 2xl:col-span-2">
        <nav className="space-y-1">
          {navigation.map((item) => {
            let errorIcon: ReactElement | undefined

            if (sectionErrors && sectionErrors[item.id]) {
              errorIcon = (
                <ExclamationCircleIcon className="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-red-300" />
              )
            }

            return (
              <Link
                to={
                  `/properties/${newProperty ? "new" : property.id}/` +
                  (item.id !== "details" ? `${item.id}/` : "")
                }
                key={item.id}
                className={classNames(
                  item.id === currentTab
                    ? "bg-gray-50 text-indigo-700 hover:bg-white hover:text-indigo-700"
                    : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                  "group flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
                )}
                aria-current={item.id === currentTab ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.id === currentTab
                      ? "text-indigo-500 group-hover:text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-1 mr-3 h-6 w-6 flex-shrink-0"
                  )}
                  aria-hidden="true"
                />
                <span className="flex-grow truncate">{item.name}</span>
                {errorIcon}
              </Link>
            )
          })}
        </nav>
      </aside>
      <form
        className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 2xl:col-span-10"
        onSubmit={handleSubmit}
      >
        {currentTab === "details" && (
          <PropertyDetailsTab
            property={property}
            listingAppointment={listingAppointment}
          />
        )}
        {currentTab === "description" && <DescriptionTab property={property} />}
        {currentTab === "images" && <ImagesTab property={property} />}
        {currentTab === "renovation" && <RenovationTab property={property} />}
        {currentTab === "travel" && <TravelTab property={property} />}
        {currentTab === "aml" && <AMLTab property={property} />}
        {currentTab === "sales" && <SalesTab property={property} />}
        {currentTab === "notes" && <NotesTab property={property} />}
        {currentTab === "users" && <UsersTab property={property} />}
        {currentTab === "offers" && <OffersTab property={property} />}
        {currentTab === "waitlisted-buyers" && (
          <WaitlistedBuyersTab property={property} />
        )}
        {currentTab === "viewings" && <ViewingsTab property={property} />}
        {currentTab === "insights" && <InsightsTab property={property} />}
      </form>
    </div>
  )
}
