import { ReactElement } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Property, PropertyImage } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import {
  updateField,
  uploadImages,
} from "../../../redux/slices/property-details"
import { ImageField } from "../fields/image-field"
import { UploadField } from "../../global/forms/upload-field"
import { ImagesTable } from "../fields/images-table"

type Props = {
  property: Property
}

export const ImagesTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()

  const { uploadingImages } = useAppSelector((state) => state.propertyDetails)

  const handleChange = (image: PropertyImage, type: string) => {
    if (!property) return

    let images =
      type == "photos"
        ? [...(property.photos ?? [])]
        : [...(property.floorplans ?? [])]

    let index = images.findIndex((i) => i.id === image.id)

    images[index] = image

    // If we've set this to the preview image,
    // clear the flag on all the others
    if (image.preview) {
      images = images.map((i) => {
        if (i.id !== image.id) {
          return { ...i, preview: false }
        }

        return i
      })
    }

    if (image.previewAlt) {
      images = images.map((i) => {
        if (i.id !== image.id) {
          return { ...i, previewAlt: false }
        }

        return i
      })
    }

    dispatch(
      updateField({
        field: type,
        value: images,
      })
    )
  }

  const handleDelete = (image: PropertyImage, type: string) => {
    if (type == "photos") {
      dispatch(
        updateField({
          field: "photos",
          value: property.photos?.filter((p) => p.id !== image.id) ?? [],
        })
      )
    } else {
      dispatch(
        updateField({
          field: "floorplans",
          value: property.floorplans?.filter((p) => p.id !== image.id) ?? [],
        })
      )
    }
  }

  const handleMove = (images: PropertyImage[], type: string) => {
    dispatch(
      updateField({
        field: type,
        value: images,
      })
    )
  }

  return (
    <div className="flex flex-col gap-y-5 2xl:grid 2xl:grid-cols-2 2xl:gap-x-5">
      <div className="shadow sm:rounded-md">
        <div className="h-full overflow-clip bg-white sm:rounded-b-md sm:rounded-t-md">
          <div className="sticky top-0 z-[2000] flex items-center justify-between border-b border-gray-200 bg-white pl-4 last:border-b-0">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-600">
                Photos
              </h3>
            </div>
            <div>
              <UploadField
                onUpload={(files) =>
                  dispatch(uploadImages({ files, type: "photos" }))
                }
                uploading={uploadingImages}
              />
            </div>
          </div>
          <ImagesTable
            images={property.photos}
            type="photos"
            className="-mt-px"
            onChange={(image) => handleChange(image, "photos")}
            onDelete={(image) => handleDelete(image, "photos")}
            onMove={(images) => handleMove(images, "photos")}
          />
        </div>
      </div>
      <div className="shadow sm:rounded-md">
        <div className="h-full overflow-clip bg-white sm:rounded-b-md sm:rounded-t-md">
          <div className="sticky top-0 z-[2000] flex items-center justify-between border-b border-gray-200 bg-white pl-4 last:border-b-0">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-600">
                Floorplans
              </h3>
            </div>
            <div>
              <UploadField
                onUpload={(files) =>
                  dispatch(uploadImages({ files, type: "floorplans" }))
                }
                uploading={uploadingImages}
              />
            </div>
          </div>
          <ImagesTable
            images={property.floorplans}
            type="floorplans"
            className="-mt-px"
            onChange={(image) => handleChange(image, "floorplans")}
            onDelete={(image) => handleDelete(image, "floorplans")}
            onMove={(images) => handleMove(images, "floorplans")}
          />
        </div>
      </div>
    </div>
  )
}
