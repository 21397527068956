import { ReactElement, useEffect, useState } from "react"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  fetchUserViewingsAndOffers,
  saveUser,
} from "../../redux/slices/user-details"
import { DeleteUserModal } from "./delete-user-modal"
import { getEndpoint } from "../../util/api"
import { UserForm } from "./user-form"

export const UserDetails: React.FC = (): ReactElement | null => {
  let { id, tab } = useParams()

  const dispatch = useAppDispatch()

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState<string | undefined>()

  useEffect(() => {
    if (!id) return

    dispatch(fetchUserViewingsAndOffers(id))
  }, [dispatch, id])

  const handleSave = async () => {
    await dispatch(saveUser())
  }

  let { user, status, viewings, offers } = useAppSelector(
    (state) => state.userDetails
  )

  const handleDelete = async () => {
    if (!user?.id) return

    setDeleteLoading(true)

    try {
      const response = await fetch(`${getEndpoint()}/users/${user.id}/`, {
        method: "DELETE",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })

      const json = (await response.json()) as { error?: string }

      setDeleteLoading(false)
      setDeleteModalVisible(false)

      if (json.error) {
        setDeleteStatus("error")
      } else {
        setDeleteStatus("success")
      }
    } catch (e: any) {
      setDeleteLoading(false)
      setDeleteModalVisible(false)
      setDeleteStatus("error")
    }
  }

  if (!user) return null

  let successMessage: string | undefined
  let errorMessage: string | undefined

  if (status === "success") {
    successMessage = "User saved"
  } else if (status === "error") {
    errorMessage = "Save error"
  }

  if (deleteStatus === "success") {
    successMessage = "User deleted"
  } else if (deleteStatus === "error") {
    errorMessage = "Delete error"
  }

  return (
    <>
      <Header
        title={`${user.firstName} ${user.lastName}`}
        breadcrumbs={[
          { label: "Users", url: "/users/" },
          { label: "View User" },
        ]}
        primaryButton={
          deleteStatus === "success"
            ? undefined
            : { label: "Save", onClick: handleSave }
        }
        secondaryButton={
          deleteStatus === "success"
            ? undefined
            : {
                label: "Delete",
                onClick: () => setDeleteModalVisible(true),
              }
        }
        successMessage={successMessage}
        errorMessage={errorMessage}
        dateCreated={user.dateCreated}
      />
      <Container>
        <UserForm
          user={user}
          viewings={viewings}
          offers={offers}
          currentTab={tab ?? "details"}
          onSubmit={handleSave}
        />
      </Container>
      <DeleteUserModal
        email={user.email}
        isOpen={deleteModalVisible}
        isLoading={deleteLoading}
        onClose={() => setDeleteModalVisible(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
