import { ReactElement } from "react"
import { FilterOption } from "../global/filter-option"
import { OfferFilters } from "../../redux/slices/offer-list"

const statuses = [
  { key: "review-required", label: "Review Required" },
  { key: "awaiting-response", label: "Awaiting Response" },
  { key: "in-discussion", label: "In Discussion" },
  { key: "rejected", label: "Rejected" },
  { key: "timed-out", label: "Timed Out" },
  { key: "closed", label: "Closed" },
  { key: "viewing-offered", label: "Viewing offered" },
  { key: "viewing-arranged", label: "Viewing arranged" },
  { key: "availability-requested", label: "Availability requested" },
  { key: "alternative-slots-requested", label: "Alternative slots requested" },
]

type Props = {
  filters: OfferFilters
  onChange: (filters: OfferFilters) => void
}

export const OfferFiltersPanel: React.FC<Props> = ({
  filters,
  onChange,
}): ReactElement => {
  const changeStatus = (status?: string) => {
    let updatedStatus: string[] = []

    if (status) {
      if (filters.status?.includes(status)) {
        updatedStatus = filters.status.filter((s) => s !== status)
      } else {
        updatedStatus = filters.status ? [...filters.status, status] : [status]
      }
    }

    if (updatedStatus.length === 5) {
      updatedStatus = []
    }

    onChange({ ...filters, status: updatedStatus })
  }

  const changeIncludeUnverifiedUsers = (includeUnverifiedUsers: boolean) => {
    onChange({ ...filters, includeUnverifiedUsers })
  }

  return (
    <div className="flex flex-col justify-between gap-0 overflow-hidden bg-white text-sm text-gray-800 shadow sm:mb-6 sm:flex-row sm:gap-12 sm:rounded-md sm:px-6 lg:mb-8">
      <div className="flex items-center divide-x">
        <div className="flex h-full items-center">
          <span className="w-1/3 p-4 font-medium sm:w-auto sm:pl-0">
            Status
          </span>
        </div>
        <FilterOption
          label="All"
          checked={!filters.status}
          onToggle={() => changeStatus()}
        />
        <div className="grid grid-cols-5 [&>*:nth-child(n+1)]:border-r">
          {statuses.map(({ key, label }) => (
            <FilterOption
              key={key}
              label={label}
              checked={Boolean(filters.status?.includes(key))}
              onToggle={() => changeStatus(key)}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center border-l">
        <FilterOption
          label="Include unverified users"
          checked={!!filters.includeUnverifiedUsers}
          onToggle={() =>
            changeIncludeUnverifiedUsers(!filters.includeUnverifiedUsers)
          }
        />
      </div>
    </div>
  )
}
