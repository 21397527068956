import { ReactElement, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/user-details"
import { BooleanField } from "../../global/forms/boolean-field"
import { FormPanel } from "../../global/forms/form-panel"
import { SelectField } from "../../global/forms/select-field"
import { TextField } from "../../global/forms/text-field"
import { useParams } from "react-router-dom"
import { fetchUser } from "../../../redux/slices/user-details"

export const UserDetailsTab: React.FC = (): ReactElement => {
  let { user, errors } = useAppSelector((state) => state.userDetails)
  let { id } = useParams()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!id) return

    dispatch(fetchUser(id))
  }, [dispatch, id])

  if (!user) return <></>

  return (
    <div className="mb-5 flex flex-col gap-y-5 lg:grid lg:grid-cols-12 lg:gap-x-5">
      <div className="col-span-4">
        <FormPanel title="User Details" expandHeight>
          <TextField
            title="Email"
            value={user.email}
            className="col-span-2"
            disabled
          />
          <TextField
            id="first_name"
            title="First Name"
            value={user.firstName}
            error={errors?.firstName}
            onChange={(value) =>
              dispatch(updateField({ field: "firstName", value }))
            }
          />
          <TextField
            id="last_name"
            title="Last Name"
            value={user.lastName}
            error={errors?.lastName}
            onChange={(value) =>
              dispatch(updateField({ field: "lastName", value }))
            }
          />
          <TextField
            id="phone_number"
            title="Phone Number"
            value={user.phoneNumber}
            onChange={(value) =>
              dispatch(updateField({ field: "phoneNumber", value }))
            }
          />
          <SelectField
            title="Signup Type"
            value={user.signupType}
            options={[
              { label: "Buyer", value: "BU" },
              { label: "Seller", value: "SE" },
              { label: "Buying Agent", value: "BA" },
            ]}
            onChange={(value) =>
              dispatch(updateField({ field: "signupType", value }))
            }
          />
          <BooleanField
            title="Verified Email?"
            checked={user.verified.email}
            onChange={(checked) =>
              dispatch(
                updateField({
                  field: "verified",
                  value: { ...user?.verified, email: checked },
                })
              )
            }
            isDropdown
          />
          <SelectField
            title="Verified Identity?"
            options={[
              { label: "Unverified" },
              { label: "Verified", value: "VE" },
              { label: "Requires Input", value: "RI" },
              { label: "Processing", value: "PR" },
            ]}
            value={user.verified.stripe}
            onChange={(value) =>
              dispatch(
                updateField({
                  field: "verified",
                  value: {
                    ...user?.verified,
                    stripe: value,
                  },
                })
              )
            }
          />
        </FormPanel>
      </div>
      <div className="col-span-4">
        {user.signupType === "BA" ? (
          <FormPanel title="Buying Agent" expandHeight>
            <TextField
              title="Company Name"
              value={user.companyName ?? ""}
              error={errors?.companyName}
              onChange={(value) =>
                dispatch(updateField({ field: "companyName", value }))
              }
            />
            <TextField
              title="Company Website"
              value={user.companyWebsite ?? ""}
              error={errors?.companyWebsite}
              onChange={(value) =>
                dispatch(updateField({ field: "companyWebsite", value }))
              }
            />
            <TextField
              title="AML Registration Number"
              value={user.amlRegNo ?? ""}
              error={errors?.amlRegNo}
              onChange={(value) =>
                dispatch(updateField({ field: "amlRegNo", value }))
              }
            />
          </FormPanel>
        ) : (
          <FormPanel title="Address" expandHeight>
            <TextField
              id="address_line1"
              title="Line 1"
              value={user.address?.line1 ?? ""}
              error={errors?.addressLine1}
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "address",
                    value: { ...user?.address, line1: value },
                  })
                )
              }
            />
            <TextField
              id="address_line2"
              title="Line 2"
              value={user.address?.line2 ?? ""}
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "address",
                    value: { ...user?.address, line2: value },
                  })
                )
              }
            />
            <TextField
              id="address_city"
              title="City"
              value={user.address?.city ?? ""}
              error={errors?.addressCity}
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "address",
                    value: { ...user?.address, city: value },
                  })
                )
              }
            />
            <TextField
              id="address_postcode"
              title="Postcode"
              value={user.address?.postcode ?? ""}
              error={errors?.addressPostcode}
              onChange={(value) =>
                dispatch(
                  updateField({
                    field: "address",
                    value: { ...user?.address, postcode: value },
                  })
                )
              }
            />
            <BooleanField
              title="Home Owner?"
              checked={user.address?.homeOwner ?? false}
              onChange={(checked) =>
                dispatch(
                  updateField({
                    field: "address",
                    value: { ...user?.address, homeOwner: checked },
                  })
                )
              }
              isDropdown
            />
          </FormPanel>
        )}
      </div>
      <div className="col-span-4">
        <FormPanel title="Internal Use" expandHeight className="">
          <TextField
            id="notes"
            title="Notes"
            value={user.notes}
            onChange={(value) =>
              dispatch(updateField({ field: "notes", value }))
            }
            rows={8}
          />
        </FormPanel>
      </div>
    </div>
  )
}
