import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { Offer } from "../../types/offer"
import { OfferStatusLabel } from "../offer-list/status-label"
import { formatDateTime } from "../../util/dates"

type Props = {
  offers: Offer[]
}

export const AdditionalOffersTable: React.FC<Props> = ({
  offers,
}): ReactElement => {
  return (
    <table className="col-span-2 min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="w-1/3 py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
          >
            Date
          </th>
          <th
            scope="col"
            className="w-1/3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
          >
            Offer
          </th>
          <th
            scope="col"
            className="w-1/3 py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
          >
            Status
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {offers?.map((offer) => (
          <tr key={offer.id}>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
              <Link
                to={`/offers/${offer.id}/`}
                className="font-medium text-indigo-600 hover:text-indigo-900"
                target="_blank"
              >
                {formatDateTime(offer.dateCreated)}
              </Link>
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
              {offer.offer
                ? `£${offer.offer?.toLocaleString()}`
                : "Buying agent"}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
              <OfferStatusLabel offer={offer} style="large" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
