import { ExclamationIcon } from "@heroicons/react/outline"
import { Link, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { ReactElement, useEffect, useState } from "react"
import { Container } from "../../global/container"
import { Header } from "../../global/header"
import {
  deleteWaitlistedBuyer,
  editWaitlistedBuyerNotes,
  fetchWaitlistedBuyer,
  reviewWaitlistedBuyer,
} from "../../../redux/slices/waitlisted-buyer-details"
import { FormPanel } from "../../global/forms/form-panel"
import { formatFundingPosition } from "../../../util/offers"
import { FieldTitle } from "../../global/forms/field-title"
import { formatVerifiedFlag } from "../../../util/users"
import { WaitlistedBuyerStatusLabel } from "./status-label"
import { ActivityFeed } from "./activity-feed"
import { ConfirmModal } from "../../global/modals/confirm-modal"
import { ReviewModal } from "./review-modal"
import { EditNotesModal } from "../../global/modals/edit-notes-modal"

export const WaitlistedBuyerDetails: React.FC = (): ReactElement | null => {
  let { id } = useParams()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editNotesModalOpen, setEditNotesModalOpen] = useState(false)
  const [reviewModalOpen, setReviewModalOpen] = useState(false)

  const [reviewApprove, setReviewApprove] = useState(false)

  let { waitlistedBuyer } = useAppSelector(
    (state) => state.waitlistedBuyerDetails
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!id) return

    dispatch(fetchWaitlistedBuyer(id))

    setDeleteModalOpen(false)
  }, [dispatch, id])

  const handleReview = (approved: boolean) => {
    if (!id) return

    dispatch(reviewWaitlistedBuyer({ id, approved }))
  }

  const handleEditNotes = (value: { note?: string; bioBuyer?: string }) => {
    if (!id) return

    dispatch(editWaitlistedBuyerNotes({ id, ...value }))
  }

  const handleDelete = () => {
    if (!id) return

    dispatch(deleteWaitlistedBuyer(id))
  }

  if (!waitlistedBuyer) return null

  const buyingAgent = Boolean(waitlistedBuyer.companyName)

  return (
    <>
      <Header
        title={`Waitlisted Buyer for ${waitlistedBuyer.property.displayAddress}`}
        breadcrumbs={[
          { label: "Waitlisted Buyers", url: "/waitlisted-buyers/" },
          { label: "View Waitlisted Buyer" },
        ]}
        secondaryButton={
          waitlistedBuyer.status !== "DE"
            ? {
                label: "Delete Waitlisted Buyer",
                onClick: () => setDeleteModalOpen(true),
              }
            : undefined
        }
        dateCreated={waitlistedBuyer.dateCreated}
        dateUpdated={waitlistedBuyer.dateUpdated}
      />
      <Container>
        <div className="mb-5 flex flex-col gap-y-5 xl:grid xl:grid-cols-12 xl:gap-x-5">
          <div className="col-span-4 row-span-2">
            <FormPanel
              title="Waitlist"
              className="grid grid-cols-3"
              expandHeight
            >
              <div className="col-span-3 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Funding Position
                </label>
                <div className="mt-1">
                  {formatFundingPosition(waitlistedBuyer.fundingPosition)}
                </div>
              </div>
              <div className="col-span-3 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Home to Sell First?
                </label>
                <div className="mt-1">
                  {waitlistedBuyer.homeSeller ? "Yes" : "No"}
                </div>
              </div>
              <div className="col-span-3 mt-6 border-t pt-6">
                <label className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <div className="mt-1 flex items-center">
                  <WaitlistedBuyerStatusLabel
                    waitlistedBuyer={waitlistedBuyer}
                    style="large"
                  />
                </div>
              </div>
            </FormPanel>
          </div>
          <div className="col-span-4 row-span-2">
            <FormPanel title="Property" expandHeight>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Display Address
                </label>
                <div className="mt-1">
                  <Link
                    to={`/properties/${waitlistedBuyer.property.id}/`}
                    className="font-medium text-blue-600"
                  >
                    {waitlistedBuyer.property.displayAddress}
                  </Link>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Price Guide
                </label>
                <div className="mt-1">
                  £{waitlistedBuyer.property.guidePrice?.toLocaleString()}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Building Type
                </label>
                <div className="mt-1">
                  {waitlistedBuyer.property.attributes.buildingType}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Interior Size
                </label>
                <div className="mt-1">
                  {waitlistedBuyer.property.attributes.interiorSize?.toLocaleString()}{" "}
                  sq ft
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Bedrooms
                </label>
                <div className="mt-1">
                  {waitlistedBuyer.property.attributes.bedrooms}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Bathrooms
                </label>
                <div className="mt-1">
                  {waitlistedBuyer.property.attributes.bathrooms}
                </div>
              </div>
            </FormPanel>
          </div>
          <div className="col-span-4">
            <FormPanel
              title={buyingAgent ? "Buying Agent" : "Buyer"}
              className="grid grid-cols-2 gap-6"
            >
              <div className="col-span-2 md:col-span-1">
                <FieldTitle
                  title="Name"
                  extraActions={[
                    {
                      key: "view-user",
                      label: "View full details",
                      href: `/users/${waitlistedBuyer.user.id}/`,
                      external: true,
                    },
                  ]}
                />
                <div className="mt-1">
                  <Link
                    to={`/users/${waitlistedBuyer.user.id}/`}
                    target="_blank"
                  >
                    {waitlistedBuyer.user.firstName}{" "}
                    {waitlistedBuyer.user.lastName}
                  </Link>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  E-mail
                </label>
                <div className="mt-1 break-all">
                  {waitlistedBuyer.user.email}
                </div>
              </div>
              {buyingAgent && (
                <>
                  <div className="col-span-2 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Company Name
                    </label>
                    <div className="mt-1">{waitlistedBuyer.companyName}</div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Company Website
                    </label>
                    <div className="mt-1">{waitlistedBuyer.companyWebsite}</div>
                  </div>
                </>
              )}
            </FormPanel>
          </div>
          <div className="col-span-4">
            <FormPanel title="Seller" className="grid grid-cols-3" expandHeight>
              {waitlistedBuyer.sellers ? (
                <>
                  <div className="col-span-3 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <div className="mt-1">
                      {waitlistedBuyer.sellers.map((seller, i) => (
                        <p key={`seller-${i}`}>
                          {seller.firstName} {seller.lastName}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      E-mail
                    </label>
                    <div className="mt-1">
                      {waitlistedBuyer.sellers.map((seller, i) => (
                        <p key={`seller-${i}`}>{seller.email}</p>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-span-3 border-l-4 border-red-400 bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        <strong className="font-medium">
                          This property does not have an assigned seller.
                        </strong>
                        <br />
                        You won't be able to approve this offer until a user is
                        added.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </FormPanel>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-5">
          <div className="lg:flex-grow">
            <FormPanel title="Activity">
              <ActivityFeed
                waitlistedBuyer={waitlistedBuyer}
                onEdit={() => setEditNotesModalOpen(true)}
                onReview={(approved) => {
                  setReviewApprove(approved)
                  setReviewModalOpen(true)
                }}
              />
            </FormPanel>
          </div>
        </div>
      </Container>
      <ReviewModal
        isOpen={reviewModalOpen}
        approve={reviewApprove}
        onConfirm={(approved) => {
          handleReview(approved)
          setReviewModalOpen(false)
        }}
        onCancel={() => {
          setReviewApprove(false)
          setReviewModalOpen(false)
        }}
      />
      <EditNotesModal
        isOpen={editNotesModalOpen}
        initialValue={{
          note: waitlistedBuyer.note,
          bioBuyer: waitlistedBuyer.bioBuyer,
        }}
        buyingAgent={buyingAgent}
        onConfirm={(value) => {
          handleEditNotes(value)
          setEditNotesModalOpen(false)
        }}
        onCancel={() => {
          setEditNotesModalOpen(false)
        }}
      />
      <ConfirmModal
        isOpen={deleteModalOpen}
        title="Delete Waitlisted Buyer"
        message="Are you sure you want to delete this waitlisted buyer?"
        confirmLabel="Yes, Delete"
        onConfirm={() => {
          handleDelete()
          setDeleteModalOpen(false)
        }}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  )
}
