import { CheckIcon } from "@heroicons/react/outline"
import { ReactElement } from "react"

type Props = {
  label: string
  checked: boolean
  onToggle: () => void
}

export const FilterOption: React.FC<Props> = ({
  label,
  checked,
  onToggle,
}): ReactElement => {
  return (
    <div
      className={
        "flex h-full w-1/3 cursor-pointer select-none items-center justify-between px-4 py-3 hover:bg-gray-50 sm:w-auto sm:justify-start"
      }
      onClick={onToggle}
    >
      {label}
      <span
        className={`ml-2 inline-block h-4 w-4 rounded border transition-colors ${
          checked ? "border-blue-500 bg-blue-500" : "border-gray-300 bg-white"
        }`}
      >
        <CheckIcon
          className={`text-white transition-opacity ${
            checked ? "opacity-1" : "opacity-0"
          }`}
        />
      </span>
    </div>
  )
}
