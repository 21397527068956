import { PlusIcon } from "@heroicons/react/outline"
import { ReactElement } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  addRenovation,
  copyRenovation,
  deleteRenovation,
  updateField,
} from "../../../redux/slices/property-details"
import { Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"
import { RenovationField } from "../fields/renovation-field"

type Props = {
  property: Property
}

export const RenovationTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()

  const { errors } = useAppSelector((state) => state.propertyDetails)

  return (
    <>
      <FormPanel>
        <TextField
          id="potential_improvements"
          title="Potential Improvements"
          rows={4}
          value={property.potentialImprovements}
          className="col-span-2"
          onChange={(value) =>
            dispatch(updateField({ field: "potentialImprovements", value }))
          }
        />
      </FormPanel>
      {property.renovations?.map((renovation) => (
        <RenovationField
          renovation={renovation}
          errors={errors?.renovations && errors?.renovations[renovation.id]}
          key={`renovation-${renovation.id}`}
          onCopy={() => dispatch(copyRenovation(renovation))}
          onDelete={() => dispatch(deleteRenovation(renovation.id))}
        />
      ))}
      <button
        type="button"
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => dispatch(addRenovation())}
      >
        <PlusIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900">
          Add new renovation
        </span>
      </button>
    </>
  )
}
