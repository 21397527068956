import { ReactElement } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/property-details"
import { Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"

type Props = {
  property: Property
}

export const DescriptionTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()

  const { errors, neighbourhoodOptions } = useAppSelector(
    (state) => state.propertyDetails
  )

  const neighbourhoodGuide = neighbourhoodOptions.find(
    (n) => n.id === property.neighbourhoodId
  )?.guide

  const handleCopyGuide = () => {
    dispatch(updateField({ field: "location", value: neighbourhoodGuide }))
  }

  return (
    <FormPanel>
      <TextField
        id="owner_highlights"
        title="Owner Highlights"
        rows={8}
        value={property.ownerHighlights}
        className="col-span-2"
        onChange={(value) =>
          dispatch(updateField({ field: "ownerHighlights", value }))
        }
      />
      <TextField
        id="notes"
        title="Property Notes"
        rows={8}
        value={property.notes}
        className="col-span-2"
        onChange={(value) => dispatch(updateField({ field: "notes", value }))}
      />
      <TextField
        id="description"
        title="Description"
        rows={8}
        value={property.description}
        error={errors?.description}
        className="col-span-2"
        onChange={(value) =>
          dispatch(updateField({ field: "description", value }))
        }
      />
      <TextField
        id="location"
        title="Location"
        rows={8}
        value={property.location}
        error={errors?.location}
        className="col-span-2"
        onChange={(value) =>
          dispatch(updateField({ field: "location", value }))
        }
        extraActions={
          neighbourhoodGuide
            ? [
                {
                  key: "copy-from-neighhourbood",
                  label: "Copy from neighbourhood",
                },
              ]
            : undefined
        }
        onExtraAction={handleCopyGuide}
      />
    </FormPanel>
  )
}
