import React from "react"
import { WaitlistedBuyer } from "../../../types/waitlisted-buyers"

type Props = {
  waitlistedBuyer: WaitlistedBuyer
  style: "large" | "small"
}

const getLabel = (
  waitlistedBuyer: WaitlistedBuyer,
  style: "large" | "small"
) => {
  if (waitlistedBuyer.status === "DE") {
    return {
      label: "Deleted",
      className: "bg-gray-100 text-gray-800",
    }
  }

  if (waitlistedBuyer.approved === true) {
    return {
      label: "Approved",
      className: "bg-green-100 text-green-800",
    }
  }

  if (waitlistedBuyer.approved === false) {
    return {
      label: style === "large" ? "Rejected by Ostrich" : "Rejected",
      className: "bg-red-100 text-red-800",
    }
  }

  return {
    label: "Review Required",
    className: "bg-yellow-100 text-yellow-800",
  }
}

export const WaitlistedBuyerStatusLabel: React.FC<Props> = ({
  waitlistedBuyer,
  style,
}) => {
  return (
    <>
      <span
        className={`inline-flex items-center rounded-full text-xs font-medium ${
          style === "large" ? "px-2.5 py-1" : "px-2.5 py-0.5"
        } ${getLabel(waitlistedBuyer, style).className}`}
      >
        {getLabel(waitlistedBuyer, style).label}
      </span>
    </>
  )
}
