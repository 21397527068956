import { ViewingRequestListItem } from "../ostrich/rpc/users/viewing_requests/v2_pb"
import { Offer } from "./offer"
import { User } from "./user"
import { ViewingListItem } from "./viewings"
import { WaitlistedBuyer } from "./waitlisted-buyers"

export type Property = {
  id: string

  status: PropertyStatus
  saleTimeline: string
  saleTimelineNotes?: string
  featured: boolean

  bookingId?: string

  address?: Address
  neighbourhoodId?: string
  secondaryNeighbourhoodIds?: string[]
  displayAddress?: string
  friendlyAddress?: string
  intro?: string
  slug?: string
  token?: string

  salePrice?: number
  guidePrice?: number
  autoRejectLevel?: number

  lrIds?: PropertyLandRegistryIds

  attributes: PropertyAttributes
  aml?: PropertyAML

  description?: string
  location?: string

  councilTax: string
  councilTaxBand?: string
  localAuthority?: string

  potentialImprovements?: string

  renovations?: Renovation[]
  transportLinks?: TransportLink[]

  floorplans?: PropertyImage[]
  photos?: PropertyImage[]

  internalNotes?: string
  internalViewingNotes?: string
  notes?: string
  ownerHighlights?: string
  hideReason?: string
  hideReasonExtra?: string

  users?: User[]
  offers?: ViewingRequestListItem[]
  waitlistedBuyers?: WaitlistedBuyer[]
  viewings?: ViewingListItem[]

  dateCreated?: string
  dateUpdated?: string

  [field: string]: any
}

export type PropertyOverview = {
  id: string

  bookingId?: string

  address?: Address
  displayAddress: string
  intro: string
  slug: string

  status: string
  featured: boolean

  salePrice?: number
  guidePrice?: number

  attributes: PropertyAttributes

  previewPhoto: PropertyImage
  photoCount: number

  users?: User[]
}

export enum PropertyStatus {
  Live = "L",
  Hidden = "H",
  Deleted = "D",
}

export enum PropertySaleTimeline {
  Within12Months = "0-12",
  Over12Months = "12+",
}

export type PropertyAttributes = {
  interiorSize?: number
  outdoorSpace?: string
  bedrooms?: number
  bathrooms?: number
  tenure?: string
  buildingType?: string
  internalType?: string
  parking?: string
  epcUrl?: string
  epcCurrent?: number
  epcPotential?: number
  epcExpiryDate?: string
  leaseLength?: string
  groundRent?: string
  serviceCharge?: string
  floors?: string
  internalOutdoorSpace?: string
  filterFreehold?: boolean
  filterOutdoorSpace?: boolean
  filterParking?: boolean
  listedStatus?: string
  utilitiesElectric?: string
  utilitiesWater?: string
  utilitiesBroadband?: string
  utilitiesHeating?: string
}

export type PropertyAML = {
  address?: boolean
  contract?: boolean
  deeds?: boolean
  ids?: boolean
}

export type PropertyLandRegistryIds = {
  propertyId?: string
  sales: NearbySale[]
}

export type Renovation = {
  id: string
  year?: number
  works?: string
  architect?: string
  cost?: number
  contractor?: string

  [field: string]: any
}

export type TransportLink = {
  id?: string
  name?: string
  types?: string[]
  travelTime?: number
  distance?: string
  googleMapsUrl?: string

  [field: string]: any
}

export type Address = {
  id: string
  buildingUnit: string
  buildingName: string
  streetNumber: string
  streetName: string
  line1?: string
  line2?: string
  city: string
  postcode: string
}

export type Neighbourhood = {
  id: string
  name: string
  guide?: string
  status?: NeighbourhoodStatus
}

export enum NeighbourhoodStatus {
  Live = "LI",
  FiltersOnly = "FO",
  SearchPreferencesOnly = "SO",
  Hidden = "HI",
}

export type PropertyImage = {
  id?: string
  filename?: string
  label?: string
  featured?: boolean
  preview?: boolean
  previewAlt?: boolean
  tags?: PropertyTag[]

  [field: string]: any
}

export type PropertyTag = {
  id: string
  content: string
}

export type NearbySale = {
  id?: string
  lrSaleId?: string
  description?: string

  [field: string]: any
}
