import { ReactElement } from "react"
import { useAppDispatch } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/property-details"
import { NearbySale, Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"
import { NearbySaleField } from "../fields/nearby-sale-field"

type Props = {
  property: Property
}

export const SalesTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()

  const lrIds = property.lrIds

  const updatePropertyId = (value: string | undefined) => {
    dispatch(
      updateField({
        field: "lrIds",
        value: lrIds
          ? { ...lrIds, propertyId: value }
          : { propertyId: value, sales: [] },
      })
    )
  }

  const updateSale = (index: number) => {
    return (sale: NearbySale) => {
      let sales = [...(lrIds?.sales ?? [])]
      sales[index] = sale
      sales = sales.filter((s) => s.lrSaleId?.length ?? 0 > 0)
      dispatch(updateField({ field: "lrIds", value: { ...lrIds, sales } }))
    }
  }

  return (
    <FormPanel>
      <TextField
        id="property_id"
        title="Property ID"
        value={lrIds?.propertyId ?? ""}
        className="col-span-2"
        onChange={updatePropertyId}
      />
    </FormPanel>
  )
}
