import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Offer } from "../../types/offer"
import { getEndpoint } from "../../util/api"

type State = {
  offer?: Offer
}

const initialState: State = {}

export const fetchOffer = createAsyncThunk(
  "offerDetails/fetchOffer",
  async (id: string, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/offers/${id}/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as { offer: Offer }

    return json.offer
  }
)

export const reviewOffer = createAsyncThunk(
  "offerDetails/reviewOffer",
  async (
    data: { id: string; approved: boolean; messageId?: string },
    thunkAPI
  ) => {
    const response = await fetch(`${getEndpoint()}/offers/${data.id}/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        approved: data.approved,
        messageId: data.messageId,
      }),
    })

    const json = (await response.json()) as { offer: Offer }

    return json.offer
  }
)

export const deleteOffer = createAsyncThunk(
  "offerDetails/deleteOffer",
  async (id: string, thunkAPI) => {
    await fetch(`${getEndpoint()}/offers/${id}/`, {
      method: "DELETE",
      cache: "no-cache",
      credentials: "include",
    })
  }
)

export const changeOfferStatus = createAsyncThunk(
  "offerDetails/changeOfferStatus",
  async (data: { id: string; status: string }, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/offers/${data.id}/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: data.status,
      }),
    })

    const json = (await response.json()) as { offer: Offer }

    return json.offer
  }
)

export const editOfferFlag = createAsyncThunk(
  "offerDetails/editOfferFlag",
  async (data: { id: string; key: string; value: boolean }, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/offers/${data.id}/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        [data.key]: data.value,
      }),
    })

    const json = (await response.json()) as { offer: Offer }

    return json.offer
  }
)

export const editOfferMessage = createAsyncThunk(
  "offerDetails/editOfferMessage",
  async (
    data: { id: string; content: string; messageId?: string },
    thunkAPI
  ) => {
    const response = await fetch(`${getEndpoint()}/offers/${data.id}/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messageId: data.messageId,
        content: data.content,
      }),
    })

    const json = (await response.json()) as { offer: Offer }

    return json.offer
  }
)

export const editOfferNotes = createAsyncThunk(
  "offerDetails/editOfferNotes",
  async (data: { id: string; note?: string; bioBuyer?: string }, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/offers/${data.id}/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        note: data.note,
        bioBuyer: data.bioBuyer,
      }),
    })

    const json = (await response.json()) as { offer: Offer }

    return json.offer
  }
)

export const offerDetailsSlice = createSlice({
  name: "offerDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffer.pending, (state, action) => {
        if (state.offer && state.offer.id !== action.meta.arg) {
          delete state.offer
        }
      })
      .addCase(fetchOffer.fulfilled, (state, action) => {
        state.offer = action.payload
      })
      .addCase(reviewOffer.fulfilled, (state, action) => {
        state.offer = action.payload
      })
      .addCase(deleteOffer.fulfilled, (state, action) => {
        if (!state.offer) return

        state.offer.status = "DE"
      })
      .addCase(changeOfferStatus.fulfilled, (state, action) => {
        state.offer = action.payload
      })
      .addCase(editOfferMessage.fulfilled, (state, action) => {
        state.offer = action.payload
      })
      .addCase(editOfferNotes.fulfilled, (state, action) => {
        state.offer = action.payload
      })
      .addCase(editOfferFlag.fulfilled, (state, action) => {
        state.offer = action.payload
      })
  },
})

export const offerDetailsReducer = offerDetailsSlice.reducer
