import { Property } from "../types/property"
import { getEndpoint } from "../util/api"

export const getProperty = async (propertyId: string): Promise<Property> => {
  const response = await fetch(`${getEndpoint()}/properties/${propertyId}/`, {
    cache: "no-cache",
    credentials: "include",
  })

  const json = (await response.json()) as {
    property?: Property
  }

  if (!json.property) {
    return Promise.reject()
  }

  return Promise.resolve(json.property)
}
