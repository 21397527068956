import {
  InformationCircleIcon,
  DocumentTextIcon,
  ChatIcon,
  CurrencyPoundIcon,
} from "@heroicons/react/outline"
import { ExclamationCircleIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { useAppSelector } from "../../redux/hooks"
import { Viewing } from "../../types/viewings"
import { classNames } from "../../util/misc"
import { ViewingDetailsTab } from "./tabs/details-tab"
import { ViewingNotesTab } from "./tabs/notes-tab"
import { ViewingFeedbackTab } from "./tabs/feedback-tab"
import { Property } from "../../types/property"
import { User } from "../../types/user"
import { Offer } from "../../types/offer"
import { OffersTab } from "./tabs/offers-tab"
import { ViewingRequestListItem } from "../../ostrich/rpc/users/viewing_requests/v2_pb"

const navigation = [
  {
    id: "details",
    name: "Viewing Details",
    icon: InformationCircleIcon,
  },
  { id: "notes", name: "Notes", icon: DocumentTextIcon },
  {
    id: "feedback",
    name: "Feedback",
    icon: ChatIcon,
  },
  {
    id: "offers",
    name: "Offers",
    icon: CurrencyPoundIcon,
  },
]

type Props = {
  viewing: Viewing
  property?: Property
  buyer?: User
  offers?: ViewingRequestListItem[]
  seller?: User
  newViewing?: boolean
  currentTab?: string
  onSubmit: () => void
}

export const ViewingForm: React.FC<Props> = ({
  viewing,
  property,
  buyer,
  offers,
  seller,
  newViewing,
  currentTab,
  onSubmit,
}): ReactElement => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit()
  }

  let { sectionErrors } = useAppSelector((state) => state.viewingDetails)

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0 2xl:col-span-2">
        <nav className="space-y-1">
          {navigation.map((item) => {
            let errorIcon: ReactElement | undefined

            if (sectionErrors && sectionErrors[item.id]) {
              errorIcon = (
                <ExclamationCircleIcon className="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-red-300" />
              )
            }

            return (
              <Link
                to={
                  `/viewings/${newViewing ? "new" : viewing.id}/` +
                  (item.id !== "details" ? `${item.id}/` : "")
                }
                key={item.id}
                className={classNames(
                  item.id === currentTab
                    ? "bg-gray-50 text-indigo-700 hover:bg-white hover:text-indigo-700"
                    : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                  "group flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
                )}
                aria-current={item.id === currentTab ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.id === currentTab
                      ? "text-indigo-500 group-hover:text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-1 mr-3 h-6 w-6 flex-shrink-0"
                  )}
                  aria-hidden="true"
                />
                <span className="flex-grow truncate">{item.name}</span>
                {errorIcon}
              </Link>
            )
          })}
        </nav>
      </aside>
      <form
        className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 2xl:col-span-10"
        onSubmit={handleSubmit}
      >
        {currentTab === "details" && (
          <ViewingDetailsTab
            viewing={viewing}
            property={property}
            buyer={buyer}
            seller={seller}
          />
        )}
        {currentTab === "notes" && <ViewingNotesTab viewing={viewing} />}
        {currentTab === "feedback" && <ViewingFeedbackTab viewing={viewing} />}
        {currentTab === "offers" && <OffersTab offers={offers} />}
      </form>
    </div>
  )
}
