import "./index.css"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/react"

import { App } from "./app"
import { store } from "./redux/store"

const getOstrichEnv = () => {
  if (process.env.REACT_APP_BRANCH === "main") {
    return "prd"
  }

  if (process.env.REACT_APP_BRANCH === "dev") {
    return "dev"
  }
}

if (getOstrichEnv()) {
  Sentry.init({
    dsn: "https://c78d84d1df184f1a8bc1e00dd3b2701b@o1169140.ingest.sentry.io/6261680",
    sampleRate: 1.0,
    environment: getOstrichEnv(),
    release: process.env.REACT_APP_GIT_HASH,
    tracesSampleRate: 0.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
