import { Fragment, ReactElement, ReactNode, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ExclamationIcon } from "@heroicons/react/outline"
import { InfoBanner, InfoBannerStyle } from "../info-banner"

type Props = {
  title: string
  message: ReactNode
  confirmLabel?: string
  cancelLabel?: string
  infoBanner?: ReactNode
  notificationText?: ReactNode
  reversible?: boolean
  isOpen: boolean
  onConfirm: (sendNotification: boolean) => void
  onClose: () => void
}

export const ConfirmModal: React.FC<Props> = ({
  title,
  message,
  confirmLabel,
  cancelLabel,
  infoBanner,
  notificationText,
  reversible,
  isOpen,
  onConfirm,
  onClose,
}): ReactElement => {
  const cancelButtonRef = useRef(null)

  const [sendNotification, setSendNotification] = useState(false)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {infoBanner && (
                  <InfoBanner style={InfoBannerStyle.Info} className="mb-5">
                    {infoBanner}
                  </InfoBanner>
                )}
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">
                        {message}
                        {!reversible && (
                          <>
                            <br />
                            <strong className="font-medium">
                              This is irreversible.
                            </strong>
                          </>
                        )}
                        {notificationText && (
                          <>
                            <br />
                            <div className="flex h-[38px] cursor-pointer items-center gap-2">
                              <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                checked={sendNotification}
                                id="send-notification"
                                onChange={(e) =>
                                  setSendNotification(e.target.checked)
                                }
                              />
                              <label htmlFor="send-notification">
                                {notificationText}
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="focus:ring-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onConfirm(sendNotification)}
                  >
                    {confirmLabel ?? "Yes, Cancel"}
                  </button>
                  <button
                    type="button"
                    className="focus:ring--none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => onClose()}
                    ref={cancelButtonRef}
                  >
                    {cancelLabel ?? "No"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
