import { Fragment } from "react"
import { tagDefaultOptions } from "./tags-field"
import { Combobox, Transition } from "@headlessui/react"

interface TagInputProps {
  value: string
  onChange?: (content: string) => void
  onBlur?: () => void
}

export const TagInput: React.FC<TagInputProps> = ({
  value,
  onChange,
  onBlur,
}) => {
  const filteredOptions =
    value !== ""
      ? tagDefaultOptions.filter((option) =>
          option
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(value.toLowerCase().replace(/\s+/g, ""))
        )
      : tagDefaultOptions

  return (
    <Combobox
      value={value}
      onChange={(value) => {
        if (onChange) {
          onChange(value)
        }
      }}
    >
      <div className="w-full">
        <div className="cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-1 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            onChange={(event) => {
              if (onChange) {
                onChange(event.target.value)
              }
            }}
            onBlur={onBlur}
          />
        </div>
        {filteredOptions.length > 0 && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {}}
          >
            <Combobox.Options className="absolute z-[1000] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredOptions.length > 0 &&
                filteredOptions.map((option, i) => (
                  <Combobox.Option
                    key={`option-${i}`}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    <span className="block truncate">{option}</span>
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </Transition>
        )}
      </div>
    </Combobox>
  )
}
