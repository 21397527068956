import React from "react"
import { PropertyImage, PropertyTag } from "../../../types/property"
import { getImageUrl } from "../../../util/api"
import { TagsField } from "./tags-field"
import { useSortable } from "@dnd-kit/sortable"
import { TrashIcon } from "@heroicons/react/outline"
import { v4 as uuidv4 } from "uuid"

interface ImagesTableRowProps {
  image: PropertyImage
  type: "photos" | "floorplans"
  index: number
  selected?: boolean
  previousTags?: PropertyTag[]
  onChange?: (
    field: string,
    value?: string | boolean | PropertyTag[] | undefined
  ) => void
  onDelete?: () => void
}

export const ImagesTableRow: React.FC<ImagesTableRowProps> = ({
  image,
  type,
  index,
  selected,
  previousTags,
  onChange,
  onDelete,
}) => {
  const { attributes, listeners, isDragging } = useSortable({
    id: image.id ?? "",
  })

  return (
    <div
      className={`-mt-px flex border-y border-gray-200 group-first/draggable:mt-0 ${
        selected ? "bg-blue-50" : "bg-white"
      } ${isDragging && onChange ? "opacity-0" : "opacity-100"}`}
    >
      <div
        className="group group flex flex-grow-0 items-center px-1 py-1 hover:bg-gray-300"
        {...attributes}
        {...listeners}
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-gray-300 group-hover:fill-white"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 8C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5C8.67157 5 8 5.67157 8 6.5C8 7.32843 8.67157 8 9.5 8ZM9.5 14C10.3284 14 11 13.3284 11 12.5C11 11.6716 10.3284 11 9.5 11C8.67157 11 8 11.6716 8 12.5C8 13.3284 8.67157 14 9.5 14ZM11 18.5C11 19.3284 10.3284 20 9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17C10.3284 17 11 17.6716 11 18.5ZM15.5 8C16.3284 8 17 7.32843 17 6.5C17 5.67157 16.3284 5 15.5 5C14.6716 5 14 5.67157 14 6.5C14 7.32843 14.6716 8 15.5 8ZM17 12.5C17 13.3284 16.3284 14 15.5 14C14.6716 14 14 13.3284 14 12.5C14 11.6716 14.6716 11 15.5 11C16.3284 11 17 11.6716 17 12.5ZM15.5 20C16.3284 20 17 19.3284 17 18.5C17 17.6716 16.3284 17 15.5 17C14.6716 17 14 17.6716 14 18.5C14 19.3284 14.6716 20 15.5 20Z"
          />
        </svg>
      </div>
      <div className="whitespace-nowrap px-3 py-4 align-top text-sm text-gray-500">
        {image.filename && (
          <div className="w-[180px] overflow-clip rounded-sm border border-gray-300">
            <img
              src={getImageUrl(
                image.filename,
                type === "photos" ? "admin" : ""
              )}
              alt=""
            />
          </div>
        )}
      </div>
      <div className="basis-1/2 gap-y-2 px-3 py-4 align-top text-sm text-gray-900">
        <div className="flex items-center rounded-sm border border-gray-300">
          <span className="border-r border-gray-300 px-2 py-1 text-gray-500">
            {index + 1}
          </span>
          <input
            type="text"
            value={image.label}
            placeholder="Label"
            onChange={(e) => {
              if (onChange) onChange("label", e.target.value)
            }}
            className="w-full rounded-sm border-0 border-gray-300 px-2 py-1 text-sm outline-0 ring-0 placeholder:text-gray-400 focus:border-0 focus:border-gray-300 focus:ring-0"
          />
        </div>
        {type === "photos" && (
          <div className="mt-2 flex h-[30px] justify-between gap-x-4 divide-x rounded-sm border border-gray-300 text-gray-900">
            <span className="inline-flex items-center gap-x-2 px-2">
              <input
                type="checkbox"
                id={`featured-${image.id}`}
                checked={image.featured ?? false}
                className="h-4 w-4 rounded border-gray-300 focus:ring-0"
                onChange={(e) => {
                  if (onChange) onChange("featured", e.target.checked)
                }}
              />
              <label
                htmlFor={`featured-${image.id}`}
                className="w-full bg-white text-xs"
              >
                Featured
              </label>
            </span>
            <span className="inline-flex items-center gap-x-2 px-2">
              <input
                type="checkbox"
                id={`preview-${image.id}`}
                checked={image.preview ?? false}
                className="h-4 w-4 rounded border-gray-300 focus:ring-0"
                onChange={(e) => {
                  if (onChange) onChange("preview", e.target.checked)
                }}
              />
              <label
                htmlFor={`preview-${image.id}`}
                className="w-full bg-white text-xs"
              >
                Preview
              </label>
            </span>
            <span className="inline-flex items-center gap-x-2 px-2">
              <input
                type="checkbox"
                id={`preview-alt-${image.id}`}
                checked={image.previewAlt ?? false}
                className="h-4 w-4 rounded border-gray-300 focus:ring-0"
                onChange={(e) => {
                  if (onChange) onChange("previewAlt", e.target.checked)
                }}
              />
              <label
                htmlFor={`preview-alt-${image.id}`}
                className="w-full bg-white text-xs"
              >
                Preview <span className="font-normal text-gray-500">(Alt)</span>
              </label>
            </span>
          </div>
        )}
        <button
          type="button"
          onClick={onDelete}
          className="mt-2 flex gap-x-2 rounded-sm border border-gray-300 px-2 py-1 text-xs text-red-700 hover:bg-red-50"
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
      {type === "photos" ? (
        <div className="flex basis-1/2 flex-col gap-y-2 px-3 py-4 text-sm font-normal text-gray-500">
          <TagsField
            tags={image.tags}
            onChange={(value) => {
              if (onChange) onChange("tags", value)
            }}
          />
          {index > 0 && previousTags && previousTags.length > 0 && (
            <button
              type="button"
              className="rounded-none border border-transparent bg-blue-400 px-4 py-1 text-center text-sm font-medium text-white shadow-sm hover:bg-blue-500"
              onClick={() => {
                if (onChange) {
                  onChange(
                    "tags",
                    previousTags.map((tag) => ({
                      ...tag,
                      id: uuidv4(),
                    }))
                  )
                }
              }}
            >
              Copy tags
            </button>
          )}
        </div>
      ) : (
        <div className="basis-1/2" />
      )}
    </div>
  )
}
