import { DuplicateIcon, TrashIcon } from "@heroicons/react/outline"
import { ReactElement } from "react"
import { useAppDispatch } from "../../../redux/hooks"
import { updateRenovationField } from "../../../redux/slices/property-details"
import { Renovation } from "../../../types/property"
import { RenovationErrors } from "../../../util/validation"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"

type Props = {
  renovation?: Renovation
  errors?: RenovationErrors
  onCopy?: () => void
  onDelete?: () => void
}

export const RenovationField: React.FC<Props> = ({
  renovation,
  errors,
  onCopy,
  onDelete,
}): ReactElement => {
  const dispatch = useAppDispatch()

  let actionButtons = (
    <>
      <button
        className="relative inline-flex items-center gap-1 rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        onClick={onCopy}
      >
        <DuplicateIcon className="w-5" />
      </button>
      <button
        className="relative -ml-px inline-flex items-center justify-center gap-1 rounded-r-md border border-gray-300 bg-white px-4 py-2 font-medium text-red-700 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-red-500 sm:text-sm"
        onClick={onDelete}
      >
        <TrashIcon className="w-5" />
      </button>
    </>
  )

  return (
    <FormPanel actionButtons={actionButtons}>
      <TextField
        id="year"
        title="Year"
        value={renovation?.year ? String(renovation?.year) : ""}
        error={errors?.year}
        onChange={(value) =>
          dispatch(
            updateRenovationField({
              id: renovation?.id ?? "",
              field: "year",
              value: isNaN(parseInt(value ?? "")) ? 0 : parseInt(value ?? ""),
            })
          )
        }
      />
      <TextField
        id="cost"
        title="Cost"
        value={renovation?.cost ? String(renovation.cost) : ""}
        onChange={(value) =>
          dispatch(
            updateRenovationField({
              id: renovation?.id ?? "",
              field: "cost",
              value: isNaN(parseInt(value ?? "")) ? 0 : parseInt(value ?? ""),
            })
          )
        }
      />
      <TextField
        id="architect"
        title="Architect"
        value={renovation?.architect ?? ""}
        onChange={(value) =>
          dispatch(
            updateRenovationField({
              id: renovation?.id ?? "",
              field: "architect",
              value: value,
            })
          )
        }
      />
      <TextField
        id="contractor"
        title="Contractor"
        value={renovation?.contractor ?? ""}
        onChange={(value) =>
          dispatch(
            updateRenovationField({
              id: renovation?.id ?? "",
              field: "contractor",
              value: value,
            })
          )
        }
      />
      <TextField
        id="works"
        title="Works"
        rows={4}
        className="col-span-2"
        value={renovation?.works ?? ""}
        error={errors?.works}
        onChange={(value) =>
          dispatch(
            updateRenovationField({
              id: renovation?.id ?? "",
              field: "works",
              value: value,
            })
          )
        }
      />
    </FormPanel>
  )
}
