import { ExclamationIcon, PencilIcon } from "@heroicons/react/outline"
import { ReactElement, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  changeOfferStatus,
  deleteOffer,
  editOfferFlag,
  editOfferMessage,
  editOfferNotes,
  fetchOffer,
  reviewOffer,
} from "../../redux/slices/offer-details"
import { Offer, OfferMessage } from "../../types/offer"
import { formatFundingPosition } from "../../util/offers"
import { Container } from "../global/container"
import { FormPanel } from "../global/forms/form-panel"
import { Header } from "../global/header"
import { MessagesFeed } from "./messages-feed"
import { ReviewModal } from "./review-modal"
import { StatusModal } from "./status-modal"
import { OfferStatusLabel } from "../offer-list/status-label"
import { AdditionalOffersTable } from "./additional-offers"
import { FieldTitle } from "../global/forms/field-title"
import { EditMessageModal } from "./edit-message-modal"
import { ConfirmModal } from "../global/modals/confirm-modal"
import { EditNotesModal } from "../global/modals/edit-notes-modal"
import { BooleanField } from "../global/forms/boolean-field"
import { formatVerifiedFlag } from "../../util/users"

export const OfferDetails: React.FC = (): ReactElement | null => {
  let { id } = useParams()

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editNotesModalOpen, setEditNotesModalOpen] = useState(false)
  const [reviewModalOpen, setReviewModalOpen] = useState(false)
  const [statusModalOpen, setStatusModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [editMessage, setEditMessage] = useState<OfferMessage | undefined>()
  const [reviewApprove, setReviewApprove] = useState(false)
  const [reviewMessage, setReviewMessage] = useState<OfferMessage | undefined>()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!id) return

    dispatch(fetchOffer(id))

    setReviewModalOpen(false)
    setStatusModalOpen(false)

    setReviewApprove(false)
    setReviewMessage(undefined)
  }, [dispatch, id])

  let { offer } = useAppSelector((state) => state.offerDetails)

  if (!offer) return null

  const handleReview = (approved: boolean, messageId?: string) => {
    if (!id) return

    dispatch(
      reviewOffer({ id, approved, messageId: messageId ?? reviewMessage?.id })
    )
  }

  const handleChangeStatus = (status: string) => {
    if (!id) return

    dispatch(changeOfferStatus({ id, status }))
  }

  const handleEditMessage = (content: string) => {
    if (!id) return

    dispatch(editOfferMessage({ id, content, messageId: editMessage?.id }))
  }

  const handleEditNotes = (value: { note?: string; bioBuyer?: string }) => {
    if (!id) return

    dispatch(editOfferNotes({ id, ...value }))
  }

  const handleDelete = () => {
    if (!id) return

    dispatch(deleteOffer(id))
  }

  const buyingAgent = Boolean(offer.companyName)

  return (
    <>
      <Header
        title={`Offer for ${offer.property.displayAddress}`}
        breadcrumbs={[
          { label: "Offers", url: "/offers/" },
          { label: "View Offer" },
        ]}
        primaryButton={{
          label: "Create Viewing",
          url: `/viewings/new/?offerId=${offer.id}`,
        }}
        secondaryButton={
          offer.status !== "DE"
            ? {
                label: "Delete Offer",
                onClick: () => setDeleteModalOpen(true),
              }
            : undefined
        }
        dateCreated={offer.dateCreated}
        dateUpdated={offer.dateUpdated}
      />
      <Container>
        <div className="mb-5 flex flex-col gap-y-5 xl:grid xl:grid-cols-12 xl:gap-x-5">
          <div className="col-span-4 row-span-2">
            <FormPanel title="Offer" className="grid grid-cols-3" expandHeight>
              <div className="col-span-3 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Price
                </label>
                <div className="mt-1">
                  {buyingAgent
                    ? "Buying Agent"
                    : `£${offer.offer?.toLocaleString()}`}
                </div>
              </div>
              <div className="col-span-3 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Funding Position
                </label>
                <div className="mt-1">
                  {formatFundingPosition(offer.fundingPosition)}
                </div>
              </div>
              <div className="col-span-3 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Home to Sell First?
                </label>
                <div className="mt-1">{offer.homeSeller ? "Yes" : "No"}</div>
              </div>
              <div className="col-span-3 mt-6 border-t pt-6">
                <label className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <div className="mt-1 flex items-center">
                  <OfferStatusLabel offer={offer} style="large" />
                  {offer.dateReviewed &&
                    offer.approved &&
                    offer.status &&
                    !offer.pendingStatus &&
                    !offer.pendingMessageReview && (
                      <button
                        type="button"
                        className="ml-2 flex rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        disabled={statusModalOpen}
                        onClick={() => setStatusModalOpen(true)}
                      >
                        Change
                        <PencilIcon className="ml-1 w-4 text-gray-700" />
                      </button>
                    )}
                </div>
              </div>
              <div className="col-span-3 mt-6 border-t pt-6">
                <label className="block text-sm font-medium text-gray-700">
                  Viewing Status
                </label>
                <div className="mt-1 flex items-center space-x-4">
                  <BooleanField
                    label="Buyer Contacted"
                    checked={offer.buyerContacted ?? false}
                    onChange={(checked) => {
                      if (offer) {
                        dispatch(
                          editOfferFlag({
                            id: offer.id,
                            key: "buyerContacted",
                            value: checked,
                          })
                        )
                      }
                    }}
                  />
                  <BooleanField
                    label="Viewing Arranged"
                    checked={offer.viewingArranged ?? false}
                    onChange={(checked) => {
                      if (offer) {
                        dispatch(
                          editOfferFlag({
                            id: offer.id,
                            key: "viewingArranged",
                            value: checked,
                          })
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </FormPanel>
          </div>
          <div className="col-span-4 row-span-2">
            <FormPanel title="Property" expandHeight>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Display Address
                </label>
                <div className="mt-1">
                  <Link
                    to={`/properties/${offer.property.id}/`}
                    className="font-medium text-blue-600"
                  >
                    {offer.property.displayAddress}
                  </Link>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Price Guide
                </label>
                <div className="mt-1">
                  £{offer.property.guidePrice?.toLocaleString()}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Building Type
                </label>
                <div className="mt-1">
                  {offer.property.attributes.buildingType}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Interior Size
                </label>
                <div className="mt-1">
                  {offer.property.attributes.interiorSize?.toLocaleString()} sq
                  ft
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Bedrooms
                </label>
                <div className="mt-1">{offer.property.attributes.bedrooms}</div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Bathrooms
                </label>
                <div className="mt-1">
                  {offer.property.attributes.bathrooms}
                </div>
              </div>
            </FormPanel>
          </div>
          <div className="col-span-4">
            <FormPanel
              title={buyingAgent ? "Buying Agent" : "Buyer"}
              className="grid grid-cols-2 gap-6"
            >
              <div className="col-span-2 md:col-span-1">
                <FieldTitle
                  title="Name"
                  extraActions={[
                    {
                      key: "view-user",
                      label: "View full details",
                      href: `/users/${offer.user.id}/`,
                      external: true,
                    },
                  ]}
                />
                <div className="mt-1">
                  <Link to={`/users/${offer.user.id}/`} target="_blank">
                    {offer.user.firstName} {offer.user.lastName}
                  </Link>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  E-mail
                </label>
                <div className="mt-1 break-all">{offer.user.email}</div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Phone
                </label>
                <div className="mt-1">{offer.phoneNumber}</div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Identity Verified?
                </label>
                <div className="mt-1">
                  {formatVerifiedFlag(offer.user.verified.stripe)}
                </div>
              </div>
              {buyingAgent && (
                <>
                  <div className="col-span-2 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Company Name
                    </label>
                    <div className="mt-1">{offer.companyName}</div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Company Website
                    </label>
                    <div className="mt-1">{offer.companyWebsite}</div>
                  </div>
                </>
              )}
            </FormPanel>
          </div>
          <div className="col-span-4">
            <FormPanel title="Seller" className="grid grid-cols-3" expandHeight>
              {offer.sellers ? (
                <>
                  <div className="col-span-3 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <div className="mt-1">
                      {offer.sellers.map((seller, i) => (
                        <p key={`seller-${i}`}>
                          {seller.firstName} {seller.lastName}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      E-mail
                    </label>
                    <div className="mt-1">
                      {offer.sellers.map((seller, i) => (
                        <p key={`seller-${i}`}>{seller.email}</p>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-span-3 border-l-4 border-red-400 bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        <strong className="font-medium">
                          This property does not have an assigned seller.
                        </strong>
                        <br />
                        You won't be able to approve this offer until a user is
                        added.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </FormPanel>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-5">
          <div
            className={
              offer.additionalOffers
                ? "lg:w-1/2 lg:flex-shrink-0"
                : "lg:flex-grow"
            }
          >
            <FormPanel title="Activity">
              <MessagesFeed
                offer={offer}
                messages={offer.messages}
                onEdit={(message) => {
                  setEditMessage(message)

                  if (message) {
                    setEditModalOpen(true)
                  } else {
                    setEditNotesModalOpen(true)
                  }
                }}
                onReview={(approved, message) => {
                  setReviewApprove(approved)
                  setReviewMessage(message)
                  setReviewModalOpen(true)
                }}
              />
            </FormPanel>
          </div>
          {offer.additionalOffers && (
            <div className="lg:w-1/2 lg:flex-shrink-0">
              <FormPanel title="Other Offers">
                <AdditionalOffersTable offers={offer.additionalOffers} />
              </FormPanel>
            </div>
          )}
        </div>
      </Container>
      {offer.sellers && (
        <ReviewModal
          isOpen={reviewModalOpen}
          offer={offer}
          message={reviewMessage}
          approve={reviewApprove}
          onConfirm={(approved) => {
            handleReview(approved)
            setReviewModalOpen(false)
          }}
          onCancel={() => {
            setReviewApprove(false)
            setReviewMessage(undefined)
            setReviewModalOpen(false)
          }}
        />
      )}
      {offer && (
        <>
          <EditMessageModal
            isOpen={editModalOpen}
            initialValue={editMessage?.content ?? offer.note}
            onConfirm={(value) => {
              handleEditMessage(value)
              setEditModalOpen(false)
            }}
            onCancel={() => {
              setEditModalOpen(false)
              setEditMessage(undefined)
            }}
          />
          <EditNotesModal
            isOpen={editNotesModalOpen}
            initialValue={{ note: offer.note, bioBuyer: offer.bioBuyer }}
            buyingAgent={buyingAgent}
            onConfirm={(value) => {
              handleEditNotes(value)
              setEditNotesModalOpen(false)
            }}
            onCancel={() => {
              setEditNotesModalOpen(false)
            }}
          />
          <StatusModal
            isOpen={statusModalOpen}
            initialValue={offer.status}
            onConfirm={(value) => {
              handleChangeStatus(value)
              setStatusModalOpen(false)
            }}
            onCancel={() => setStatusModalOpen(false)}
          />
          <ConfirmModal
            isOpen={deleteModalOpen}
            title="Delete Offer"
            message="Are you sure you want to delete this offer?"
            confirmLabel="Yes, Delete"
            onConfirm={() => {
              handleDelete()
              setDeleteModalOpen(false)
            }}
            onClose={() => setDeleteModalOpen(false)}
          />
        </>
      )}
    </>
  )
}
