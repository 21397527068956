import { CalendarIcon, ClockIcon } from "@heroicons/react/outline"
import { ReactElement } from "react"
import { Link } from "react-router-dom"

const tabs = [
  { name: "Bookings", href: "/bookings/", icon: CalendarIcon },
  { name: "Availability", href: "/bookings/availability/", icon: ClockIcon },
]

type Props = {
  currentTab: string
}

export const BookingsTabs: React.FC<Props> = ({ currentTab }): ReactElement => {
  return (
    <div className="border-b border-gray-200 bg-white px-4 sm:px-6 lg:px-8">
      <div>
        <div className="">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={`group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium ${
                  currentTab === tab.name
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`}
              >
                <tab.icon
                  className={`-ml-0.5 mr-2 h-5 w-5 ${
                    currentTab === tab.name
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500"
                  }`}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
