import { PlusIcon } from "@heroicons/react/outline"
import { ReactElement, useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  lookupNearestTransportLinks,
  updateField,
} from "../../../redux/slices/property-details"
import { Property, TransportLink } from "../../../types/property"
import { TransportLinkField } from "../fields/transport-link-field"
import { formatAddress } from "../../../util/addresses"

type Props = {
  property: Property
}

export const TravelTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const { errors } = useAppSelector((state) => state.propertyDetails)

  const handleChange = (transportLink: TransportLink) => {
    let transportLinks = [...(property.transportLinks ?? [])]

    let index = transportLinks.findIndex((tl) => tl.id === transportLink.id)

    transportLinks[index] = transportLink

    dispatch(
      updateField({
        field: "transportLinks",
        value: transportLinks,
      })
    )
  }

  const handleAdd = () => {
    dispatch(
      updateField({
        field: "transportLinks",
        value: [...(property.transportLinks ?? []), { id: uuidv4() }],
      })
    )
  }

  const handleCopy = (transportLink: TransportLink) => {
    let newTransportLink = { ...transportLink, id: uuidv4() }

    dispatch(
      updateField({
        field: "transportLinks",
        value: [...(property.transportLinks ?? []), newTransportLink],
      })
    )
  }

  const handleDelete = (transportLink: TransportLink) => {
    dispatch(
      updateField({
        field: "transportLinks",
        value: (property.transportLinks ?? []).filter(
          (tl) => tl.id !== transportLink.id
        ),
      })
    )
  }

  const fullAddress = useMemo(() => {
    return property.address ? formatAddress(property.address, true) : ""
  }, [property.address])

  return (
    <>
      <div
        onClick={async () => {
          if (!property.address || loading) return

          setLoading(true)
          await dispatch(lookupNearestTransportLinks(fullAddress))
          setLoading(false)
        }}
        className="focus:ring-none inline-flex w-full cursor-pointer justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none sm:w-auto sm:text-sm"
      >
        {loading ? "Searching..." : `Find nearest to ${fullAddress}`}
      </div>
      {property.transportLinks?.map((transportLink) => (
        <TransportLinkField
          transportLink={transportLink}
          errors={
            transportLink.id && errors?.transportLinks
              ? errors?.transportLinks[transportLink.id]
              : undefined
          }
          key={`transport-link-${transportLink.id}`}
          onChange={handleChange}
          onCopy={handleCopy}
          onDelete={handleDelete}
        />
      ))}
      <button
        type="button"
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={handleAdd}
      >
        <PlusIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900">
          Add new transport link
        </span>
      </button>
    </>
  )
}
