import { ReactElement, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Container } from "../../global/container"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"
import { Header } from "../../global/header"
import {
  fetchNeighbourhood,
  newNeighbourhood,
  saveNeighbourhood,
  updateField,
} from "../../../redux/slices/neighbourhood-details"
import { SelectField } from "../../global/forms/select-field"

export const NeighbourhoodDetails: React.FC = (): ReactElement | null => {
  let { id } = useParams()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const state = useAppSelector((state) => state.neighbourhoodDetails)

  useEffect(() => {
    if (id && id != state.neighbourhood?.id) {
      if (id == "new") {
        dispatch(newNeighbourhood())
      } else {
        dispatch(fetchNeighbourhood(id))
      }
    }
  }, [dispatch, id])

  let { neighbourhood, status } = useAppSelector(
    (state) => state.neighbourhoodDetails
  )

  if (!neighbourhood) return null

  const handleSave = async () => {
    try {
      const neighbourhood = await dispatch(saveNeighbourhood()).unwrap()

      if (id === "new" && neighbourhood) {
        navigate(`/neighbourhoods/${neighbourhood.id}/`, { replace: true })
      }
    } catch (err) {}
  }

  return (
    <>
      <Header
        title={neighbourhood.name}
        breadcrumbs={[
          { label: "Neighbourhoods", url: "/neighbourhoods/" },
          { label: "View Neighbourhood" },
        ]}
        primaryButton={{ label: "Save", onClick: handleSave }}
        successMessage={status === "saved" ? "Neighbourhood saved" : undefined}
        errorMessage={status === "error" ? "Save error" : undefined}
      />
      <Container>
        <div className="flex flex-col gap-y-5 lg:grid lg:grid-cols-2 lg:gap-x-5">
          <FormPanel
            title="Details"
            className="grid grid-cols-3 gap-6"
            expandHeight
          >
            <TextField
              title="Name"
              className="col-span-3 md:col-span-1"
              id="name"
              rows={1}
              value={neighbourhood?.name ?? ""}
              onChange={(value) => {
                dispatch(
                  updateField({
                    field: "name",
                    value: value?.length ? value : undefined,
                  })
                )
              }}
            />
            <SelectField
              id="status"
              className="col-span-3 md:col-span-1"
              title="Status"
              options={[
                { value: "LI", label: "Live" },
                { value: "FO", label: "Filters only" },
                { value: "SO", label: "Search preferences only" },
                { value: "HI", label: "Hidden" },
              ]}
              value={neighbourhood.status}
              onChange={(value) => {
                dispatch(updateField({ field: "status", value }))
              }}
            />
          </FormPanel>
          <FormPanel title="Guide" className="flex flex-col" expandHeight>
            <TextField
              rows={8}
              value={neighbourhood?.guide ?? ""}
              onChange={(value) => {
                dispatch(
                  updateField({
                    field: "guide",
                    value: value?.length ? value : undefined,
                  })
                )
              }}
            />
          </FormPanel>
        </div>
      </Container>
    </>
  )
}
