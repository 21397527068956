import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"
import { WaitlistedBuyer } from "../../types/waitlisted-buyers"

type State = {
  waitlistedBuyers: WaitlistedBuyer[]
}

export type WaitlistedBuyerFilters = {
  status?: string[]
}

const initialState: State = {
  waitlistedBuyers: [],
}

export const decodeFilters = (
  searchParams: URLSearchParams
): WaitlistedBuyerFilters => {
  let filters: WaitlistedBuyerFilters = {}

  let status = searchParams
    .get("status")
    ?.split(",")
    .filter((s) => s.length)

  if (status?.length) {
    filters.status = status
  }

  return filters
}

export const encodeFilters = (
  filters: WaitlistedBuyerFilters
): URLSearchParams => {
  let params = new URLSearchParams()

  if (filters.status?.length) {
    params.set("status", filters.status.join(","))
  }

  return params
}

export const fetchWaitlistedBuyers = createAsyncThunk(
  "waitlistedBuyerList/fetchWaitlistedBuyers",
  async (filters: WaitlistedBuyerFilters, thunkAPI: any) => {
    let url = `${getEndpoint()}/waitlisted-buyers/`

    if (filters.status?.length) {
      url += `?status=${filters.status.join(",")}`
    }

    const response = await fetch(url, {
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const json = (await response.json()) as {
      waitlistedBuyers: WaitlistedBuyer[]
    }

    return json.waitlistedBuyers ?? []
  }
)

export const waitlistedBuyerListSlice = createSlice({
  name: "waitlistedBuyerList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWaitlistedBuyers.fulfilled, (state, action) => {
      state.waitlistedBuyers = action.payload
    })
  },
})

export const waitlistedBuyerListReducer = waitlistedBuyerListSlice.reducer
