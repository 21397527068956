import React, { ReactElement, useEffect, useState } from "react"
import { initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth"

import { useAppDispatch } from "../../redux/hooks"
import { login } from "../../redux/slices/user"

export const Login: React.FC = (): ReactElement => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useAppDispatch()

  useEffect(() => {
    initializeApp({
      apiKey: "AIzaSyAhz2SzyGJE1FrSIPgkLzPVOJTjKWs2uqw",
      authDomain: "ostrich-admin.firebaseapp.com",
    })
  }, [])

  const handleLogin = () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      hd: "ostrich.co.uk",
      prompt: "select_account",
    })
    const auth = getAuth()

    signInWithPopup(auth, provider)
      .then(async (result) => {
        let token = await result.user.getIdToken()

        dispatch(login({ token }))
      })
      .catch((error) => {
        console.log(error.code, error.message)
      })
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="/img/logo.svg"
          alt="Ostrich"
        />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="flex flex-col items-center">
            <button type="button" onClick={handleLogin}>
              <img src="/img/google-sign-in-normal.png" className="w-[184px]" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
