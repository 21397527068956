import dayjs from "dayjs"

import DatePicker from "react-datepicker"

import { FieldTitle, FieldTitleExtraAction } from "./field-title"

import {
  encodeDateTime,
  formatDateTime,
  formatDateWithPadding,
} from "../../../util/dates"

import "react-datepicker/dist/react-datepicker.css"
import { PencilIcon } from "@heroicons/react/solid"

type Props = {
  title?: string
  subtitle?: string
  value?: string
  disabled?: boolean
  placeholder?: string
  error?: boolean
  extraActions?: FieldTitleExtraAction[]
  className?: string
  isClearable?: boolean
  showTimeSelect?: boolean
  onChange: (value?: string) => void
  onExtraAction?: (key: string) => void
}

export const DateField: React.FC<Props> = ({
  title,
  subtitle,
  value,
  disabled,
  placeholder,
  error,
  extraActions,
  className,
  isClearable = false,
  showTimeSelect = true,
  onChange,
  onExtraAction,
}) => {
  const customInput = (
    <div
      className={`group block w-full rounded-md border px-3 py-2 text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
        value ? "relative" : "text-gray-500"
      } ${disabled ? "bg-gray-100" : "cursor-pointer"} ${
        error ? "border-red-300" : "border-gray-300"
      }`}
    >
      <button type="button">
        <p>
          {value
            ? showTimeSelect
              ? formatDateTime(value)
              : formatDateWithPadding(value)
            : placeholder ?? "Select date"}
        </p>
        {!disabled && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-2">
            <PencilIcon
              className={`${
                isClearable && "mr-6"
              } h-5 w-5 text-gray-400 group-hover:text-gray-700`}
            />
          </span>
        )}
      </button>
    </div>
  )

  const handleChange = (value: Date) => {
    if (value) {
      onChange(encodeDateTime(value))
    } else {
      onChange()
    }
  }

  return (
    <div className={className ?? "col-span-2 md:col-span-1"}>
      {title && (
        <FieldTitle
          title={title}
          subtitle={subtitle}
          extraActions={extraActions}
          onExtraAction={onExtraAction}
        />
      )}
      <div>
        <DatePicker
          showPopperArrow={false}
          isClearable={isClearable}
          showTimeSelect={showTimeSelect}
          timeIntervals={15}
          calendarStartDay={1}
          customInput={showTimeSelect && customInput}
          dateFormat={showTimeSelect ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
          selected={value ? dayjs(value).toDate() : null}
          disabled={disabled}
          onChange={handleChange}
          className={`border-red border ${
            !showTimeSelect &&
            "w-full rounded-md border-gray-300 px-3 py-2 text-left shadow-sm"
          }`}
          wrapperClassName="w-full"
        />
      </div>
    </div>
  )
}
