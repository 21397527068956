import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AvailabilityDate, BookingSlotDate } from "../../types/bookings"
import { getEndpoint } from "../../util/api"
import { formatDateWithPadding } from "../../util/dates"

type State = {
  slots: BookingSlotDate[]
}

const initialState: State = {
  slots: [],
}

export const fetchSlots = createAsyncThunk(
  "availabilityList/fetchSlots",
  async (_: void, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/bookings/slots/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as { slots?: BookingSlotDate[] }

    return json.slots ?? []
  }
)

export const setAvailabilityDate = createAsyncThunk(
  "availabilityList/setAvailabilityDate",
  async (data: AvailabilityDate, thunkAPI: any) => {
    const response = await fetch(`${getEndpoint()}/bookings/slots/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        availabilityDate: data,
      }),
    })

    const json = await response.json()
    return json
  }
)

export const setSlotAvailability = createAsyncThunk(
  "availabilityList/setSlotAvailability",
  async (data: { slot: string; available: boolean }, thunkAPI: any) => {
    await fetch(`${getEndpoint()}/bookings/slots/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        availability: [data],
      }),
    })
  }
)

export const availabilityListSlice = createSlice({
  name: "availabilityList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlots.fulfilled, (state, action) => {
        state.slots = action.payload
      })
      .addCase(setSlotAvailability.pending, (state, action) => {
        // TODO: Improve this
        for (let dateSlots of state.slots) {
          for (let time of dateSlots.times) {
            if (time.slot === action.meta.arg.slot) {
              time.available = action.meta.arg.available
            }
          }
        }
      })
      .addCase(setAvailabilityDate.fulfilled, (state, action) => {
        const dateSlot = state.slots.find(
          (slot) =>
            slot.date ===
            formatDateWithPadding(action.payload.availabilityDate.date)
        )

        if (dateSlot) {
          dateSlot.availabilityDate = action.payload.availabilityDate
        }
      })
  },
})

export const availabilityListReducer = availabilityListSlice.reducer
