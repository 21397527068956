import { ReactElement } from "react"
import { Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import { Link } from "react-router-dom"
import { formatDateTime } from "../../../util/dates"

type Props = {
  property: Property
}

export const ViewingsTab: React.FC<Props> = ({ property }): ReactElement => {
  return (
    <>
      <FormPanel title="Viewings">
        {property.viewings && property.viewings.length > 0 ? (
          <table className="col-span-2 min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  User
                </th>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"
                >
                  <span className="sr-only">View</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {property.viewings.map((viewing) => (
                <tr key={viewing.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                    {viewing.buyerName}
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 flex gap-2">
                    {formatDateTime(viewing.dateViewing)}
                    {viewing.status === "CA" && (
                      <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                        Cancelled
                      </span>
                    )}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6 md:pr-0">
                    <Link
                      to={`/viewings/${viewing.id}/`}
                      className="font-medium text-indigo-600 hover:text-indigo-900"
                      target="_blank"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="col-span-2 text-sm text-gray-500">No viewings.</p>
        )}
      </FormPanel>
    </>
  )
}
