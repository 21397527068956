import {
  DuplicateIcon,
  ExternalLinkIcon,
  TrashIcon,
} from "@heroicons/react/outline"
import { ReactElement, useState } from "react"
import { TransportLink } from "../../../types/property"
import { TransportLinkErrors } from "../../../util/validation"
import { CheckboxField } from "../../global/forms/checkbox-field"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"

type Props = {
  transportLink?: TransportLink
  errors?: TransportLinkErrors
  onChange?: (transportLink: TransportLink) => void
  onCopy?: (transportLink: TransportLink) => void
  onDelete?: (transportLink: TransportLink) => void
}

export const TransportLinkField: React.FC<Props> = ({
  transportLink,
  errors,
  onChange,
  onCopy,
  onDelete,
}): ReactElement => {
  const [distance, setDistance] = useState(transportLink?.distance)

  const handleChange = (field: string, value?: string | string[] | number) => {
    if (!onChange) return

    let updatedTransportLink = { ...transportLink }

    updatedTransportLink[field] = value

    onChange(updatedTransportLink)
  }

  const actionButtons = (
    <>
      {transportLink?.googleMapsUrl && (
        <a href={transportLink.googleMapsUrl} target="blank">
          <div className="relative inline-flex items-center gap-1 rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            <ExternalLinkIcon className="w-5" />
          </div>
        </a>
      )}
      <button
        className="relative -ml-px inline-flex items-center gap-1 border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        onClick={() => {
          if (onCopy && transportLink) onCopy(transportLink)
        }}
      >
        <DuplicateIcon className="w-5" />
      </button>
      <button
        className="relative -ml-px inline-flex items-center justify-center gap-1 rounded-r-md border border-gray-300 bg-white px-4 py-2 font-medium text-red-700 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-red-500 sm:text-sm"
        onClick={() => {
          if (onDelete && transportLink) onDelete(transportLink)
        }}
      >
        <TrashIcon className="w-5" />
      </button>
    </>
  )

  return (
    <FormPanel actionButtons={actionButtons}>
      <TextField
        id="name"
        title="Name"
        value={transportLink?.name ?? ""}
        error={errors?.name}
        onChange={(value) => handleChange("name", value)}
      />
      <CheckboxField
        title="Type"
        options={[
          { value: "UN", label: "Underground" },
          { value: "EL", label: "Elizabeth Line" },
          { value: "OV", label: "Overground" },
          { value: "NR", label: "National Rail" },
          { value: "BU", label: "Bus" },
          { value: "CS", label: "Cycle Share" },
        ]}
        value={transportLink?.types ?? []}
        error={errors?.types}
        onChange={(value) => handleChange("types", value)}
      />
      <TextField
        id="travel-time"
        title="Travel Time (minutes)"
        value={
          transportLink?.travelTime ? String(transportLink?.travelTime) : ""
        }
        onChange={(value) => handleChange("travelTime", parseInt(value ?? ""))}
      />
      <TextField
        id="distance"
        title="Distance"
        value={distance}
        onChange={(value) => setDistance(value)}
        onBlur={(value) => {
          handleChange("distance", String(parseFloat(value ?? "")))
          setDistance(String(parseFloat(value ?? "")))
        }}
      />
    </FormPanel>
  )
}
