import { useAppDispatch } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/viewing-details"
import { Viewing } from "../../../types/viewings"
import { FormPanel } from "../../global/forms/form-panel"
import { SelectField } from "../../global/forms/select-field"
import { TextField } from "../../global/forms/text-field"

type Props = {
  viewing: Viewing
}

export const ViewingFeedbackTab: React.FC<Props> = ({ viewing }) => {
  const dispatch = useAppDispatch()

  return (
    <FormPanel
      title="Feedback"
      subtitle="Internal use only"
      className="flex flex-col gap-y-6"
    >
      <SelectField
        title="Feedback Status"
        options={[
          { label: "Buyer not interested", value: "NI" },
          { label: "Offer made", value: "OM" },
          { label: "Follow up viewing", value: "FV" },
          { label: "Questions asked", value: "QA" },
        ]}
        value={viewing.feedbackStatus}
        showClearButton
        onChange={(value) =>
          dispatch(updateField({ field: "feedbackStatus", value }))
        }
        className="w-1/4"
      />
      <TextField
        title="Ostrich Feedback"
        value={viewing.feedbackInternal}
        onChange={(value) =>
          dispatch(updateField({ field: "feedbackInternal", value }))
        }
        rows={8}
      />
      <TextField
        title="Viewing Feedback"
        value={viewing.feedbackViewing}
        onChange={(value) =>
          dispatch(updateField({ field: "feedbackViewing", value }))
        }
        rows={8}
      />
      {viewing.status === "CA" && (
        <TextField
          title="Cancellation Feedback"
          value={viewing.feedbackCancellation}
          onChange={(value) =>
            dispatch(updateField({ field: "feedbackCancellation", value }))
          }
          rows={8}
        />
      )}
    </FormPanel>
  )
}
