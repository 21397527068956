import {
  InformationCircleIcon,
  CurrencyPoundIcon,
  CameraIcon,
} from "@heroicons/react/outline"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { classNames } from "../../util/misc"
import { User } from "../../types/user"
import { UserDetailsTab } from "./tabs/user-details-tab"
import { OffersTab } from "./tabs/offers-tab"
import { ViewingsTab } from "./tabs/viewings-tab"
import { Viewing } from "../../types/viewings"
import { Offer } from "../../types/offer"

const navigation = [
  {
    id: "details",
    name: "User Details",
    icon: InformationCircleIcon,
  },
  {
    id: "offers",
    name: "Offers",
    icon: CurrencyPoundIcon,
  },
  {
    id: "viewings",
    name: "Viewings",
    icon: CameraIcon,
  },
]

type Props = {
  user: User
  viewings?: Viewing[]
  offers?: Offer[]
  currentTab?: string
  onSubmit: () => void
}

export const UserForm: React.FC<Props> = ({
  user,
  viewings,
  offers,
  currentTab,
  onSubmit,
}): ReactElement => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit()
  }

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="px-2 py-6 sm:px-6 lg:col-span-3 lg:px-0 lg:py-0 2xl:col-span-2">
        <nav className="space-y-1">
          {navigation.map((item) => {
            return (
              <Link
                to={
                  `/users/${user.id}/` +
                  (item.id !== "details" ? `${item.id}/` : "")
                }
                key={item.id}
                className={classNames(
                  item.id === currentTab
                    ? "bg-gray-50 text-indigo-700 hover:bg-white hover:text-indigo-700"
                    : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                  "group flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
                )}
                aria-current={item.id === currentTab ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.id === currentTab
                      ? "text-indigo-500 group-hover:text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-1 mr-3 h-6 w-6 flex-shrink-0"
                  )}
                  aria-hidden="true"
                />
                <span className="flex-grow truncate">{item.name}</span>
              </Link>
            )
          })}
        </nav>
      </aside>
      <form
        className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 2xl:col-span-10"
        onSubmit={handleSubmit}
      >
        {currentTab === "details" && <UserDetailsTab />}
        {currentTab === "offers" && <OffersTab offers={offers} />}
        {currentTab === "viewings" && <ViewingsTab viewings={viewings} />}
      </form>
    </div>
  )
}
