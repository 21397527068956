import React from "react"
import { SelectOption } from "../../../types/forms"

type Props = {
  options: SelectOption[]
  value?: string
  className?: string
  onChange: (value?: string) => void
}

export const RadioField: React.FC<Props> = ({
  options,
  value,
  className,
  onChange,
}) => {
  return (
    <div
      className={`divide-y divide-gray-200 border-t border-b border-gray-200 ${className}`}
    >
      {options.map((option) => (
        <div
          key={`option-${option.value}`}
          className="relative flex items-center"
        >
          <div className="min-w-0 flex-1 text-sm leading-6">
            <label
              htmlFor={`option-${option.value}`}
              className="block w-full py-4 text-left font-medium text-gray-900"
            >
              {option.label}
            </label>
          </div>
          <div className="ml-3 flex h-6 items-center">
            <input
              id={`option-${option.value}`}
              name="plan"
              type="radio"
              checked={value === option.value}
              value={value}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onChange={() => onChange(option.value)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
