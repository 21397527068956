import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { WaitlistedBuyer } from "../../types/waitlisted-buyers"
import { getEndpoint } from "../../util/api"

type State = {
  waitlistedBuyer?: WaitlistedBuyer
}

const initialState: State = {}

export const fetchWaitlistedBuyer = createAsyncThunk(
  "waitlistedBuyerDetails/fetchWaitlistedBuyer",
  async (id: string, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/waitlisted-buyers/${id}/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as { waitlistedBuyer: WaitlistedBuyer }

    return json.waitlistedBuyer
  }
)

export const reviewWaitlistedBuyer = createAsyncThunk(
  "waitlistedBuyerDetails/reviewaitlistedBuyer",
  async (data: { id: string; approved: boolean }, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/waitlisted-buyers/${data.id}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          approved: data.approved,
        }),
      }
    )

    const json = (await response.json()) as { waitlistedBuyer: WaitlistedBuyer }

    return json.waitlistedBuyer
  }
)

export const editWaitlistedBuyerNotes = createAsyncThunk(
  "waitlistedBuyerDetails/editWaitlistedBuyerNotes",
  async (data: { id: string; note?: string; bioBuyer?: string }, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/waitlisted-buyers/${data.id}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          note: data.note,
          bioBuyer: data.bioBuyer,
        }),
      }
    )

    const json = (await response.json()) as { waitlistedBuyer: WaitlistedBuyer }

    return json.waitlistedBuyer
  }
)

export const deleteWaitlistedBuyer = createAsyncThunk(
  "waitlistedBuyerDetails/deleteWaitlistedBuyer",
  async (id: string, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/waitlisted-buyers/${id}/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: "DE",
      }),
    })

    const json = (await response.json()) as { waitlistedBuyer: WaitlistedBuyer }

    return json.waitlistedBuyer
  }
)

export const waitlistedBuyerDetailsSlice = createSlice({
  name: "waitlistedBuyerDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaitlistedBuyer.pending, (state, action) => {
        if (
          state.waitlistedBuyer &&
          state.waitlistedBuyer.id !== action.meta.arg
        ) {
          delete state.waitlistedBuyer
        }
      })
      .addCase(fetchWaitlistedBuyer.fulfilled, (state, action) => {
        state.waitlistedBuyer = action.payload
      })
      .addCase(reviewWaitlistedBuyer.fulfilled, (state, action) => {
        state.waitlistedBuyer = action.payload
      })
      .addCase(editWaitlistedBuyerNotes.fulfilled, (state, action) => {
        state.waitlistedBuyer = action.payload
      })
      .addCase(deleteWaitlistedBuyer.fulfilled, (state, action) => {
        state.waitlistedBuyer = action.payload
      })
  },
})

export const waitlistedBuyerDetailsReducer = waitlistedBuyerDetailsSlice.reducer
