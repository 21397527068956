import React, { Fragment, ReactElement } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon, XCircleIcon } from "@heroicons/react/solid"
import { classNames } from "../../../util/misc"
import { SelectOption } from "../../../types/forms"
import { FieldTitle, FieldTitleExtraAction } from "./field-title"

type Props = {
  id?: string
  title?: string
  subtitle?: string
  options: SelectOption[]
  value?: string
  disabled?: boolean
  error?: boolean
  placeholder?: string
  showClearButton?: boolean
  className?: string
  extraActions?: FieldTitleExtraAction[]
  onChange?: (value?: string) => void
  onExtraAction?: (key: string) => void
}

export const SelectField: React.FC<Props> = ({
  id,
  title,
  subtitle,
  options,
  value,
  disabled,
  error,
  placeholder,
  showClearButton,
  className,
  extraActions,
  onChange,
  onExtraAction,
}): ReactElement => {
  let selectedOption = options.find((o) => o.value == value)

  const handleClear = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()

    if (onChange) onChange()
  }

  return (
    <div className={"z-100 " + (className ?? "col-span-2 md:col-span-1")}>
      <Listbox
        value={value}
        disabled={disabled}
        onChange={(value) => {
          if (onChange) {
            onChange(value)
          }
        }}
      >
        {({ open }) => (
          <>
            {title && (
              <Listbox.Label className="mb-1 block text-sm font-medium text-gray-700">
                <FieldTitle
                  title={title}
                  subtitle={subtitle}
                  extraActions={extraActions}
                  onExtraAction={onExtraAction}
                />
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button
                className={`relative w-full cursor-default rounded-md border ${
                  error ? "border-red-300" : "border-gray-300"
                } ${
                  disabled ? "bg-gray-100" : "bg-white"
                } py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm`}
              >
                <span
                  className={`block truncate ${
                    selectedOption ? "" : "text-gray-500"
                  }`}
                >
                  {selectedOption?.label ?? placeholder ?? "Select"}
                </span>
                {selectedOption && showClearButton && (
                  <span
                    className="absolute inset-y-0 right-7 flex cursor-pointer items-center pr-2"
                    onClick={handleClear}
                  >
                    <XCircleIcon className="h-5 w-5 text-gray-400 hover:text-gray-700" />
                  </span>
                )}
                {!disabled && (
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <SelectorIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value ?? "empty"}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}
