import { ReactElement, useState } from "react"
import { FieldTitle } from "../../global/forms/field-title"
import { UserSearchModal } from "../../property-details/modals/user-search-modal"
import { SearchIcon } from "@heroicons/react/solid"
import { User } from "../../../types/user"

type Props = {
  title?: string
  value?: User
  disabled?: boolean
  placeholder?: string
  error?: boolean
  className?: string
  onChange: (value: User) => void
}

export const BuyerSearchField: React.FC<Props> = ({
  title,
  value,
  disabled,
  placeholder,
  error,
  className,
  onChange,
}): ReactElement => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleSelect = (buyer: User) => {
    onChange(buyer)

    setModalOpen(false)
  }

  const handleEdit = () => {
    if (disabled) return

    setModalOpen(true)
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  return (
    <>
      <div className={className ?? "col-span-2 md:col-span-1"}>
        {title && (
          <FieldTitle
            title="User"
            extraActions={
              value
                ? [
                    {
                      key: "view-user",
                      label: "View user details",
                      href: `/users/${value.id}/`,
                      external: true,
                    },
                  ]
                : undefined
            }
          />
        )}
        <div
          className={`group relative flex w-full rounded-md border px-3 py-2 text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
            value ? "" : "text-gray-500"
          } ${disabled ? "bg-gray-100" : "cursor-pointer"} ${
            error ? "border-red-300" : "border-gray-300"
          }`}
          onClick={handleEdit}
        >
          <p>
            {value
              ? `${value.firstName} ${value.lastName} (${value.email})`
              : placeholder}
          </p>
          {!disabled && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SearchIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-700" />
            </span>
          )}
        </div>
      </div>
      <UserSearchModal
        excludeIds={[]}
        isOpen={isModalOpen}
        onSelect={handleSelect}
        onClose={handleCancel}
      />
    </>
  )
}
