import { ReactElement } from "react"
import { useAppDispatch } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/property-details"
import { Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import { TextField } from "../../global/forms/text-field"

type Props = {
  property: Property
}

export const NotesTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()

  return (
    <FormPanel>
      <TextField
        title="Notes"
        subtitle="For internal use only"
        rows={8}
        value={property.internalNotes}
        className="col-span-2"
        onChange={(value) =>
          dispatch(updateField({ field: "internalNotes", value }))
        }
      />
      <TextField
        title="Viewing Notes"
        subtitle="For internal use only"
        rows={8}
        value={property.internalViewingNotes}
        className="col-span-2"
        onChange={(value) =>
          dispatch(updateField({ field: "internalViewingNotes", value }))
        }
      />
    </FormPanel>
  )
}
