import { ReactElement } from "react"
import { useAppDispatch } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/property-details"
import { Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"
import { BooleanField } from "../../global/forms/boolean-field"

type Props = {
  property: Property
}

export const AMLTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()

  return (
    <FormPanel title="AML" className="grid grid-cols-4 gap-6">
      <BooleanField
        label="IDs verified"
        checked={property.aml?.ids ?? false}
        onChange={(checked) => {
          dispatch(
            updateField({
              field: "aml",
              value: {
                ...property.aml,
                ids: checked,
              },
            })
          )
        }}
      />
      <BooleanField
        label="Signed contract"
        checked={property.aml?.contract ?? false}
        onChange={(checked) => {
          dispatch(
            updateField({
              field: "aml",
              value: {
                ...property.aml,
                contract: checked,
              },
            })
          )
        }}
      />
      <BooleanField
        label="Address verified"
        checked={property.aml?.address ?? false}
        onChange={(checked) => {
          dispatch(
            updateField({
              field: "aml",
              value: {
                ...property.aml,
                address: checked,
              },
            })
          )
        }}
      />
      <BooleanField
        label="Checked deeds"
        checked={property.aml?.deeds ?? false}
        onChange={(checked) => {
          dispatch(
            updateField({
              field: "aml",
              value: {
                ...property.aml,
                deeds: checked,
              },
            })
          )
        }}
      />
    </FormPanel>
  )
}
