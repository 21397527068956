import { ReactElement, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  fetchSlots,
  setAvailabilityDate,
  setSlotAvailability,
} from "../../../redux/slices/availability-list"
import { Container } from "../../global/container"
import { Header } from "../../global/header"
import { BookingsTabs } from "../bookings-tabs"
import { AvailabilityRow } from "./availability-row"

export const AvailabilityList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()

  const { slots } = useAppSelector((state) => state.availabilityList)

  useEffect(() => {
    dispatch(fetchSlots())
  }, [dispatch])

  return (
    <>
      <Header title="Bookings" />
      <BookingsTabs currentTab="Availability" />
      <Container>
        <div className="rounded-md bg-white px-4 py-10 shadow sm:px-6 lg:px-8">
          {slots &&
            slots.map((dateSlots) => (
              <AvailabilityRow
                slots={dateSlots}
                key={`slots-${dateSlots.date}`}
                onSetAvailability={(slot, available) =>
                  dispatch(setSlotAvailability({ slot, available }))
                }
                onSetAvailabilityDate={(availabilityDate) =>
                  dispatch(setAvailabilityDate(availabilityDate))
                }
              />
            ))}
        </div>
      </Container>
    </>
  )
}
