import { Transition, Dialog } from "@headlessui/react"
import { ExclamationIcon } from "@heroicons/react/outline"
import { ReactElement, Fragment, useRef, useState, useEffect } from "react"
import { TextField } from "../forms/text-field"

type Props = {
  isOpen: boolean
  initialValue: { note?: string; bioBuyer?: string }
  buyingAgent: boolean
  onConfirm: (value: { note?: string; bioBuyer?: string }) => void
  onCancel: () => void
}

export const EditNotesModal: React.FC<Props> = ({
  isOpen,
  initialValue,
  buyingAgent,
  onConfirm,
  onCancel,
}): ReactElement => {
  const cancelButtonRef = useRef(null)

  const [internalValue, setInternalValue] = useState(initialValue)

  useEffect(() => {
    setInternalValue(initialValue)
  }, [initialValue])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit message
                    </Dialog.Title>
                    <div className="mt-2">
                      {internalValue.bioBuyer ? (
                        <>
                          {internalValue.note && (
                            <>
                              <h3 className="mb-1 text-xs font-medium text-gray-500">
                                What appeals about the property
                              </h3>
                              <TextField
                                value={internalValue.note}
                                rows={5}
                                onChange={(value) =>
                                  setInternalValue({
                                    ...internalValue,
                                    note: value ?? "",
                                  })
                                }
                                className="w-full"
                              />
                            </>
                          )}
                          <h3 className="mb-1 mt-3 text-xs font-medium text-gray-500">
                            Tell us about{" "}
                            {buyingAgent ? "your client's search" : "yourself"}
                          </h3>
                          <TextField
                            value={internalValue.bioBuyer}
                            rows={5}
                            onChange={(value) =>
                              setInternalValue({
                                ...internalValue,
                                bioBuyer: value ?? "",
                              })
                            }
                            className="w-full"
                          />
                        </>
                      ) : (
                        <TextField
                          value={internalValue.note}
                          rows={5}
                          onChange={(value) =>
                            setInternalValue({
                              ...internalValue,
                              note: value ?? "",
                            })
                          }
                          className="w-full"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="focus:ring-none inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onConfirm(internalValue)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="focus:ring--none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => onCancel()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
