import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"
import { formatDateWithPadding } from "../../util/dates"
import { ListingAppointment } from "../../types/listingAppointment"
import { AvailabilityDate } from "../../types/bookings"

type State = {
  listingAppointment?: ListingAppointment
  status?: string
  availabilityDate?: AvailabilityDate
}

const initialState: State = {}

export const fetchListingAppointment = createAsyncThunk(
  "listingAppointmentDetails/fetchListingAppointment",
  async (id: string, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/bookings/${id}/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as {
      listingAppointment: ListingAppointment
    }

    return json.listingAppointment
  }
)

export const fetchAvailabilityDate = createAsyncThunk(
  "listingAppointmentDetails/fetchAvailabilityDate",
  async (date: string, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/misc/availability-date/${formatDateWithPadding(date)}/`,
      {
        cache: "no-cache",
        credentials: "include",
      }
    )

    const json = (await response.json()) as {
      availabilityDate: AvailabilityDate
    }

    return json.availabilityDate
  }
)

export const saveListingAppointment = createAsyncThunk(
  "listingAppointmentDetails/saveListingAppointment",
  async (_: void, thunkAPI: any) => {
    const { listingAppointment } = thunkAPI.getState().listingAppointmentDetails

    console.log(listingAppointment)

    const response = await fetch(
      `${getEndpoint()}/bookings/${listingAppointment.id}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(listingAppointment),
      }
    )

    if (response.status !== 200) {
      return thunkAPI.rejectWithValue({})
    }

    const json = (await response.json()) as {
      listingAppointment?: ListingAppointment
    }

    if (!json.listingAppointment) {
      return thunkAPI.rejectWithValue({})
    }

    return json.listingAppointment
  }
)

export const setListingAppointmentStatus = createAsyncThunk(
  "listingAppointmentDetails/setListingAppointmentStatus",
  async (
    data: { status: string; sendNotification?: boolean },
    thunkAPI: any
  ) => {
    const { listingAppointment } = thunkAPI.getState().listingAppointmentDetails

    const response = await fetch(
      `${getEndpoint()}/bookings/${listingAppointment.id}/status/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )

    if (response.status !== 200) {
      return thunkAPI.rejectWithValue({})
    }

    const json = (await response.json()) as {
      listingAppointment?: ListingAppointment
    }

    if (!json.listingAppointment) {
      return thunkAPI.rejectWithValue({})
    }

    return json.listingAppointment
  }
)

export const listingAppointmentDetailsSlice = createSlice({
  name: "listingAppointmentDetails",
  initialState,
  reducers: {
    updateField: (
      state,
      action: PayloadAction<{ field: string; value?: any }>
    ) => {
      if (!state.listingAppointment) return

      // @ts-ignore
      state.listingAppointment[action.payload.field] = action.payload.value

      state.status = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListingAppointment.fulfilled, (state, action) => {
        state.listingAppointment = action.payload
      })
      .addCase(fetchAvailabilityDate.fulfilled, (state, action) => {
        state.availabilityDate = action.payload
      })
      .addCase(setListingAppointmentStatus.fulfilled, (state, action) => {
        state.listingAppointment = action.payload

        switch (action.payload.status) {
          case "CA":
            state.status = "cancelled"
            break

          case "CO":
            state.status = "confirmed"
            break

          case "PO":
            state.status = "postponed"
            break
        }
      })
      .addCase(setListingAppointmentStatus.rejected, (state) => {
        state.status = "error"
      })
      .addCase(saveListingAppointment.fulfilled, (state, action) => {
        state.listingAppointment = action.payload
        state.status = "saved"
      })
      .addCase(saveListingAppointment.rejected, (state) => {
        state.status = "error"
      })
  },
})

export const { updateField } = listingAppointmentDetailsSlice.actions
export const listingAppointmentDetailsReducer =
  listingAppointmentDetailsSlice.reducer
