import {
  AnnotationIcon,
  CalendarIcon,
  CheckIcon,
  CurrencyPoundIcon,
  EyeIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/solid"
import React, { createElement, ReactNode } from "react"
import { formatDateTime } from "../../../util/dates"

type Props = {
  title: string
  date: string
  content?: ReactNode
  icon: string
  isChild?: boolean
  approved?: boolean
  approveLabel?: string
  hideReject?: boolean
  showEdit?: boolean
  showReview?: boolean
  onEdit?: () => void
  onReview?: (approved: boolean) => void
}

const getIconClass = (icon: string) => {
  switch (icon) {
    case "AP":
      return CheckIcon

    case "PL":
      return PlusIcon

    case "RE":
      return XIcon

    default:
      return AnnotationIcon
  }
}

const formatTitle = (title: string, date: string) => {
  return (
    title.replace(
      /\*([^\*]*)\*/g,
      '<span class="font-medium text-gray-900">$1</span>'
    ) + `&nbsp;${formatDateTime(date)}`
  )
}

export const ActivityFeedItem: React.FC<Props> = ({
  title,
  date,
  content,
  icon,
  isChild,
  approved,
  approveLabel,
  hideReject,
  showEdit,
  showReview,
  onEdit,
  onReview,
}) => {
  return (
    <li className="group">
      <div className={`relative ${isChild ? "pt-0" : "pt-8"} group-first:pt-0`}>
        <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200 group-last:hidden" />
        <div className={`relative flex items-start space-x-3`}>
          <>
            <div>
              <div className="relative px-1">
                <div
                  className={`flex items-center justify-center rounded-full ring-8 ring-white ${
                    isChild
                      ? "mx-1.5 mt-1.5 h-5 w-5 bg-gray-100"
                      : "h-8 w-8 bg-gray-400"
                  }`}
                >
                  {createElement(getIconClass(icon), {
                    className: `${
                      isChild ? "text-gray-500 h-3" : "text-white h-5"
                    }`,
                  })}
                </div>
              </div>
            </div>
            <div
              className={`min-w-0 flex-1 py-1.5 ${
                approved === false
                  ? "line-through decoration-gray-500/30 decoration-2"
                  : ""
              }`}
            >
              <div>
                <div
                  className="text-sm text-gray-500"
                  dangerouslySetInnerHTML={{ __html: formatTitle(title, date) }}
                />
              </div>
              <div className="mt-2 text-sm text-gray-700">{content}</div>
              {approved === null && (
                <div className="mt-2">
                  {showReview && (
                    <>
                      <button
                        type="button"
                        className="relative inline-flex items-center rounded-l-full px-2.5 py-1 text-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                          if (onReview) onReview(true)
                        }}
                      >
                        <span className="absolute flex flex-shrink-0 items-center justify-center">
                          <span className="h-1.5 w-1.5 rounded-full bg-indigo-500" />
                        </span>
                        <span className="ml-3 font-semibold text-gray-900">
                          {approveLabel ?? "Approve"}
                        </span>
                      </button>
                      {!hideReject && (
                        <button
                          type="button"
                          className="relative -ml-[1px] mr-2 inline-flex items-center rounded-r-full px-2.5 py-1 text-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => {
                            if (onReview) onReview(false)
                          }}
                        >
                          <span className="absolute flex flex-shrink-0 items-center justify-center">
                            <span className="h-1.5 w-1.5 rounded-full bg-rose-500" />
                          </span>
                          <span className="ml-3 font-semibold text-gray-900">
                            Reject
                          </span>
                        </button>
                      )}
                    </>
                  )}
                  {showEdit && (
                    <button
                      type="button"
                      className="relative inline-flex items-center rounded-full px-2.5 py-1 text-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => {
                        if (onEdit) onEdit()
                      }}
                    >
                      <span className="absolute flex flex-shrink-0 items-center justify-center">
                        <span className="h-1.5 w-1.5 rounded-full bg-cyan-500" />
                      </span>
                      <span className="ml-3 font-semibold text-gray-900">
                        Edit Message
                      </span>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    </li>
  )
}
