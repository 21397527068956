import { ViewingAttendee } from "../../../types/viewings"
import { ViewingAttendeeErrors } from "../../../util/validation"
import { FieldTitle } from "../../global/forms/field-title"
import { SelectField } from "../../global/forms/select-field"
import { TextField } from "../../global/forms/text-field"

type Props = {
  value?: ViewingAttendee
  disabled?: boolean
  error?: ViewingAttendeeErrors
  sellerFirstName?: string
  className?: string
  onChange: (value?: ViewingAttendee) => void
}

export const ViewingAttendeeField: React.FC<Props> = ({
  value,
  disabled,
  error,
  sellerFirstName,
  className,
  onChange,
}) => {
  const handleChangeType = (type?: string) => {
    if (type) {
      onChange({
        type,
      })
    } else {
      onChange()
    }
  }

  const handleChangeName = (name?: string) => {
    onChange({
      ...value,
      name,
    })
  }

  return (
    <div className={className ?? "col-span-2 md:col-span-1"}>
      <FieldTitle
        title="Attendee"
        extraActions={
          sellerFirstName && !disabled
            ? [
                {
                  key: "copy-seller-first-name",
                  label: "Copy seller first name",
                },
              ]
            : undefined
        }
        onExtraAction={() => {
          if (sellerFirstName) {
            onChange({
              type: "SE",
              name: sellerFirstName,
            })
          }
        }}
      />
      <div className="grid grid-cols-4 gap-x-2">
        <SelectField
          options={[
            { value: "OS", label: "Ostrich" },
            { value: "SE", label: "Seller" },
          ]}
          value={value?.type}
          disabled={disabled}
          error={error?.type}
          onChange={handleChangeType}
        />
        {value?.type === "OS" ? (
          <SelectField
            options={[
              { label: "Duncan", value: "duncan" },
              { label: "Max", value: "max" },
              { label: "Rich", value: "rich" },
            ]}
            placeholder="Select name"
            value={value.name}
            disabled={disabled}
            error={error?.name}
            onChange={handleChangeName}
          />
        ) : (
          value?.type === "SE" && (
            <TextField
              placeholder="Enter name"
              value={value.name}
              disabled={disabled}
              error={error?.name}
              onChange={handleChangeName}
            />
          )
        )}
      </div>
    </div>
  )
}
