import { ReactElement } from "react"
import { FilterOption } from "../../global/filter-option"
import { ListingAppointmentsFilters } from "../../../redux/slices/listing-appointments-list"

const statuses = [
  { key: "CO", label: "Confirmed" },
  { key: "PO", label: "Postponed" },
  { key: "CA", label: "Cancelled" },
]

interface ListingAppointmentFiltersPanelProps {
  filters: ListingAppointmentsFilters
  onChange: (filters: ListingAppointmentsFilters) => void
}

export const ListingAppointmentFiltersPanel: React.FC<
  ListingAppointmentFiltersPanelProps
> = ({ filters, onChange }): ReactElement => {
  const changeStatus = (status?: string) => {
    let updatedStatus: string[] = []

    if (status) {
      if (filters.status?.includes(status)) {
        updatedStatus = filters.status.filter((s) => s !== status)
      } else {
        updatedStatus = filters.status ? [...filters.status, status] : [status]
      }
    }

    if (
      updatedStatus.length === statuses.length ||
      updatedStatus.length === 0
    ) {
      updatedStatus = []
    }

    onChange({ ...filters, status: updatedStatus })
  }

  return (
    <div className="flex flex-col items-center justify-between gap-0 overflow-hidden bg-white text-sm text-gray-800 shadow sm:mb-6 sm:flex-row sm:gap-12 sm:rounded-md sm:px-6 lg:mb-8">
      <div className="flex items-center divide-x">
        <div className="flex h-full items-center">
          <span className="w-1/3 p-4 font-medium sm:w-auto sm:pl-0">
            Status
          </span>
        </div>
        <FilterOption
          label="All"
          checked={!filters.status || filters.status.length === 0}
          onToggle={() => changeStatus()}
        />
        <div className="grid grid-cols-5 divide-x">
          {statuses.map(({ key, label }) => (
            <FilterOption
              key={key}
              label={label}
              checked={Boolean(filters.status?.includes(key))}
              onToggle={() => changeStatus(key)}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center border-l">
        <FilterOption
          label="Include past appointments"
          checked={filters.includePastAppointments || false}
          onToggle={() => {
            onChange({
              ...filters,
              includePastAppointments: !filters.includePastAppointments,
            })
          }}
        />
      </div>
    </div>
  )
}
