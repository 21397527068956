import { ReactElement } from "react"
import { FieldTitle, FieldTitleExtraAction } from "./field-title"

type Props = {
  id?: string
  title?: string
  subtitle?: string
  rows?: number
  value?: string
  placeholder?: string
  disabled?: boolean
  error?: boolean
  extraActions?: FieldTitleExtraAction[]
  className?: string
  onChange?: (value?: string) => void
  onBlur?: (value?: string) => void
  onExtraAction?: (key: string) => void
}

export const TextField: React.FC<Props> = ({
  id,
  title,
  subtitle,
  rows,
  value,
  placeholder,
  disabled,
  error,
  extraActions,
  className,
  onChange,
  onBlur,
  onExtraAction,
}): ReactElement => {
  return (
    <div className={className ?? "col-span-2 md:col-span-1"}>
      {title && (
        <FieldTitle
          title={title}
          subtitle={subtitle}
          extraActions={extraActions}
          onExtraAction={onExtraAction}
        />
      )}
      <div>
        {rows && rows > 1 ? (
          <textarea
            id={id}
            name={id}
            rows={rows}
            className={`mt-1 block w-full rounded-md border ${
              error ? "border-red-300" : "border-gray-300"
            } shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              if (onChange) onChange(e.target.value)
            }}
          />
        ) : (
          <input
            type="text"
            name={id}
            id={id}
            className={`block w-full rounded-md ${
              error ? "border-red-300" : "border-gray-300"
            } shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
              disabled ? "bg-gray-100" : ""
            }`}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => {
              if (onChange) onChange(e.target.value)
            }}
            onBlur={(e) => {
              if (onBlur) onBlur(e.target.value)
            }}
          />
        )}
      </div>
    </div>
  )
}
