import { ChevronRightIcon } from "@heroicons/react/solid"
import { ReactElement, ReactNode, useRef, useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { Pagination } from "../pagination"
import { DateFilter } from "./date-filter"
import { useDebounce } from "usehooks-ts"

/**
 * Base interface for items that can be displayed in the table
 */
export interface TableItem {
  id: string
  [key: string]: any
}

/**
 * Pagination data structure that matches the backend response
 */
export interface PaginationData {
  totalCount: number
  totalPages: number
  currentPage: number
  pageSize: number
}

interface Props<T extends TableItem> {
  /** Array of items to display in the table */
  items: T[]
  /** Function to generate the URL for a single item */
  getItemUrl: (item: T) => string
  /** Optional table title */
  title?: string
  /** Pagination data for the current dataset */
  paginationData?: PaginationData
  /** Base URL for pagination/filtering */
  baseUrl: string
  /** Optional search functionality */
  onSearch?: (value: string) => void
  searchQuery?: string
  /** Optional date filter functionality */
  onDateChange?: (date: string | null) => void
  availableDates?: string[]
  selectedDate?: string | null
  /** Render function for table rows */
  children: (item: T) => ReactNode
}

export const Table = <T extends TableItem>({
  items,
  children,
  getItemUrl,
  title,
  onSearch,
  onDateChange,
  availableDates = [],
  selectedDate = null,
  paginationData,
  baseUrl,
  searchQuery,
}: Props<T>): ReactElement => {
  // Get the current search params to preserve them in pagination
  const [searchParams] = useSearchParams()
  const inputRef = useRef<HTMLInputElement>(null)
  const [search, setSearchTerm] = useState(searchQuery || "")
  const debouncedValue = useDebounce(search, 500)

  useEffect(() => {
    setSearchTerm(searchQuery || "")
  }, [searchQuery])

  useEffect(() => {
    if (debouncedValue !== searchQuery) {
      onSearch?.(debouncedValue)
    }
  }, [debouncedValue])

  // Helper function to generate pagination URLs with preserved filters
  const getPaginationUrl = (page: number) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set("page", page.toString())
    return `${baseUrl}?${newParams.toString()}`
  }

  return (
    <div>
      {/* Search and date filter controls */}
      {(onSearch || onDateChange) && (
        <div className="mb-2 flex justify-between">
          {onSearch && (
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                className="block w-full rounded-md border-0 shadow bg-white pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Search..."
                value={search}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
              />
              {search && (
                <button
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => {
                    setSearchTerm("")
                    onSearch?.("")
                  }}
                >
                  <svg
                    className="h-5 w-5 text-gray-400 hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}
          {onDateChange && (
            <DateFilter
              value={selectedDate}
              dates={availableDates}
              onChange={(date) => onDateChange(date)}
            />
          )}
        </div>
      )}

      {/* Table title */}
      {title && (
        <h3 className="px-4 py-4 text-lg font-medium text-gray-700 lg:px-6">
          {title}
        </h3>
      )}

      {/* Table content */}
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {items.map((item) => (
            <li key={item.id}>
              <Link to={getItemUrl(item)} className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="w-full">{children(item)}</div>
                  </div>
                  <div>
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>

        {/* Pagination controls */}
        {paginationData && (
          <Pagination
            start={
              (paginationData.currentPage - 1) * paginationData.pageSize + 1
            }
            end={Math.min(
              paginationData.currentPage * paginationData.pageSize,
              paginationData.totalCount,
            )}
            total={paginationData.totalCount}
            prevUrl={
              paginationData.currentPage > 1
                ? getPaginationUrl(paginationData.currentPage - 1)
                : undefined
            }
            nextUrl={
              paginationData.currentPage < paginationData.totalPages
                ? getPaginationUrl(paginationData.currentPage + 1)
                : undefined
            }
          />
        )}
      </div>
    </div>
  )
}
