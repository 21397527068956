import { ReactElement, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  ViewingFilters,
  decodeFilters,
  encodeFilters,
  fetchViewings,
} from "../../redux/slices/viewing-list"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { ViewingFiltersPanel } from "./viewing-filters"
import { Table } from "../global/tables/table"
import { formatDateTime } from "../../util/dates"
import { LocationMarkerIcon } from "@heroicons/react/outline"
import {
  ViewingListItem,
  ViewingListItem_Address,
} from "../../ostrich/rpc/users/viewings/v1_pb"

const formatViewingAddress = (address: ViewingListItem_Address): string[] => {
  let lines: string[] = []

  if (address.streetName) {
    if (address.buildingUnit && address.buildingName) {
      lines.push(`${address.buildingUnit} ${address.buildingName}`)
    } else if (address.buildingUnit) {
      lines.push(address.buildingUnit)
    } else if (address.buildingName) {
      lines.push(address.buildingName)
    }

    if (address.streetNumber) {
      lines.push(`${address.streetNumber} ${address.streetName}`)
    } else {
      lines.push(address.streetName)
    }
  }

  lines.push(address.city)
  lines.push(address.postcode)

  return lines
}

const ViewingRow: React.FC<{ viewing: ViewingListItem }> = ({
  viewing,
}): ReactElement => {
  const feedbackStatusColor =
    viewing.feedbackStatus === "NI"
      ? "gray"
      : viewing.feedbackStatus === "OM"
        ? "green"
        : viewing.feedbackStatus === "FV"
          ? "blue"
          : viewing.feedbackStatus === "QA"
            ? "orange"
            : "black"

  return (
    <div className="flex items-center">
      <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
        <div className="flex flex-col gap-1 text-sm">
          <p className="flex items-center gap-2 text-gray-500">
            {viewing.dateViewing && (
              <span>{formatDateTime(viewing.dateViewing)}</span>
            )}
            {viewing.status === "CA" && (
              <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                Cancelled
              </span>
            )}
            {viewing.feedbackStatus && (
              <span
                className={`inline-flex items-center rounded-md bg-${feedbackStatusColor}-100 px-2.5 py-0.5 text-xs font-medium text-${feedbackStatusColor}-800`}
              >
                {viewing.feedbackStatus === "NI"
                  ? "No Interest"
                  : viewing.feedbackStatus === "OM"
                    ? "Offer Made"
                    : viewing.feedbackStatus === "FV"
                      ? "Follow up viewing"
                      : viewing.feedbackStatus === "QA"
                        ? "Questions asked"
                        : ""}
              </span>
            )}
          </p>
          <p className="truncate font-medium text-blue-600">
            {viewing.buyer?.firstName} {viewing.buyer?.lastName}
          </p>
        </div>
        {viewing.address && (
          <div className="flex items-start gap-2 text-sm text-gray-800">
            <LocationMarkerIcon className="w-5 text-gray-500" />
            <div className="flex flex-col gap-1">
              {formatViewingAddress(viewing.address).map((line, i) => (
                <p className={i === 0 ? "font-medium" : ""} key={`line-${i}`}>
                  {line}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export const ViewingList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<ViewingFilters>({})

  useEffect(() => {
    const filters = decodeFilters(searchParams)
    dispatch(fetchViewings(filters))
    setFilters(filters)
  }, [dispatch, searchParams])

  const { viewings, pagination, datesWithViewings } = useAppSelector(
    (state) => state.viewingList,
  )

  const handleChangeFilters = (newFilters: ViewingFilters) => {
    const hasOtherFilterChanges = Object.keys(newFilters).some(
      (key) =>
        key !== "page" &&
        key !== "searchQuery" &&
        newFilters[key as keyof ViewingFilters] !==
          filters[key as keyof ViewingFilters],
    )

    const finalFilters = hasOtherFilterChanges
      ? { ...newFilters, page: 1 }
      : newFilters

    setSearchParams(encodeFilters(finalFilters))
  }

  return (
    <>
      <Header
        title="Viewings"
        primaryButton={{
          label: "Create Viewing",
          onClick: () => navigate("/viewings/new/"),
        }}
      />
      <Container>
        <ViewingFiltersPanel filters={filters} onChange={handleChangeFilters} />
        <Table
          searchQuery={filters.searchQuery}
          selectedDate={filters.date}
          paginationData={pagination}
          availableDates={datesWithViewings}
          onSearch={(value: string) => {
            setSearchParams(
              encodeFilters({ ...filters, searchQuery: value, page: 1 }),
            )
          }}
          onDateChange={(value: string | null) => {
            setSearchParams(
              encodeFilters({
                ...filters,
                date: value || undefined,
                page: 1,
              }),
            )
          }}
          items={viewings}
          getItemUrl={(viewing) => `/viewings/${viewing.id}/`}
          baseUrl="/viewings"
        >
          {(viewing) => <ViewingRow viewing={viewing} />}
        </Table>
      </Container>
    </>
  )
}
