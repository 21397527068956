import { Property } from "../types/property"
import { User } from "../types/user"
import { Viewing } from "../types/viewings"

export type PropertyErrors = {
  postcode?: boolean
  address?: boolean
  displayAddress?: boolean
  friendlyAddress?: boolean
  neighbourhood?: boolean
  intro?: boolean
  status?: boolean
  saleTimeline?: boolean
  description?: boolean
  location?: boolean
  localAuthority?: boolean
  councilTax?: boolean
  attributes?: PropertyAttributeErrors
  renovations?: {
    [id: string]: RenovationErrors
  }
  transportLinks?: {
    [id: string]: TransportLinkErrors
  }
}

type PropertyAttributeErrors = {
  interiorSize?: boolean
  outdoorSpace?: boolean
  bedrooms?: boolean
  bathrooms?: boolean
  tenure?: boolean
  buildingType?: boolean
  internalType?: boolean
  parking?: boolean
  epcUrl?: boolean
  epcCurrent?: boolean
  epcPotential?: boolean
}

export type PropertySectionErrors = {
  details: boolean
  description: boolean
  images: boolean
  renovation: boolean
  travel: boolean
  sales: boolean

  [field: string]: boolean
}

export type TransportLinkErrors = {
  name?: boolean
  types?: boolean
  travelTime?: boolean
  distance?: boolean
}

export type RenovationErrors = {
  year?: boolean
  works?: boolean
}

export type ViewingErrors = {
  property?: boolean
  type?: boolean
  dateViewing?: boolean
  attendee?: ViewingAttendeeErrors
  seller?: boolean
  buyer?: boolean
  buyerPhoneNumber?: boolean
}

export type ViewingAttendeeErrors = {
  type?: boolean
  name?: boolean
}

export type ViewingSectionErrors = {
  details: boolean
  notes: boolean
  feedback: boolean

  [field: string]: boolean
}

export type UserErrors = {
  firstName?: boolean
  lastName?: boolean
  addressLine1?: boolean
  addressCity?: boolean
  addressPostcode?: boolean
  companyName?: boolean
  companyWebsite?: boolean
  amlRegNo?: boolean
}

export const validateProperty = (
  property: Property,
): {
  errors: PropertyErrors
  sectionErrors: PropertySectionErrors
  errorMessage?: string
} => {
  let errors: PropertyErrors = {}
  let errorMessage: string | undefined

  let sectionErrors = {
    details: false,
    description: false,
    images: false,
    renovation: false,
    travel: false,
    sales: false,
  }

  if (property.status === "L") {
    // Make sure we have a preview photo
    let previewPhoto = property.photos?.find((photo) => photo.preview)

    if (!previewPhoto) {
      errors.status = true

      sectionErrors.details = true
      sectionErrors.images = true

      errorMessage = "No preview photo"
    }

    if (!property.guidePrice) {
      errors.status = true

      sectionErrors.details = true

      errorMessage = "A live property must include a price guide"
    }
  }

  if (property.saleStatus === "I") {
    // Pre-market properties need a sale timeline
    if (!property.saleTimeline?.length) {
      errors.saleTimeline = true
      sectionErrors.details = true
    }
  }

  if (!property.address?.id) {
    errors.address = true
    sectionErrors.details = true
  }

  if (!property.address?.postcode) {
    errors.postcode = true
    sectionErrors.details = true
  }

  if (!property.displayAddress?.length) {
    errors.displayAddress = true
    sectionErrors.details = true
  }

  if (!property.friendlyAddress?.length) {
    errors.friendlyAddress = true
    sectionErrors.details = true
  }

  if (!property.intro?.length) {
    errors.intro = true
    sectionErrors.details = true
  }

  let attributeErrors: PropertyAttributeErrors = {}

  if (!property.attributes.interiorSize) {
    attributeErrors.interiorSize = true
  }

  if (
    property.attributes.bedrooms === undefined ||
    isNaN(property.attributes.bedrooms)
  ) {
    attributeErrors.bedrooms = true
  }

  if (!property.attributes.bathrooms) {
    attributeErrors.bathrooms = true
  }

  if (!property.attributes.tenure) {
    attributeErrors.tenure = true
  }

  if (!property.attributes.buildingType) {
    attributeErrors.buildingType = true
  }

  if (Object.keys(attributeErrors).length) {
    errors.attributes = attributeErrors
    sectionErrors.details = true
  }

  let renovations: { [id: string]: RenovationErrors } = {}

  property.renovations?.forEach((renovation) => {
    let renovationErrors: RenovationErrors = {}

    if (!renovation.year) {
      renovationErrors.year = true
    }

    if (!renovation.works?.length) {
      renovationErrors.works = true
    }

    if (Object.keys(renovationErrors).length) {
      renovations[renovation.id!] = renovationErrors
      sectionErrors.renovation = true
    }
  })

  if (Object.keys(renovations).length) {
    errors.renovations = renovations
  }

  let transportLinks: { [id: string]: TransportLinkErrors } = {}

  property.transportLinks?.forEach((transportLink) => {
    let transportLinkErrors: TransportLinkErrors = {}

    if (!transportLink.name?.length) {
      transportLinkErrors.name = true
    }

    if (!transportLink.types?.length) {
      transportLinkErrors.types = true
    }

    if (Object.keys(transportLinkErrors).length) {
      transportLinks[transportLink.id!] = transportLinkErrors
      sectionErrors.travel = true
    }
  })

  if (Object.keys(transportLinks).length) {
    errors.transportLinks = transportLinks
  }

  return { errors, sectionErrors, errorMessage }
}

export const validateUser = (user: User) => {
  let errors: UserErrors = {}

  if (!user.firstName?.length) {
    errors.firstName = true
  }

  if (!user.lastName?.length) {
    errors.lastName = true
  }

  if (user.signupType === "BA") {
    if (!user.companyName?.length) {
      errors.companyName = true
    }

    if (!user.companyWebsite?.length) {
      errors.companyWebsite = true
    }

    if (!user.amlRegNo?.length) {
      errors.amlRegNo = true
    }
  } else {
    if (!user.address?.line1?.length) {
      errors.addressLine1 = true
    }

    if (!user.address?.city?.length) {
      errors.addressCity = true
    }

    if (!user.address?.postcode?.length) {
      errors.addressPostcode = true
    }
  }

  return errors
}

export const validateViewing = (
  viewing: Viewing,
): {
  errors: ViewingErrors
  sectionErrors: ViewingSectionErrors
  errorMessage?: string
} => {
  let errors: ViewingErrors = {}
  let errorMessage: string | undefined

  let sectionErrors: ViewingSectionErrors = {
    details: false,
    notes: false,
    feedback: false,
  }

  if (!viewing.propertyId) {
    errors.property = true
    sectionErrors.details = true
  }

  if (!viewing.type) {
    errors.type = true
    sectionErrors.details = true
  }

  if (!viewing.dateViewing) {
    errors.dateViewing = true
    sectionErrors.details = true
  }

  if (!viewing.attendee?.type) {
    errors.attendee = { type: true }
    sectionErrors.details = true
  } else if (!viewing.attendee?.name) {
    errors.attendee = { name: true }
    sectionErrors.details = true
  }

  if (!viewing.sellerId) {
    errors.seller = true
    sectionErrors.details = true
  }

  if (!viewing.buyerId) {
    errors.buyer = true
    sectionErrors.details = true
  } else if (!viewing.buyerPhoneNumber) {
    errors.buyerPhoneNumber = true
    sectionErrors.details = true
  }

  return { errors, sectionErrors, errorMessage }
}
