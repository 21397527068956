import React from "react"
import DatePicker from "react-datepicker"
import { ChevronDownIcon } from "@heroicons/react/solid"

type Props = {
  value: string | null
  dates?: string[]
  onChange: (value: string | null) => void
}

const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}

export const DateFilter: React.FC<Props> = ({ value, dates, onChange }) => {
  const [isPickerOpen, setPickerOpen] = React.useState(false)

  const handleChange = (date: Date | null) => {
    setPickerOpen(false)

    if (date) {
      onChange(formatDate(date))
    } else {
      onChange(null)
    }
  }

  return (
    <>
      <div className="relative">
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setPickerOpen(!isPickerOpen)}
        >
          Filter by date{value && ": "}
          {value && (
            <span className="font-normal">
              {new Date(value).toLocaleDateString("en-GB")}
            </span>
          )}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
        {isPickerOpen && (
          <div className="absolute top-[40px] right-0 z-20 rounded-[0.3rem] border bg-white p-1">
            <DatePicker
              showPopperArrow={false}
              isClearable
              inline
              onChange={handleChange}
              calendarClassName="!border-0"
              calendarStartDay={1}
              value={undefined}
              filterDate={(date) => {
                if (!dates) return true

                return dates.includes(formatDate(date))
              }}
            />
            <button
              type="button"
              className="flex w-full items-center justify-center border-t border-gray-300 py-2 text-sm font-medium hover:bg-blue-50"
              onClick={() => handleChange(null)}
            >
              Clear filter
            </button>
          </div>
        )}
      </div>
    </>
  )
}
