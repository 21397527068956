import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"
import { Viewing } from "../../types/viewings"
import { getEndpoint } from "../../util/api"
import {
  ViewingErrors,
  ViewingSectionErrors,
  validateViewing,
} from "../../util/validation"
import { Property } from "../../types/property"
import { User } from "../../types/user"
import { getProperty } from "../../api/properties"
import { getUser } from "../../api/users"
import { Offer } from "../../types/offer"
import { ViewingRequestListItem } from "../../ostrich/rpc/users/viewing_requests/v2_pb"

type State = {
  viewing?: Viewing
  property?: Property
  buyer?: User
  offers?: ViewingRequestListItem[]
  seller?: User
  newViewing?: boolean
  copyOffer?: Offer
  errors?: ViewingErrors
  sectionErrors?: ViewingSectionErrors
  errorMessage?: string
  status?: string
}

const getInitialState = (): State => {
  return {}
}

export const newViewing = createAsyncThunk(
  "viewingDetails/newViewing",
  async (offerId: string | null, thunkAPI) => {
    let state = getInitialState()

    state.newViewing = true

    state.viewing = {
      id: uuidv4(),
      status: "CO",
    }

    // If we have an offer ID, copy the offer details into the viewing
    if (offerId) {
      const response = await fetch(`${getEndpoint()}/offers/${offerId}/`, {
        cache: "no-cache",
        credentials: "include",
      })

      const json = (await response.json()) as { offer: Offer }

      if (json.offer) {
        let offer = json.offer
        let seller: User | undefined

        if (offer.sellers?.length === 1) {
          seller = offer.sellers[0]
        }

        state.viewing.propertyId = offer.property.id
        state.viewing.buyerId = offer.user.id
        state.viewing.buyerPhoneNumber = offer.user.phoneNumber
        state.viewing.sellerId = seller?.id
        state.viewing.sellerPhoneNumber = seller?.phoneNumber

        state.buyer = offer.user
        state.property = offer.property
        state.property.users = offer.sellers
        state.seller = seller
      }
    }

    return state
  },
)

export const fetchViewing = createAsyncThunk(
  "viewingDetails/fetchViewing",
  async (id: string, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/viewings/${id}/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as {
      viewing?: Viewing
    }

    const viewing = json.viewing

    const property = viewing?.propertyId
      ? await getProperty(viewing.propertyId)
      : undefined

    const buyer = viewing?.buyerId ? await getUser(viewing.buyerId) : undefined

    const seller = viewing?.sellerId
      ? await getUser(viewing.sellerId)
      : undefined

    const offersResponse = await fetch(
      `${getEndpoint()}/offers/?userId=${viewing?.buyerId}&propertyId=${
        viewing?.propertyId
      }&pageSize=10000`,
      {
        cache: "no-cache",
        credentials: "include",
      },
    )

    const offers = (await offersResponse.json()).viewingRequests

    return { viewing, property, buyer, seller, offers }
  },
)

export const saveViewing = createAsyncThunk(
  "viewingDetails/saveViewing",
  async (offerId: string | null, thunkAPI: any) => {
    let { viewing, newViewing } = thunkAPI.getState().viewingDetails

    let validation = validateViewing(viewing)

    if (Object.keys(validation.errors).length) {
      return thunkAPI.rejectWithValue({ validation })
    }

    if (offerId && newViewing) {
      viewing = { ...viewing, offerId }
    }

    const response = await fetch(
      `${getEndpoint()}/viewings/${newViewing ? "new" : viewing.id}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(viewing),
      },
    )

    if (response.status !== 200) {
      return thunkAPI.rejectWithValue({})
    }

    const json = (await response.json()) as { viewing?: Viewing }

    if (!json.viewing) {
      return thunkAPI.rejectWithValue({})
    }

    viewing = json.viewing

    const property = viewing?.propertyId
      ? await getProperty(viewing.propertyId)
      : undefined

    const buyer = viewing?.buyerId ? await getUser(viewing.buyerId) : undefined

    const seller = viewing?.sellerId
      ? await getUser(viewing.sellerId)
      : undefined

    return { viewing, property, buyer, seller }
  },
)

export const cancelViewing = createAsyncThunk(
  "viewingDetails/cancelViewing",
  async (id: string, thunkAPI: any) => {
    const response = await fetch(`${getEndpoint()}/viewings/${id}/cancel/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (response.status !== 200) {
      return thunkAPI.rejectWithValue({})
    }

    const json = (await response.json()) as { viewing?: Viewing }

    if (!json.viewing) {
      return thunkAPI.rejectWithValue({})
    }

    return json.viewing
  },
)

export const viewingDetailsSlice = createSlice({
  name: "viewingDetails",
  initialState: getInitialState(),
  reducers: {
    updateField: (
      state,
      action: PayloadAction<{ field: string; value?: any }>,
    ) => {
      if (!state.viewing) return

      state.viewing[action.payload.field] = action.payload.value ?? ""

      state.status = undefined
    },
    updateBuyer: (state, action: PayloadAction<User | undefined>) => {
      if (!state.viewing) return

      state.viewing.buyerId = action.payload?.id
      state.viewing.buyerPhoneNumber = undefined
      state.buyer = action.payload

      state.status = undefined
    },
    updateSeller: (state, action: PayloadAction<User | undefined>) => {
      if (!state.viewing) return

      state.viewing.sellerId = action.payload?.id
      state.viewing.sellerPhoneNumber = undefined
      state.seller = action.payload

      state.status = undefined
    },
    updateProperty: (state, action: PayloadAction<Property>) => {
      if (!state.viewing) return

      state.viewing.propertyId = action.payload.id
      state.property = action.payload

      let seller: User | undefined

      // If the new property only has one user, set it as the seller
      if (action.payload.users?.length === 1) {
        seller = action.payload.users[0]
      }
      // Check the existing value is also on the new property
      else if (state.viewing.sellerId) {
        seller = action.payload.users?.find(
          (u) => u.id === state.viewing?.sellerId,
        )
      }

      state.viewing.sellerId = seller?.id
      state.seller = seller

      state.status = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newViewing.pending, (state, action) => {
        return {
          newViewing: true,
          ...getInitialState(),
        }
      })
      .addCase(newViewing.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(fetchViewing.pending, () => {
        return getInitialState()
      })
      .addCase(fetchViewing.fulfilled, (state, action) => {
        state.viewing = action.payload.viewing
        state.property = action.payload.property
        state.buyer = action.payload.buyer
        state.seller = action.payload.seller
        state.offers = action.payload.offers
      })
      .addCase(saveViewing.fulfilled, (state, action) => {
        state.viewing = action.payload.viewing
        state.property = action.payload.property
        state.buyer = action.payload.buyer
        state.seller = action.payload.seller
        state.newViewing = false
        state.errors = undefined
        state.sectionErrors = undefined
        state.status = "success"
      })
      .addCase(saveViewing.rejected, (state, action) => {
        let payload = action.payload as {
          validation?: {
            errors: ViewingErrors
            sectionErrors: ViewingSectionErrors
            errorMessage?: string
          }
        }

        if (payload.validation) {
          state.errors = payload.validation.errors
          state.sectionErrors = payload.validation.sectionErrors
          state.errorMessage = payload.validation.errorMessage
        }

        state.status = "error"
      })
      .addCase(cancelViewing.fulfilled, (state, action) => {
        state.viewing = action.payload
        state.status = "cancelled"
      })
      .addCase(cancelViewing.rejected, (state) => {
        state.status = "error"
      })
  },
})

export const { updateBuyer, updateField, updateProperty, updateSeller } =
  viewingDetailsSlice.actions
export const viewingDetailsReducer = viewingDetailsSlice.reducer
