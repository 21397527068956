import { ReactElement } from "react"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { UserSearch } from "../global/search/user-search"
import { useNavigate } from "react-router-dom"

export const UserList: React.FC = (): ReactElement => {
  const navigate = useNavigate()

  return (
    <>
      <Header title="Users" />
      <Container>
        <div className="bg-white p-2 text-sm shadow sm:rounded-md">
          <UserSearch onSelect={(user) => navigate(`/users/${user.id}/`)} />
        </div>
      </Container>
    </>
  )
}
