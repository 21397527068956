import { ChevronRightIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { Neighbourhood } from "../../../types/property"
import { NeighbourhoodStatusLabel } from "./status-label"

type Props = {
  neighbourhoods: Neighbourhood[]
}

export const NeighbourhoodsTable: React.FC<Props> = ({
  neighbourhoods,
}): ReactElement => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {neighbourhoods.map((neighbourhood) => (
          <li key={neighbourhood.id}>
            <Link
              to={`/neighbourhoods/${neighbourhood.id}/`}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <p className="flex items-center gap-2 truncate text-sm font-medium text-blue-600">
                    {neighbourhood.name}
                    <NeighbourhoodStatusLabel neighbourhood={neighbourhood} />
                  </p>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
