import { Fragment, ReactElement, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ExclamationIcon } from "@heroicons/react/outline"
import { RadioField } from "../global/forms/radio-field"

type Props = {
  isOpen: boolean
  initialValue: string
  onConfirm: (value: string) => void
  onCancel: () => void
}

export const StatusModal: React.FC<Props> = ({
  isOpen,
  initialValue,
  onConfirm,
  onCancel,
}): ReactElement => {
  const cancelButtonRef = useRef(null)

  const [value, setValue] = useState(initialValue)

  let options = [
    {
      label: "Awaiting Response",
      value: "AW",
    },
    { label: "Looking for higher price", value: "HP" },
    { label: "Wrong time to sell", value: "WT" },
    { label: "Viewing offered", value: "VO" },
    { label: "Viewing arranged", value: "VA" },
    { label: "In discussion", value: "DI" },
    { label: "Under offer", value: "UO" },
    { label: "Completed", value: "CM" },
    { label: "Closed", value: "CL" },
  ]

  // Some statuses don't make sense to change directly to,
  // so remove them unless it's the current value
  if (initialValue === "AR") {
    options.push({ label: "Availability requested", value: "AR" })
  } else if (initialValue == "AS") {
    options.push({ label: "Alternative slots requested", value: "AS" })
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mx-4 w-full transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:hidden sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Change Status
                    </Dialog.Title>
                    <div className="mt-4">
                      <RadioField
                        className="w-full"
                        value={value}
                        options={options}
                        onChange={(value) => {
                          if (value) setValue(value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="focus:ring-none inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onConfirm(value)}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="focus:ring--none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setValue(initialValue)
                      onCancel()
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
