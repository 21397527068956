import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"
import { ListResponse } from "../../ostrich/rpc/properties/properties/v1_pb"
import { BaseFilters } from "../../types/filters"

export interface PropertyFilters extends BaseFilters {
  status?: string[]
}

const initialState: ListResponse = {
  properties: [],
  pagination: {
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 20,
  },
}

export const decodeFilters = (
  searchParams: URLSearchParams,
): PropertyFilters => {
  const filters: PropertyFilters = {}

  const status = searchParams
    .get("status")
    ?.split(",")
    .filter((s) => s.length)

  if (status?.length) {
    filters.status = status
  }

  const search = searchParams.get("search")

  if (search) {
    filters.searchQuery = search
  }

  const page = searchParams.get("page")

  if (page) {
    filters.page = parseInt(page)
  }

  return filters
}

export const encodeFilters = (filters: PropertyFilters): URLSearchParams => {
  const params = new URLSearchParams()

  if (filters.status?.length) {
    params.set("status", filters.status.join(","))
  }

  if (filters.searchQuery) {
    params.set("search", filters.searchQuery)
  }

  if (filters.page) {
    params.set("page", filters.page.toString())
  }

  return params
}

export const fetchProperties = createAsyncThunk(
  "propertyList/fetchProperties",
  async (filters: PropertyFilters, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/properties/?${encodeFilters(filters)}`,
      {
        cache: "no-cache",
        credentials: "include",
      },
    )

    const json = (await response.json()) as ListResponse

    return json
  },
)

export const propertyListSlice = createSlice({
  name: "propertyList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProperties.fulfilled, (state, action) => {
      state.properties = action.payload.properties
      state.pagination = action.payload.pagination
    })
  },
})

export const propertyListReducer = propertyListSlice.reducer
