import { ReactElement } from "react"
import { SelectField } from "./select-field"

type Props = {
  title?: string
  label?: string
  checked: boolean
  error?: boolean
  isDropdown?: boolean
  onChange: (checked: boolean) => void
}

export const BooleanField: React.FC<Props> = ({
  title,
  label,
  checked,
  error,
  isDropdown,
  onChange,
}): ReactElement => {
  if (isDropdown) {
    return (
      <SelectField
        title={title}
        options={[
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ]}
        value={checked ? "yes" : "no"}
        error={error}
        onChange={(value) => onChange(value === "yes")}
      />
    )
  } else {
    return (
      <div className="col-span-2 md:col-span-1">
        {title && (
          <label className="block text-sm font-medium text-gray-700">
            {title}
          </label>
        )}
        <div
          className={`mt-1 flex flex-wrap items-center gap-x-4 rounded-md border px-4 text-sm text-gray-700 ${
            error ? "border-red-300" : "border-gray-300"
          }`}
        >
          <div className="flex h-[38px] cursor-pointer items-center gap-2">
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
              id={label}
            />
            {label && <label htmlFor={label}>{label}</label>}
          </div>
        </div>
      </div>
    )
  }
}
