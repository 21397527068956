export const getEndpoint = (): string => {
  return process.env.REACT_APP_API_ENDPOINT ?? "http://ubuntu-ostrich:8000"
}

export const getImageUrl = (filename: string, suffix?: string): string => {
  let parts = filename.split(".")
  let extension = parts[parts.length - 1]

  if (suffix) {
    filename =
      "processed/" +
      filename.replace(`.${extension}`, `-${suffix}.${extension}`)
  }

  return `https://ostrich-media.s3.eu-west-2.amazonaws.com/${filename}`
}

export const getSiteUrl = (): string => {
  return `https://${window.location.hostname.replace("admin.", "")}`
}
