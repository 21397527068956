import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid"
import React, { createElement, ReactNode } from "react"

export enum InfoBannerStyle {
  Alert,
  Error,
  Info,
  Success,
}

const getIcon = (style?: InfoBannerStyle) => {
  switch (style) {
    case InfoBannerStyle.Alert:
      return ExclamationCircleIcon

    case InfoBannerStyle.Error:
      return XCircleIcon

    case InfoBannerStyle.Success:
      return CheckCircleIcon

    default:
      return InformationCircleIcon
  }
}

const getColours = (style?: InfoBannerStyle) => {
  switch (style) {
    case InfoBannerStyle.Alert:
      return {
        background: "bg-yellow-50",
        icon: "text-yellow-400",
        title: "text-yellow-800",
        text: "text-yellow-700",
      }

    case InfoBannerStyle.Error:
      return {
        background: "bg-red-50",
        icon: "text-red-400",
        title: "text-red-800",
        text: "text-red-700",
      }

    case InfoBannerStyle.Success:
      return {
        background: "bg-green-50",
        icon: "text-green-400",
        title: "text-green-800",
        text: "text-green-700",
      }

    default:
      return {
        background: "bg-blue-50",
        icon: "text-blue-400",
        title: "text-blue-800",
        text: "text-blue-700",
      }
  }
}

type Props = {
  title?: string
  style?: InfoBannerStyle
  className?: string
  children?: ReactNode
}

export const InfoBanner: React.FC<Props> = ({
  title,
  style,
  className,
  children,
}) => {
  let colours = getColours(style)

  let icon = createElement(getIcon(style), {
    className: `h-5 w-5 ${colours.icon}`,
  })

  return (
    <div className={`rounded-md p-4 ${colours.background} ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          {title && (
            <h3 className={`mb-2 text-sm font-medium ${colours.title}`}>
              {title}
            </h3>
          )}
          <div className={`text-sm text-yellow-700 ${colours.text}`}>
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
