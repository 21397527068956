import { ReactElement, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  decodeFilters,
  fetchProperties,
  PropertyFilters,
} from "../../redux/slices/property-list"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { PropertyFiltersPanel } from "./property-filters"
import { Table } from "../global/tables/table"
import { PropertyListItem } from "../../ostrich/rpc/properties/properties/v1_pb"
import { encodeFilters } from "../../redux/slices/viewing-list"
import { formatAddress } from "../../util/addresses"
import {
  ArrowsExpandIcon,
  CloudIcon,
  EyeOffIcon,
  HomeIcon,
  InformationCircleIcon,
  LinkIcon,
  StarIcon,
  UserIcon,
} from "@heroicons/react/outline"

const PropertyRow: React.FC<{ propertyListItem: PropertyListItem }> = ({
  propertyListItem: property,
}): ReactElement => {
  return (
    <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
      <div>
        <div className="flex flex-col gap-2 text-sm font-medium text-blue-600 xl:flex-row xl:items-center">
          {property.address
            ? formatAddress(property.address, true)
            : property.displayAddress}
          {property.bookingId && (
            <div className="flex">
              <UserIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-teal-600 xl:h-4 xl:w-4" />
              <span className="font-normal text-teal-600 xl:text-xs">
                User Submitted
              </span>
            </div>
          )}
          {property.users && (
            <div className="flex">
              <LinkIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 xl:h-4 xl:w-4" />
              <span className="font-normal text-gray-500 xl:text-xs">
                {property.users
                  .map((user) => `${user.firstName} ${user.lastName}`)
                  .join(", ")}
              </span>
            </div>
          )}
          {property.status === "H" && (
            <div className="flex">
              <EyeOffIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 xl:h-4 xl:w-4" />
              <span className="font-normal text-gray-500 xl:text-xs">
                Hidden
              </span>
            </div>
          )}
          {property.featured && (
            <div className="flex">
              <StarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-600 xl:h-4 xl:w-4" />
              <span className="font-normal text-green-600 xl:text-xs">
                Featured
              </span>
            </div>
          )}
        </div>
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <InformationCircleIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <span className="mono truncate">{property.id}</span>
        </p>
      </div>
      <div className="hidden items-center md:flex">
        <ul className="grid grid-cols-3 gap-8 text-sm text-gray-500">
          <li className="flex md:w-[120px] lg:w-[160px]">
            <CloudIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {property.attributes?.bedrooms
              ? `${property.attributes.bedrooms} Bedroom${
                  property.attributes.bedrooms > 1 ? "s" : ""
                }`
              : "Studio"}
          </li>
          <li className="flex md:w-[120px] lg:w-[160px]">
            {property.attributes?.buildingType && (
              <>
                <HomeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {property.attributes.buildingType}
              </>
            )}
          </li>
          <li className="flex md:w-[120px] lg:w-[160px]">
            {property.attributes?.interiorSize && (
              <>
                <ArrowsExpandIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {property.attributes.interiorSize.toLocaleString()} sq ft
              </>
            )}
          </li>
        </ul>
      </div>
    </div>
  )
}

export const PropertyList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<PropertyFilters>({})

  useEffect(() => {
    const filters = decodeFilters(searchParams)
    dispatch(fetchProperties(filters))
    setFilters(filters)
  }, [dispatch, searchParams])

  const { properties, pagination } = useAppSelector(
    (state) => state.propertyList,
  )

  const handleChangeFilters = (newFilters: PropertyFilters) => {
    const hasOtherFilterChanges = Object.keys(newFilters).some(
      (key) =>
        key !== "page" &&
        key !== "searchQuery" &&
        newFilters[key as keyof PropertyFilters] !==
          filters[key as keyof PropertyFilters],
    )

    const finalFilters = hasOtherFilterChanges
      ? { ...newFilters, page: 1 }
      : newFilters

    setSearchParams(encodeFilters(finalFilters))
  }

  return (
    <>
      <Header
        title="Properties"
        primaryButton={{
          label: "Create Property",
          onClick: () => navigate("/properties/new/"),
        }}
      />
      <Container>
        <PropertyFiltersPanel
          filters={filters}
          onChange={handleChangeFilters}
        />
        <Table
          searchQuery={filters.searchQuery}
          paginationData={pagination}
          onSearch={(value: string) => {
            setSearchParams(
              encodeFilters({ ...filters, searchQuery: value, page: 1 }),
            )
          }}
          items={properties}
          getItemUrl={(propertyListItem) =>
            `/properties/${propertyListItem.id}/`
          }
          baseUrl="/properties"
        >
          {(propertyListItem) => (
            <PropertyRow propertyListItem={propertyListItem} />
          )}
        </Table>
      </Container>
    </>
  )
}
