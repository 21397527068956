import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"
import { getEndpoint } from "../../util/api"
import { Neighbourhood, NeighbourhoodStatus } from "../../types/property"

type State = {
  neighbourhood?: Neighbourhood
  newNeighbourhood: boolean
  status?: string
}

const getInitialState = (): State => {
  return {
    newNeighbourhood: false,
  }
}

export const fetchNeighbourhood = createAsyncThunk(
  "neighbourhoodDetails/fetchNeighbourhood",
  async (id: string, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/misc/neighbourhoods/${id}/`,
      {
        cache: "no-cache",
        credentials: "include",
      }
    )

    const json = (await response.json()) as { neighbourhood: Neighbourhood }

    return json.neighbourhood
  }
)

export const saveNeighbourhood = createAsyncThunk(
  "neighbourhoodDetails/saveNeighbourhood",
  async (_: void, thunkAPI: any) => {
    const { neighbourhood, newNeighbourhood } =
      thunkAPI.getState().neighbourhoodDetails

    let url = `${getEndpoint()}/misc/neighbourhoods/`

    if (!newNeighbourhood) {
      url += `${neighbourhood.id}/`
    }

    const response = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(neighbourhood),
    })

    if (response.status !== 200) {
      return thunkAPI.rejectWithValue({})
    }

    const json = (await response.json()) as { neighbourhood?: Neighbourhood }

    if (!json.neighbourhood) {
      return thunkAPI.rejectWithValue({})
    }

    return json.neighbourhood
  }
)

export const neighbourhoodDetailsSlice = createSlice({
  name: "neighbourhoodDetails",
  initialState: getInitialState(),
  reducers: {
    newNeighbourhood: (state) => {
      let updatedState = getInitialState()

      updatedState.newNeighbourhood = true
      updatedState.neighbourhood = {
        id: uuidv4(),
        status: NeighbourhoodStatus.Hidden,
        name: "",
      }

      return updatedState
    },
    updateField: (
      state,
      action: PayloadAction<{ field: keyof Neighbourhood; value?: any }>
    ) => {
      if (!state.neighbourhood) return

      state.neighbourhood[action.payload.field] = action.payload.value

      state.status = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNeighbourhood.fulfilled, (state, action) => {
        state.neighbourhood = action.payload
      })
      .addCase(saveNeighbourhood.fulfilled, (state, action) => {
        state.neighbourhood = action.payload
        state.newNeighbourhood = false
        state.status = "saved"
      })
      .addCase(saveNeighbourhood.rejected, (state) => {
        state.status = "error"
      })
  },
})

export const { newNeighbourhood, updateField } =
  neighbourhoodDetailsSlice.actions
export const neighbourhoodDetailsReducer = neighbourhoodDetailsSlice.reducer
