import { ReactElement, useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Login } from "./components/auth/login"
import { AvailabilityList } from "./components/bookings/availability/availability-list"
import { BookingDetails } from "./components/bookings/details/booking-details"
import { BookingsList } from "./components/bookings/list/bookings-list"
import { Navigation } from "./components/global/navigation"
import { OfferDetails } from "./components/offer-details/offer-details"
import { OfferList } from "./components/offer-list/offer-list"
import { PropertyDetails } from "./components/property-details/property-details"
import { PropertyList } from "./components/property-list/property-list"
import { useAppDispatch, useAppSelector } from "./redux/hooks"
import { sync } from "./redux/slices/user"
import { ViewingList } from "./components/viewing-list/viewing-list"
import { ViewingDetails } from "./components/viewing-details/viewing-details"
import { UserList } from "./components/user-list/user-list"
import { UserDetails } from "./components/user-details/user-details"
import { NeighbourhoodsList } from "./components/neighbourhoods/list/neighbourhoods-list"
import { NeighbourhoodDetails } from "./components/neighbourhoods/details/neighbourhood-details"
import { WaitlistedBuyerList } from "./components/waitlisted-buyers/list/waitlisted-buyer-list"
import { WaitlistedBuyerDetails } from "./components/waitlisted-buyers/details/waitlisted-buyer-details"

export const App = (): ReactElement => {
  const dispatch = useAppDispatch()

  const { loading, user } = useAppSelector((state) => state.user)

  useEffect(() => {
    dispatch(sync())
  }, [dispatch])

  if (loading) {
    return <div />
  }

  if (!user) {
    return <Login />
  }

  return (
    <div className="min-h-full">
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/properties/" element={<PropertyList />} />
          <Route path="/properties/:id/" element={<PropertyDetails />} />
          <Route path="/properties/:id/:tab/" element={<PropertyDetails />} />
          <Route path="/bookings/" element={<BookingsList />} />
          <Route path="/bookings/:id/" element={<BookingDetails />} />
          <Route
            path="/bookings/availability/"
            element={<AvailabilityList />}
          />
          <Route path="/offers/" element={<OfferList />} />
          <Route path="/offers/:id/" element={<OfferDetails />} />
          <Route path="/users/" element={<UserList />} />
          <Route path="/users/:id/" element={<UserDetails />} />
          <Route path="/users/:id/:tab/" element={<UserDetails />} />
          <Route path="/viewings/" element={<ViewingList />} />
          <Route path="/viewings/:id/" element={<ViewingDetails />} />
          <Route path="/viewings/:id/:tab/" element={<ViewingDetails />} />
          <Route path="/neighbourhoods/" element={<NeighbourhoodsList />} />
          <Route
            path="/neighbourhoods/:id/"
            element={<NeighbourhoodDetails />}
          />
          <Route path="/waitlisted-buyers/" element={<WaitlistedBuyerList />} />
          <Route
            path="/waitlisted-buyers/:id/"
            element={<WaitlistedBuyerDetails />}
          />
          <Route path="*" element={<Navigate to="/properties/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
