import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Neighbourhood } from "../../types/property"
import { getEndpoint } from "../../util/api"

type State = {
  neighbourhoods: Neighbourhood[]
}

const initialState: State = {
  neighbourhoods: [],
}

export const fetchNeighbourhoods = createAsyncThunk(
  "neighbourhoodsList/fetchNeighbourhoods",
  async (_: void, thunkAPI: any) => {
    const response = await fetch(`${getEndpoint()}/misc/neighbourhoods/`, {
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const json = (await response.json()) as { neighbourhoods: Neighbourhood[] }

    return json.neighbourhoods ?? []
  }
)

export const neighbourhoodsListSlice = createSlice({
  name: "neighbourhoodsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNeighbourhoods.fulfilled, (state, action) => {
      state.neighbourhoods = action.payload
    })
  },
})

export const neighbourhoodsListReducer = neighbourhoodsListSlice.reducer
