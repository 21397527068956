import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"

type User = {
  id: string
  email: string
}

type State = {
  loading: boolean
  user?: User
}

const initialState: State = {
  loading: false,
}

export const sync = createAsyncThunk("user/sync", async (_: void, thunkAPI) => {
  const response = await fetch(`${getEndpoint()}/auth/sync/`, {
    cache: "no-cache",
    credentials: "include",
  })

  const json = (await response.json()) as { user?: User }

  if (!json.user) {
    return thunkAPI.rejectWithValue(false)
  }

  return json.user
})

export const login = createAsyncThunk(
  "user/login",
  async (data: { token: string }, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/auth/login/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    const json = await response.json()

    return json
  }
)

export const logout = createAsyncThunk("user/logout", async (_, thunkAPI) => {
  await fetch(`${getEndpoint()}/auth/logout/`, {
    cache: "no-cache",
    credentials: "include",
    method: "POST",
  })
})

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = undefined
      })
      .addCase(sync.pending, (state) => {
        state.loading = true
      })
      .addCase(sync.fulfilled, (state, action) => {
        state.user = action.payload
        state.loading = false
      })
      .addCase(sync.rejected, (state) => {
        state.loading = false
      })
  },
})

export const userReducer = userSlice.reducer
