import { HomeIcon, InformationCircleIcon } from "@heroicons/react/outline"

import { ChevronRightIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { Pagination } from "../../global/pagination"
import { WaitlistedBuyer } from "../../../types/waitlisted-buyers"
import { ItemDates } from "../../global/tables/item-dates"
import { WaitlistedBuyerStatusLabel } from "../details/status-label"

type Props = {
  waitlistedBuyers: WaitlistedBuyer[]
}

export const WaitlistedBuyerTable: React.FC<Props> = ({
  waitlistedBuyers,
}): ReactElement => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {waitlistedBuyers.map((waitlistedBuyer) => (
          <li key={waitlistedBuyer.id}>
            <Link
              to={`/waitlisted-buyers/${waitlistedBuyer.id}/`}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
                    <div>
                      <p className="flex items-center gap-2 truncate text-sm font-medium text-blue-600">
                        {waitlistedBuyer.user.firstName}{" "}
                        {waitlistedBuyer.user.lastName}
                        <WaitlistedBuyerStatusLabel
                          waitlistedBuyer={waitlistedBuyer}
                          style="small"
                        />
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <InformationCircleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="mono truncate">
                          {waitlistedBuyer.id}
                        </span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <ul className="mt-2 flex flex-col gap-1 text-sm text-gray-500">
                          <li className="flex">
                            <HomeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {waitlistedBuyer.property.displayAddress}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <ItemDates item={waitlistedBuyer} />
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {/* <Pagination
        start={1}
        end={waitlistedBuyers.length}
        total={waitlistedBuyers.length}
        enablePrev={false}
        enableNext={false}
      /> */}
    </div>
  )
}
