import { Fragment, ReactElement, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ExclamationIcon } from "@heroicons/react/outline"
import { InfoBanner, InfoBannerStyle } from "../global/info-banner"
import { Offer, OfferMessage } from "../../types/offer"

type Props = {
  isOpen: boolean
  offer: Offer
  message?: OfferMessage
  approve: boolean
  onConfirm: (approved: boolean) => void
  onCancel: () => void
}

const getRecipientType = (
  message?: OfferMessage
): "buyer" | "seller" | undefined => {
  // Are we reviewing an offer message?
  if (message) {
    // If it's a "Contact Ostrich" message,
    // no emails are sent
    if (message.type === "CO" && message.recipient === "OS") {
      return
    }

    // All other types will trigger an email
    // based on the message recipient
    if (message.recipient === "BU") {
      return "buyer"
    } else if (message.recipient === "SE") {
      return "seller"
    }
  } else {
    // We're reviewing the offer, so it will always
    // be the seller that is notified
    return "seller"
  }
}

const getRecipientEmail = (
  offer: Offer,
  recipientType?: "buyer" | "seller"
): string | undefined => {
  // If the recipient is the seller, we want to
  // send to all users attached to the property
  if (recipientType === "seller") {
    return offer.sellers?.map((s) => s.email).join(",")
  }

  // For the buyer, it's just the user that
  // made the offer
  if (recipientType === "buyer") {
    return offer.user.email
  }
}

const getActionNoun = (message?: OfferMessage) => {
  if (message) {
    if (message.type === "CO") {
      return "Message"
    }

    // All non-contact messages are classed as responses
    return "Response"
  } else {
    return "Offer"
  }
}

const getActionVerb = (approve: boolean, message?: OfferMessage): string => {
  // If this is a contact message, the only action
  // is to acknowledge it (no approval/rejection)
  if (message?.type === "CO") {
    return "Acknowledge"
  }

  return approve ? "Approve" : "Reject"
}

export const ReviewModal: React.FC<Props> = ({
  isOpen,
  offer,
  message,
  approve,
  onConfirm,
  onCancel,
}): ReactElement => {
  const cancelButtonRef = useRef(null)

  let buyerVerified = offer.user.verified.stripe === "VE"

  let recipientType = getRecipientType(message)
  let recipientEmail: string | undefined

  // If this is an approval action, we'll show who
  // will receive an email notification from Ostrich
  if (approve) {
    recipientEmail = getRecipientEmail(offer, recipientType)
  }

  const actionNoun = getActionNoun(message)
  const actionVerb = getActionVerb(approve, message)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {!buyerVerified && (
                  <InfoBanner style={InfoBannerStyle.Alert} className="mb-5">
                    The buyer has not verified their identity.
                  </InfoBanner>
                )}
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {actionVerb} {actionNoun}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to {actionVerb.toLowerCase()} the{" "}
                        {actionNoun.toLowerCase()}?
                      </p>
                      {recipientType && recipientEmail && (
                        <p className="mt-2 text-sm text-gray-500">
                          This will send an email to the {recipientType} at{" "}
                          <span className="text-blue-500">
                            {recipientEmail}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="focus:ring-none inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onConfirm(approve)}
                  >
                    {actionVerb}
                  </button>
                  <button
                    type="button"
                    className="focus:ring--none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => onCancel()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
