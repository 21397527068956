import { ReactElement } from "react"
import { FilterOption } from "../../global/filter-option"
import { WaitlistedBuyerFilters } from "../../../redux/slices/waitlisted-buyer-list"

type Props = {
  filters: WaitlistedBuyerFilters
  onChange: (filters: WaitlistedBuyerFilters) => void
}

export const WaitlistedBuyerFiltersPanel: React.FC<Props> = ({
  filters,
  onChange,
}): ReactElement => {
  const changeStatus = (status?: string) => {
    let updatedStatus: string[] = []

    if (status) {
      if (filters.status?.includes(status)) {
        updatedStatus = filters.status.filter((s) => s !== status)
      } else {
        updatedStatus = filters.status ? [...filters.status, status] : [status]
      }
    }

    onChange({ ...filters, status: updatedStatus })
  }

  return (
    <div className="flex flex-col gap-0 overflow-hidden bg-white text-sm text-gray-800 shadow sm:mb-6 sm:flex-row sm:gap-12 sm:rounded-md sm:px-6 lg:mb-8">
      <div className="flex items-center border-b sm:border-0">
        <span className="w-1/3 border-r p-4 font-medium sm:w-auto sm:pl-0">
          Status
        </span>
        <FilterOption
          label="All"
          checked={!filters.status}
          onToggle={() => changeStatus()}
        />
        <FilterOption
          label="Review Required"
          checked={Boolean(filters.status?.includes("review-required"))}
          onToggle={() => changeStatus("review-required")}
        />
      </div>
    </div>
  )
}
