import { ReactNode } from "react"

type Props = {
  children?: ReactNode
  title?: string
  subtitle?: string
  actionButtons?: ReactNode
  className?: string
  expandHeight?: boolean
}

export const FormPanel: React.FC<Props> = ({
  children,
  title,
  subtitle,
  className,
  actionButtons,
  expandHeight,
}) => {
  return (
    <div className={`shadow sm:rounded-md ${expandHeight ? "h-full" : ""}`}>
      <div
        className={`space-y-6 bg-white py-6 px-4 sm:rounded-t-md sm:p-6 ${
          expandHeight ? "h-full" : ""
        } ${actionButtons ? "" : "sm:rounded-b-md"}`}
      >
        {(title || subtitle) && (
          <div>
            {title && (
              <h3 className="text-lg font-medium leading-6 text-gray-600">
                {title}
              </h3>
            )}
            {subtitle && (
              <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
            )}
          </div>
        )}
        <div className={className ?? `grid grid-cols-2 gap-6`}>{children}</div>
      </div>
      {actionButtons && (
        <div className="rounded-b-md border-t bg-gray-50 px-4 py-3 text-right sm:px-6">
          {actionButtons}
        </div>
      )}
    </div>
  )
}
