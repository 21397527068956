import { UserAddIcon } from "@heroicons/react/outline"
import { ReactElement, useState } from "react"
import { useAppDispatch } from "../../../redux/hooks"
import { updateField } from "../../../redux/slices/property-details"
import { Property } from "../../../types/property"
import { User } from "../../../types/user"
import { FormPanel } from "../../global/forms/form-panel"
import { UserSearchModal } from "../modals/user-search-modal"
import { Link } from "react-router-dom"

type Props = {
  property: Property
}

export const UsersTab: React.FC<Props> = ({ property }): ReactElement => {
  const dispatch = useAppDispatch()

  const [isSearchOpen, setSearchOpen] = useState(false)

  let addButton = (
    <button
      className="relative inline-flex items-center gap-1 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
      type="button"
      onClick={() => setSearchOpen(true)}
    >
      <UserAddIcon className="w-5" />
    </button>
  )

  const handleAdd = (user: User) => {
    dispatch(
      updateField({
        field: "users",
        value: [...(property.users ?? []), user],
      })
    )

    setSearchOpen(false)
  }

  const handleDelete = (user: User) => {
    dispatch(
      updateField({
        field: "users",
        value: property.users?.filter((u) => u.id !== user.id),
      })
    )
  }

  return (
    <>
      <FormPanel title="Users" actionButtons={addButton}>
        {property.users && property.users.length > 0 ? (
          <table className="col-span-2 min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-1/4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"
                >
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {property.users?.map((user) => (
                <tr key={user.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                    {user.firstName} {user.lastName}
                    {user.verified.stripe !== "VE" && (
                      <span className="ml-1 font-normal text-gray-500">
                        &ndash; Unverified
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {user.email}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 font-mono text-sm text-gray-500">
                    {user.id}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6 md:pr-0">
                    <Link
                      to={`/users/${user.id}/`}
                      className="mr-6 border-r pr-6 font-medium text-indigo-600 hover:text-indigo-900"
                      target="_blank"
                    >
                      View
                    </Link>
                    <button
                      type="button"
                      onClick={() => handleDelete(user)}
                      className="font-medium text-indigo-600 hover:text-indigo-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="col-span-2 text-sm text-gray-500">No linked users.</p>
        )}
      </FormPanel>
      <UserSearchModal
        isOpen={isSearchOpen}
        excludeIds={property.users?.map((user) => user.id) ?? []}
        onClose={() => setSearchOpen(false)}
        onSelect={handleAdd}
      />
    </>
  )
}
