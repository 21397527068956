import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline"
import {
  CheckCircleIcon,
  PencilAltIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { formatDateTime } from "../../util/dates"

export type HeaderBreadcrumb = {
  label: string
  url?: string
}

export type HeaderButton = {
  label: string
  url?: string
  onClick?: () => void
}

type Props = {
  title: string
  titleTag?: string
  breadcrumbs?: HeaderBreadcrumb[]
  primaryButton?: HeaderButton
  secondaryButton?: HeaderButton | HeaderButton[]
  successMessage?: string
  errorMessage?: string
  dateCreated?: string
  dateUpdated?: string
}

export const Header: React.FC<Props> = ({
  title,
  titleTag,
  breadcrumbs,
  primaryButton,
  secondaryButton,
  successMessage,
  errorMessage,
  dateCreated,
  dateUpdated,
}): ReactElement => {
  let parentUrls = breadcrumbs?.map((b) => b.url)?.filter(Boolean)

  return (
    <div className="bg-gray-800 px-4 py-10 sm:px-6 lg:px-8">
      {breadcrumbs && (
        <div className="mb-2">
          {parentUrls?.length && (
            <nav className="sm:hidden" aria-label="Back">
              <Link
                to={parentUrls[parentUrls.length - 1] ?? ""}
                className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200"
              >
                <ChevronLeftIcon
                  className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-500"
                  aria-hidden="true"
                />
                Back
              </Link>
            </nav>
          )}
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              {breadcrumbs.map((breadcrumb, i) => (
                <li key={`breadcrumb-${i}`}>
                  <div className="flex items-center">
                    {i > 0 && (
                      <ChevronRightIcon
                        className="mr-4 h-5 w-5 flex-shrink-0 text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                    {breadcrumb.url ? (
                      <Link
                        to={breadcrumb.url}
                        className="text-sm font-medium text-gray-400 hover:text-gray-200"
                      >
                        {breadcrumb.label}
                      </Link>
                    ) : (
                      <span className="text-sm font-medium text-gray-200">
                        {breadcrumb.label}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      )}
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl">
            {title}
          </h2>
          {dateCreated && (
            <div className="mt-1 flex flex-col text-sm sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-gray-300">
                <PlusCircleIcon
                  className="mr-1.5 h-4 flex-shrink-0 text-gray-500"
                  aria-hidden="true"
                />
                <span className="mr-1.5 text-gray-400">Created</span>
                {formatDateTime(dateCreated)}
              </div>
              {dateUpdated && dateUpdated !== dateCreated && (
                <div className="mt-2 flex items-center text-gray-300">
                  <PencilAltIcon
                    className="mr-1.5 h-4 flex-shrink-0 text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="mr-1.5 text-gray-400">Updated</span>
                  {formatDateTime(dateUpdated)}
                </div>
              )}
            </div>
          )}
          {titleTag && (
            <p className="mt-2 inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
              {titleTag}
            </p>
          )}
        </div>
        {(primaryButton ||
          secondaryButton ||
          successMessage ||
          errorMessage) && (
          <div className="mt-4 flex flex-shrink-0 flex-col sm:flex-row md:mt-0 md:ml-4">
            {successMessage && (
              <p className={`mr-4 flex items-center text-sm text-green-200`}>
                <CheckCircleIcon
                  className="mr-2 h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="mr-4 flex items-center text-sm text-red-200">
                <XCircleIcon
                  className="mr-2 h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
                {errorMessage}
              </p>
            )}
            <div className="mt-4 flex sm:mt-0">
              {secondaryButton && (
                <div className="mr-3 flex gap-x-px">
                  {(Array.isArray(secondaryButton)
                    ? secondaryButton
                    : [secondaryButton]
                  ).map((button, i) =>
                    button.url ? (
                      <Link
                        to={button.url}
                        className="inline-flex items-center border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm first:rounded-l-md last:rounded-r-md hover:bg-gray-700"
                        key={`button-${i}`}
                      >
                        {button.label}
                      </Link>
                    ) : (
                      button.onClick && (
                        <button
                          type="button"
                          className="inline-flex items-center border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm first:rounded-l-md last:rounded-r-md hover:bg-gray-700"
                          onClick={button.onClick}
                          key={`button-${i}`}
                        >
                          {button.label}
                        </button>
                      )
                    )
                  )}
                </div>
              )}
              {primaryButton?.url && (
                <Link
                  to={primaryButton.url}
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-600"
                  onClick={primaryButton.onClick}
                >
                  {primaryButton.label}
                </Link>
              )}
              {primaryButton?.onClick && (
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-600"
                  onClick={primaryButton.onClick}
                >
                  {primaryButton.label}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
