import { ReactElement } from "react"
import { useAppSelector } from "../../../redux/hooks"
import { Property } from "../../../types/property"
import { FormPanel } from "../../global/forms/form-panel"

type Props = {
  property: Property
}

export const InsightsTab: React.FC<Props> = ({ property }): ReactElement => {
  const { insights } = useAppSelector((state) => state.propertyDetails)

  return (
    <>
      {insights?.metrics && (
        <FormPanel title="Metrics (last 30 days)">
          <table className="col-span-2 min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Unique views
                </th>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Saves
                </th>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Viewing requests
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {insights?.metrics?.saves}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {insights?.metrics?.views}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {insights?.metrics.viewingRequests}
                </td>
              </tr>
            </tbody>
          </table>
        </FormPanel>
      )}
      {insights?.graphs.map((graph) => (
        <FormPanel title={graph.title}>
          <table className="col-span-2 min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {graph.legend?.bar}
                </th>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {graph.legend?.line}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {graph.dataPoints
                .slice()
                .reverse()
                .map((dataPoint) => (
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {dataPoint.date}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {dataPoint.barValue}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {dataPoint.lineValue}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </FormPanel>
      ))}
    </>
  )
}
