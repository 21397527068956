import React from "react"
import { Offer, OfferMessage } from "../../types/offer"
import { FeedItem } from "./feed-item"

type Props = {
  offer: Offer
  messages?: OfferMessage[]
  onEdit: (message?: OfferMessage) => void
  onReview: (approved: boolean, message?: OfferMessage) => void
}

export const MessagesFeed: React.FC<Props> = ({
  offer,
  messages,
  onEdit,
  onReview,
}) => {
  const buyingAgent = Boolean(offer.companyName)
  const unlockedOffer = !["DE", "TO", "CL"].includes(offer.status)

  return (
    <div className={`flow-root ${offer.additionalOffers ? "col-span-2" : ""}`}>
      <ul>
        <FeedItem
          title={`*${offer.user.firstName} ${offer.user.lastName}* made offer ${
            offer.offer ? `*£${offer.offer?.toLocaleString()}*` : ""
          }`}
          date={offer.dateCreated}
          content={
            offer.bioBuyer ? (
              <div>
                {offer.note && (
                  <>
                    <h3 className="mb-1 text-xs font-medium text-gray-500">
                      What appeals about the property
                    </h3>
                    <p className="mb-1.5">{offer.note}</p>
                  </>
                )}
                <h3 className="mb-1 text-xs font-medium text-gray-500">
                  Tell us about{" "}
                  {buyingAgent ? "your client's search" : "yourself"}
                </h3>
                <p>{offer.bioBuyer}</p>
              </div>
            ) : (
              offer.note
            )
          }
          icon="PL"
          approved={offer.approved}
          showEdit={unlockedOffer && offer.approved === null}
          showReview={unlockedOffer}
          onEdit={() => onEdit(undefined)}
          onReview={(approved) => onReview(approved, undefined)}
        />
        {offer.dateReviewed && (
          <FeedItem
            title={`*${offer.approved ? "Approved" : "Rejected"}* by *${
              offer.reviewedBy ?? "Ostrich"
            }*`}
            date={offer.dateReviewed}
            icon={offer.approved ? "AP" : "RE"}
            isChild
          />
        )}
        {messages?.map((message) => (
          <React.Fragment key={`message-${message.id}`}>
            <FeedItem
              title={message.description}
              date={message.dateCreated}
              content={
                message.content
                  ? message.content
                      .split("\n")
                      .map((line, i) => <p key={`line-${i}`}>{line}</p>)
                  : undefined
              }
              icon={message.type}
              approved={message.approved}
              approveLabel={message.type === "CO" ? "Acknowledge" : "Approve"}
              hideReject={message.type === "CO"}
              showEdit={
                unlockedOffer &&
                message.approved === null &&
                message.type !== "CO"
              }
              showReview={unlockedOffer}
              onEdit={() => onEdit(message)}
              onReview={(approved) => onReview(approved, message)}
            />
            {message.dateReviewed && (
              <FeedItem
                title={`*${
                  message.type === "CO"
                    ? "Acknowledged"
                    : message.approved
                    ? "Approved"
                    : "Rejected"
                }* by *${message.reviewedBy ?? "Ostrich"}*`}
                date={message.dateReviewed}
                icon={message.approved ? "AP" : "RE"}
                isChild
              />
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  )
}
