import { ReactElement, useState } from "react"
import { Property } from "../../../types/property"
import { formatAddress } from "../../../util/addresses"
import { PropertySearchModal } from "../modals/property-search-modal"
import { FieldTitle } from "../../global/forms/field-title"
import { SearchIcon } from "@heroicons/react/solid"

type Props = {
  title?: string
  value?: Property
  disabled?: boolean
  placeholder?: string
  error?: boolean
  className?: string
  onChange: (value: Property) => void
}

export const PropertySearchField: React.FC<Props> = ({
  title,
  value,
  disabled,
  placeholder,
  error,
  className,
  onChange,
}): ReactElement => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleSelect = (property: Property) => {
    onChange(property)

    setModalOpen(false)
  }

  const handleEdit = () => {
    if (disabled) return

    setModalOpen(true)
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  return (
    <>
      <div className={className ?? "col-span-2 md:col-span-1"}>
        {title && (
          <FieldTitle
            title={title}
            extraActions={[
              {
                key: "view-property",
                label: "View property",
                href: `/properties/${value?.id}/`,
                external: true,
              },
            ]}
          />
        )}
        <div
          className={`group relative flex w-full rounded-md border px-3 py-2 text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
            value ? "" : "text-gray-500"
          } ${disabled ? "bg-gray-100" : "cursor-pointer"} ${
            error ? "border-red-300" : "border-gray-300"
          }`}
          onClick={handleEdit}
        >
          <p>
            {value?.address ? formatAddress(value.address, true) : placeholder}
          </p>
          {!disabled && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SearchIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-700" />
            </span>
          )}
        </div>
      </div>
      <PropertySearchModal
        isOpen={isModalOpen}
        onSelect={handleSelect}
        onClose={handleCancel}
      />
    </>
  )
}
