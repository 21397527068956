import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  updateBuyer,
  updateField,
  updateProperty,
  updateSeller,
} from "../../../redux/slices/viewing-details"
import { Property } from "../../../types/property"
import { User } from "../../../types/user"
import { Viewing } from "../../../types/viewings"
import { DateField } from "../../global/forms/date-field"
import { FormPanel } from "../../global/forms/form-panel"
import { SelectField } from "../../global/forms/select-field"
import { TextField } from "../../global/forms/text-field"
import { BuyerSearchField } from "../fields/buyer-search-field"
import { PropertySearchField } from "../fields/property-search-field"
import { ViewingAttendeeField } from "../fields/viewing-attendee-field"

type Props = {
  viewing: Viewing
  property?: Property
  buyer?: User
  seller?: User
}

export const ViewingDetailsTab: React.FC<Props> = ({
  viewing,
  property,
  buyer,
  seller,
}) => {
  const dispatch = useAppDispatch()

  const { errors } = useAppSelector((state) => state.viewingDetails)

  const isCancelled = viewing.status === "CA"

  const sellerOptions =
    property?.users?.map((user) => {
      return {
        value: user.id,
        label: `${user.firstName} ${user.lastName} (${user.email})`,
      }
    }) ?? []

  return (
    <>
      <FormPanel title="Core Details">
        <PropertySearchField
          title="Property"
          value={property}
          disabled={isCancelled}
          error={errors?.property}
          placeholder="Select property"
          onChange={(value) => dispatch(updateProperty(value))}
        />
        <SelectField
          title="Viewing Type"
          options={[
            { value: "1", label: "1st" },
            { value: "2", label: "2nd" },
            { value: "3", label: "3rd" },
          ]}
          value={viewing.type?.toString()}
          disabled={isCancelled}
          error={errors?.type}
          onChange={(value) =>
            dispatch(
              updateField({
                field: "type",
                value: value ? parseInt(value) : undefined,
              }),
            )
          }
        />
        <DateField
          title="Date"
          value={viewing.dateViewing}
          disabled={isCancelled}
          error={errors?.dateViewing}
          onChange={(value) =>
            dispatch(updateField({ field: "dateViewing", value }))
          }
        />
        <ViewingAttendeeField
          value={viewing.attendee}
          disabled={isCancelled}
          error={errors?.attendee}
          sellerFirstName={seller?.firstName}
          onChange={(value) =>
            dispatch(updateField({ field: "attendee", value }))
          }
        />
      </FormPanel>
      {viewing.propertyId && (
        <>
          <FormPanel title="Seller">
            <SelectField
              title="User"
              options={sellerOptions}
              disabled={sellerOptions.length <= 1 || isCancelled}
              value={viewing.sellerId}
              error={errors?.seller}
              extraActions={
                seller
                  ? [
                      {
                        key: "view-user",
                        label: "View user details",
                        href: `/users/${seller.id}/`,
                        external: true,
                      },
                    ]
                  : undefined
              }
              onChange={(value) => {
                const seller = property?.users?.find((u) => u.id === value)

                dispatch(updateSeller(seller))
              }}
              placeholder="Select user"
            />
            {seller && (
              <TextField
                title="Phone Number"
                extraActions={
                  seller?.phoneNumber && !isCancelled
                    ? [{ key: "copy-from-user", label: "Copy from user" }]
                    : undefined
                }
                value={viewing.sellerPhoneNumber ?? ""}
                disabled={isCancelled}
                onChange={(value) =>
                  dispatch(
                    updateField({
                      field: "sellerPhoneNumber",
                      value,
                    }),
                  )
                }
                onExtraAction={() =>
                  dispatch(
                    updateField({
                      field: "sellerPhoneNumber",
                      value: seller?.phoneNumber,
                    }),
                  )
                }
              />
            )}
          </FormPanel>
          <FormPanel title="Buyer">
            <BuyerSearchField
              title="User"
              value={buyer}
              disabled={isCancelled}
              error={errors?.buyer}
              placeholder="Select user"
              onChange={(value) => dispatch(updateBuyer(value))}
            />
            {buyer && (
              <>
                <TextField
                  title="Phone Number"
                  extraActions={
                    buyer?.phoneNumber && !isCancelled
                      ? [{ key: "copy-from-user", label: "Copy from user" }]
                      : undefined
                  }
                  value={viewing.buyerPhoneNumber ?? ""}
                  disabled={isCancelled}
                  error={errors?.buyerPhoneNumber}
                  onChange={(value) =>
                    dispatch(
                      updateField({
                        field: "buyerPhoneNumber",
                        value,
                      }),
                    )
                  }
                  onExtraAction={() =>
                    dispatch(
                      updateField({
                        field: "buyerPhoneNumber",
                        value: buyer?.phoneNumber,
                      }),
                    )
                  }
                />
                <TextField
                  title="Additional Attendees"
                  value={viewing.additionalAttendees}
                  disabled={isCancelled}
                  onChange={(value) =>
                    dispatch(
                      updateField({ field: "additionalAttendees", value }),
                    )
                  }
                />
              </>
            )}
          </FormPanel>
        </>
      )}
    </>
  )
}
