import { Address } from "../types/property"

export const formatAddress = (
  address: Address,
  includePostcode?: boolean
): string => {
  let lines: string[] = []

  if (address.streetName) {
    if (address.buildingUnit && address.buildingName) {
      lines.push(`${address.buildingUnit} ${address.buildingName}`)
    } else if (address.buildingUnit) {
      lines.push(address.buildingUnit)
    } else if (address.buildingName) {
      lines.push(address.buildingName)
    }

    if (address.streetNumber) {
      lines.push(`${address.streetNumber} ${address.streetName}`)
    } else {
      lines.push(address.streetName)
    }
  } else if (address.line1) {
    lines.push(address.line1)

    if (address.line2) {
      lines.push(address.line2)
    }
  }

  if (includePostcode) {
    lines.push(formatPostcode(address.postcode))
  }

  return lines.join(", ")
}

export const formatPostcode = (postcode: string): string => {
  let len = postcode.length

  return `${postcode.substring(0, len - 3)} ${postcode.substring(len - 3)}`
}

export const sortAddresses = (addresses: Address[]): Address[] => {
  return addresses.sort((a, b) =>
    formatAddress(a).localeCompare(formatAddress(b))
  )
}
